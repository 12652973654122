import React from 'react';
import { Filter } from '../common';
import {
    Apple,
    DeveloperModeOutlined, Facebook, PermIdentity,
    ReportOutlined,
    ReportProblemOutlined,
} from '@mui/icons-material';
import { FilterList, FilterListItem, FilterListItemIcon, FilterListItemText } from '../../utils';
import { CardMedia, Checkbox } from '@mui/material';

interface Props extends ThemeModeProps {
    readonly bannedOnly: boolean;
    readonly cheaterOnly: boolean;
    readonly developerOnly: boolean;
    readonly customLoginLinkOnly: boolean;
    readonly gameCenterLoginLinkOnly: boolean;
    readonly googlePlayGamesLoginLinkOnly: boolean;
    readonly facebookLoginLinkOnly: boolean;
    readonly appleIdLoginLinkOnly: boolean;
    readonly googleLoginLinkOnly: boolean;
    readonly onFilter: (
        bannedOnly: boolean,
        cheaterOnly: boolean,
        developerOnly: boolean,
        customLoginLinkOnly: boolean,
        gameCenterLoginLinkOnly: boolean,
        googlePlayGamesLoginLinkOnly: boolean,
        facebookLoginLinkOnly: boolean,
        appleIdLoginLinkOnly: boolean,
        googleLoginLinkOnly: boolean,
    ) => void;
}

interface State {
    readonly bannedOnly: boolean;
    readonly cheaterOnly: boolean;
    readonly developerOnly: boolean;
    readonly customLoginLinkOnly: boolean;
    readonly gameCenterLoginLinkOnly: boolean;
    readonly googlePlayGamesLoginLinkOnly: boolean;
    readonly facebookLoginLinkOnly: boolean;
    readonly appleIdLoginLinkOnly: boolean;
    readonly googleLoginLinkOnly: boolean;
}

export class PlayersFilter extends React.Component<Props, State> {
    state: State = {
        bannedOnly: false,
        cheaterOnly: false,
        developerOnly: false,
        customLoginLinkOnly: false,
        gameCenterLoginLinkOnly: false,
        googlePlayGamesLoginLinkOnly: false,
        facebookLoginLinkOnly: false,
        appleIdLoginLinkOnly: false,
        googleLoginLinkOnly: false,
    };

    componentDidUpdate(prevProps: Props) {
        const {
            bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
        } = this.props;
        if ( prevProps.bannedOnly === bannedOnly &&
            prevProps.cheaterOnly === cheaterOnly &&
            prevProps.developerOnly === developerOnly &&
            prevProps.customLoginLinkOnly === customLoginLinkOnly &&
            prevProps.gameCenterLoginLinkOnly === gameCenterLoginLinkOnly &&
            prevProps.googlePlayGamesLoginLinkOnly === googlePlayGamesLoginLinkOnly &&
            prevProps.facebookLoginLinkOnly === facebookLoginLinkOnly &&
            prevProps.appleIdLoginLinkOnly === appleIdLoginLinkOnly &&
            prevProps.googleLoginLinkOnly === googleLoginLinkOnly
        ) {
            return;
        }

        this.setState({
            bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
        });
    }

    render() {
        const { themeMode } = this.props;
        const {
            bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
        } = this.state;

        const onFilterBanned = () => this.setState({ bannedOnly: !bannedOnly });
        const onFilterCheater = () => this.setState({ cheaterOnly: !cheaterOnly });
        const onFilterDeveloper = () => this.setState({ developerOnly: !developerOnly });

        const onFilterCustom = () => this.setState({ customLoginLinkOnly: !customLoginLinkOnly });
        const onFilterGameCenter = () => this.setState({ gameCenterLoginLinkOnly: !gameCenterLoginLinkOnly });
        const onFilterGooglePlayGames = () => this.setState({ googlePlayGamesLoginLinkOnly: !googlePlayGamesLoginLinkOnly });
        const onFilterFacebook = () => this.setState({ facebookLoginLinkOnly: !facebookLoginLinkOnly });
        const onFilterAppleId = () => this.setState({ appleIdLoginLinkOnly: !appleIdLoginLinkOnly });
        const onFilterGoogle = () => this.setState({ googleLoginLinkOnly: !googleLoginLinkOnly });

        const onFilter = () => {
            this.props.onFilter(
                bannedOnly,
                cheaterOnly,
                developerOnly,
                customLoginLinkOnly,
                gameCenterLoginLinkOnly,
                googlePlayGamesLoginLinkOnly,
                facebookLoginLinkOnly,
                appleIdLoginLinkOnly,
                googleLoginLinkOnly,
            );
        };

        const onClose = () => {
            this.setState({bannedOnly: this.props.bannedOnly, cheaterOnly: this.props.cheaterOnly, developerOnly: this.props.developerOnly});
        };

        return (
            <Filter
                themeMode={themeMode}
                title={'Filter Players'}
                menuTitles = {['Player Type', 'Link Type']}
                menuElements = {[
                    (
                        <FilterList themeMode={themeMode}>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`banned`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterBanned}
                                        checked={bannedOnly}
                                        style={{ color: 'inherit' }}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <ReportOutlined style={{ width: 18, height: 18, color: 'red' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Banned'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`cheater`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterCheater}
                                        checked={cheaterOnly}
                                        style={{ color: 'inherit' }}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <ReportProblemOutlined style={{ width: 18, height: 18, color: 'orange' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Cheater'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`developer`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterDeveloper}
                                        checked={developerOnly}
                                        style={{ color: 'inherit' }}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <DeveloperModeOutlined style={{ width: 18, height: 18, color: '#21BA47' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Developer'} />
                            </FilterListItem>
                        </FilterList>
                    ),
                    (
                        <FilterList themeMode={themeMode}>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`custom_id`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterCustom}
                                        checked={customLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <PermIdentity style={{ width: 18, height: 18 }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Custom Id'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`game_center`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterGameCenter}
                                        checked={gameCenterLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <CardMedia
                                        style={{ width: 18, height: 18 }}
                                        image={`${process.env.PUBLIC_URL}/images/game_center_icon.png`}
                                    />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Game Center'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`google_play_games`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterGooglePlayGames}
                                        checked={googlePlayGamesLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <CardMedia
                                        style={{ width: 18, height: 18 }}
                                        image={`${process.env.PUBLIC_URL}/images/google_play_games_icon.png`}
                                    />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Google Play Games'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`facebook`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterFacebook}
                                        checked={facebookLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <Facebook style={{ width: 18, height: 18, color: 'rgb(66, 103, 178)' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Facebook'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`apple_id`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterAppleId}
                                        checked={appleIdLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <Apple style={{ width: 18, height: 18 }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Apple Id'} />
                            </FilterListItem>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`google`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterGoogle}
                                        checked={googleLoginLinkOnly}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <CardMedia
                                        style={{ width: 18, height: 18 }}
                                        image={`${process.env.PUBLIC_URL}/images/google_icon.png`}
                                    />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Google'} />
                            </FilterListItem>
                        </FilterList>
                    )
                ]}
                onFilter={onFilter}
                onClose={onClose}
            />
        );
    }
}
