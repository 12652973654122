import * as React from 'react';
import { Button, Table } from '../common';
import { Typography, DialogProps, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { fontFamily } from '../../utils';

interface Props extends DialogProps {
    readonly jobs: Entities.CloudBuildJob[];
    readonly onClose: () => void;
    readonly onStartBuild: (jobs: Entities.CloudBuildJob[], clean: boolean) => void;
}

interface State {
    readonly selected: Entities.CloudBuildJob[];
}

export class StartBuildDialog extends React.Component<Props, State> {
    state = this.initState();

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.onClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle >
                    <Typography variant={'h5'}>
                        Build targets
                    </Typography>
                    <p style={{ fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem', letterSpacing: '-0.006rem' }}>
                        Select the target(s) you want to build.
                    </p>
                </DialogTitle>
                <DialogContent sx={{ padding: '0px !important' }}>
                    {this.renderContent()}
                </DialogContent>
                <DialogActions style={{ padding: 8 }}>
                    {this.renderActions()}
                </DialogActions>
            </Dialog>
        );
    }

    private renderContent = () => {
        const { jobs } = this.props;
        const columns = [
            { title: 'Build Name', field: 'name', cellStyle: { padding: '10px 16px', fontFamily, fontSize: 14 }},
        ];

        return (
            <Table
                data={jobs}
                columns={columns}
                showBox={false}
                options={{
                    showTitle: false,
                    selection: true,
                    search: false,
                    sorting: false,
                    draggable: false,
                    paging: false,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        height: 25,
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        lineHeight: '1.3',
                        padding: '10px 16px',
                    }
                }}
                components={{
                    Toolbar: props => <></>,
                }}
                onSelectionChange={this.onSelectionChange}
            />
        );
    };

    private onSelectionChange = (rows: any) => this.setState({ selected: rows });

    private renderActions = () => {
        return (
            <>
                <Button text="Cancel" onClick={this.onClose} variant="text" />
                <Button text="Clean Build" onClick={this.onSubmitClean} variant="contained" disabled={!this.validate()} />
                <Button text="Build" onClick={this.onSubmit} variant="contained" disabled={!this.validate()} />
            </>
        );
    };

    private onSubmit = () => {
        const { selected } = this.state;
        if (!this.validate()) {
            return;
        }

        this.props.onStartBuild(selected, false);
        this.onClose();
    };

    private onSubmitClean  = () => {
        const { selected } = this.state;
        if (!this.validate()) {
            return;
        }

        this.props.onStartBuild(selected, true);
        this.onClose();
    };

    private onClose = () => {
        this.setState(this.initState());
        this.props.onClose();
    };

    private validate(): boolean {
        const { selected } = this.state;
        return selected.length > 0;
    }

    private initState(): State {
        return {
            selected: [],
        };
    }
}
