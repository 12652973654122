import * as _ from 'lodash';
import * as React from 'react';

import { InputLabelProps, InputProps as StandardInputProps, TextField as MTextField } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface TextFieldProps extends PropsWithChildren {
    readonly id?: string;
    readonly margin?: 'dense';
    readonly key?: string;
    readonly className?: string;
    readonly label: string;
    readonly value?: string;
    readonly placeholder?: string;
    readonly required?: boolean;
    readonly readOnly?: boolean;
    readonly disabled?: boolean;
    readonly multiline?: boolean;
    readonly rows?: number;
    readonly error?: boolean;
    readonly select?: boolean;
    readonly fullWidth?: boolean;
    readonly InputProps?: Partial<StandardInputProps>;
    readonly InputLabelProps?: Partial<InputLabelProps>;
    readonly defaultValue?: unknown;
    readonly variant?: 'standard' | 'outlined' | 'filled';
    readonly color?: 'primary' | 'secondary';
    readonly type?: React.InputHTMLAttributes<unknown>['type'];
    readonly style?: React.CSSProperties;
    readonly onChange?: (val: string) => void;
    readonly sx?: SxProps<Theme>;
}

export class TextField extends React.PureComponent<TextFieldProps> {
    render() {
        const { value, variant, color, children, margin } = this.props;
        return (
            <MTextField
                {..._.pick(
                    this.props,
                    'id',
                    'key',
                    'className',
                    'label',
                    'placeholder',
                    'required',
                    'disabled',
                    'multiline',
                    'rows',
                    'error',
                    'select',
                    'fullWidth',
                    'InputLabelProps',
                    'defaultValue',
                    'InputProps',
                    'readOnly',
                    'type',
                    'sx'
                )}
                value={value || ''}
                onChange={this.onChange}
                margin={margin || 'none'}
                color={color || 'primary'}
                variant={variant || 'standard'}
            >
                {children}
            </MTextField>
        );
    }

    private onChange = (eventObject: any) => {
        let value = eventObject.target.value;
        const { onChange } = this.props;
        if (!value) {
            return;
        }
        if (onChange) {
            onChange(value);
        }
    };
}
