import * as _ from 'lodash';
import * as React from 'react';
import { Button } from '../';
import { Link } from '../../../utils';
import {
    Grid2 as Grid,
    SvgIcon,
    Typography,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { MouseEventHandler } from 'react';
import { styled } from '@mui/system';

interface Headline {
    readonly text: any;
    readonly to?: string;
}

type SvgIconComponent = typeof SvgIcon;
export interface HeaderButton {
    readonly text?: string;
    readonly icon?: SvgIconComponent;
    readonly onClick: MouseEventHandler<any> | undefined;
    readonly style?: React.CSSProperties;
    readonly color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    readonly iconColor?: string;
    readonly textColor?: string;
}

export interface CommonHeaderProps extends ThemeModeProps {
    readonly title?: React.ReactNode;
    readonly TitleIcon?: any;
    readonly headline?: string;
    readonly headlines?: Headline[];
    readonly onRefresh?: () => void;
    readonly buttons?: HeaderButton[];
}
interface ComponentProps extends CommonHeaderProps {}

export class CommonHeader extends React.Component<ComponentProps> {
    render() {
        const { headline, headlines = [], buttons, title } = this.props;
        if (headline) {
            headlines.push({ text: headline });
        }

        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                {title && (
                    <>
                        <Grid size={12}>
                            {this.renderTitle()}
                        </Grid>
                    </>
                )}
                {buttons && buttons.length > 0 && (
                    <Grid size={12}>
                        <Grid container={true} justifyContent="right" spacing={1}>
                            {_.map(buttons, ({ text, icon, iconColor, onClick, style, color, textColor }) => (
                                <ButtonsGrid size={{ xs:12, sm:4, md:3, lg:3, xl:2 }} justifyContent="right">
                                    <Button
                                        text={text}
                                        icon={icon}
                                        iconColor={iconColor}
                                        variant="contained"
                                        color={color ? color : 'secondary'}
                                        onClick={onClick}
                                        textColor={textColor}
                                        style={{
                                            ...style,
                                            width: '100%',
                                            height: '100%',
                                            fontSize: 12,
                                        }}
                                    />
                                </ButtonsGrid>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {headlines.length > 0 && (
                    <Grid size={12}>
                        {this.renderHeadlines(headlines)}
                    </Grid>
                )}
            </Grid>
        );
    }

    private renderTitle() {
        const { themeMode, onRefresh, TitleIcon } = this.props;
        return (
            <div style={{
                flexGrow: 0,
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: themeMode === 'light' ? '#404040' : '#adadad',
                fontWeight: 'bold',
            }}>
                <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-start'}}>
                    {TitleIcon && (
                        <TitleIcon style={{width: 22, height: 22, verticalAlign: 'middle', color: themeMode === 'light' ? '#404040' : '#adadad', margin: 'auto'}}/>
                    )}
                    <Typography
                        variant="h4"
                        style={{
                            color: themeMode === 'light' ? '#16191f' : '#ffffffe6',
                            fontSize: '18px',
                            fontWeight: 500,
                            lineHeight: '2.1rem',
                            margin: 'auto'
                        }}
                    >
                        {this.props.title}
                    </Typography>
                </div>
                {onRefresh && (
                    <Button icon={Refresh} style={{ marginRight: 8, backgroundColor: '#fff' }} onClick={onRefresh} />
                )}
            </div>
        );
    }

    private renderHeadlines(headlines: Headline[]) {
        const { themeMode } = this.props;
        return (
            <div style={{
                flexGrow: 0,
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '2px 0px',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 13,
                }}>
                    {_.map(headlines, ({ text, to = '' }, index) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: 13,
                                color: themeMode === 'light' ? 'grey' : '#ffffff99',
                            }}
                            key={`headline_${text}`}
                        >
                            {to ? (
                                <Link to={to} style={{
                                    textDecoration: 'none',
                                    fontSize: 13,
                                    fontWeight: 500,
                                }}>
                                    {text}
                                </Link>
                            ) : (
                                <Typography variant="h6" style={{
                                    fontSize: 13,
                                }}>
                                    {text}
                                </Typography>
                            )}
                            {index !== headlines.length - 1 && (
                                <Typography style={{ margin: '0 8px' }} variant="body2">
                                    {'>'}
                                </Typography>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const ButtonsGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.only('xs')]: {
        padding: '5px 40px !important',
    }
}));
