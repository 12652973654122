import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onAdd: (name: string, apiKey: string, orgId: string, projectId: string, targetId: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly apiKey: string;
    readonly orgId: string;
    readonly projectId: string;
    readonly targetId: string;
}

export class AddUnityCloudJobDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { name, apiKey, orgId, projectId, targetId } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Api Key</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={apiKey}
                        label=""
                        required={true}
                        onChange={this.updateApiKey}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Organization Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={orgId}
                        label=""
                        required={true}
                        onChange={this.updateOrgId}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Project Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={projectId}
                        label=""
                        required={true}
                        onChange={this.updateProjectId}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Target Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={targetId}
                        label=""
                        required={true}
                        onChange={this.updateTargetId}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onSubmit} variant="contained" disabled={!this.validate()} />
        );
    }

    private onSubmit = () => {
        const { name, apiKey, orgId, projectId, targetId } = this.state;
        if (!this.validate()) {
            return;
        }

        this.props.onAdd(name, apiKey, orgId, projectId, targetId);
        this.onClose();
    };

    private updateName = (name: string) => this.setState({ name });
    private updateApiKey = (apiKey: string) => this.setState({ apiKey });
    private updateOrgId = (orgId: string) => this.setState({ orgId });
    private updateProjectId = (projectId: string) => this.setState({ projectId });
    private updateTargetId = (targetId: string) => this.setState({ targetId });

    private validate(): boolean {
        const { name, apiKey, orgId, projectId, targetId } = this.state;
        return name !== '' && apiKey !== '' && orgId !== '' && projectId !== '' && targetId !== '';
    }

    protected initState(): State {
        return {
            name: '',
            apiKey: '',
            orgId: '',
            projectId: '',
            targetId: '',
        };
    }
}
