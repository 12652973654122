import * as _ from 'lodash';
import * as React from 'react';
import { classifyEconomyItems } from '../utils';
import { BaseEconomyContainer, BaseElement, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import actions from '../actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly items: Entities.EconomyItem[];
}

interface State {
    readonly bundles: Entities.EconomyItem[];
}

class Container extends BaseElement<Props, State> {
    state: State = {
        bundles: [],
    };

    async componentDidMount() {
        await actions.economy.getItems();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.items !== prevProps.items) {
            const bundles = classifyEconomyItems(this.props.items).bundles;
            this.setState({bundles});
        }
    }

    protected renderContainer(): React.JSX.Element {
        return (
            <BaseEconomyContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Economy Bundles"}
            >
                {this.renderContent()}
            </BaseEconomyContainer>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Item ID', field: 'itemId'},
            { title: 'Display Name', field: 'displayName'},
            { title: 'Item Class', field: 'itemClass'},
        ];
        const data = _.map(
            this.state.bundles,
            item => {
                return {
                    itemId: item.id,
                    displayName: item.displayName,
                    itemClass: item.itemClass,
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                onRowClick={this.onClick}
                options={{
                    showTitle: false,
                    selection: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private onClick = (event: any, rowData: any) => {
        const selectedItem = this.state.bundles.find(i => i.id === rowData.id );
        if (selectedItem) {
            this.toLink(`/economy/item/${selectedItem.id}`);
        }
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    items: state.economy.items,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
