import * as React from 'react';
import { TextField, Button } from '../common';
import { LoginType } from '../../enums';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly loginUser: Entities.User;
    readonly onUpdatePassword: (password: string) => void;
}

interface State extends BaseDialogState {
    readonly password: string;
}

export class AccountDialog extends BaseDialog<Props, State> {
    state = {
        password: '',
    };

    protected renderContent(): React.JSX.Element {
        const { loginUser } = this.props;
        const { password } = this.state;

        return (
            <>
                <TextField
                    fullWidth={true}
                    value={loginUser.username}
                    label="User Name"
                    disabled={true}
                />
                {this.enablePassword() && (
                    <TextField
                        fullWidth={true}
                        error={!this.validatePassword()}
                        value={password}
                        label="Password"
                        type="password"
                        required={true}
                        onChange={this.updatePassword}
                    />
                )}
                <TextField
                    fullWidth={true}
                    value={loginUser.name}
                    label="Name"
                    disabled={true}
                />
                <TextField
                    fullWidth={true}
                    value={loginUser.organization}
                    label="Organization"
                    disabled={true}
                />
            </>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <>
                {this.enablePassword() && (
                    <Button text={'Update Password'} onClick={this.onUpdatePassword} variant="contained" />
                )}
            </>
        );
    }

    private updatePassword = (password: string) => this.setState({ password });

    private validatePassword(): boolean {
        const { password } = this.state;
        return password !== '' && this.props.loginUser.password !== password;
    }

    private onUpdatePassword = () => {
        const { onUpdatePassword } = this.props;
        const { password } = this.state;
        onUpdatePassword(password);
    };

    private enablePassword(): boolean {
        const { loginUser } = this.props;
        return loginUser.loginType !== LoginType.Google;

    }
}
