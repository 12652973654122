import * as React from 'react';
import { EventType, LeaderBoardType, TimerRecurringType, TimerType } from '../../enums';
import {
    Grid2 as Grid,
    InputLabel,
    Step as MaterialStep,
    StepButton,
    Stepper,
    DialogContentText,
    CardMedia,
    Chip,
} from '@mui/material';
import { DeveloperModeOutlined, ExpandMore } from '@mui/icons-material';
import {
    daysMenus, DaysMenuType,
    formatMomentDate,
    getColorIndexByTitle, getColorTitleByIndex,
    getDurationValueFromTimeStamp,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../utils';
import { BaseCreateNewEventComponent, CreateLiveEventProps, CreateLiveEventState } from './BaseCreateNewEventComponent';
import { AutomatePlayerAction, LabelCheckBox, Metadata, NumberField } from '../common';
import { LeaderBoardRanks } from './LeaderBoardRanks';
import { default as moment } from 'moment';

interface Props extends CreateLiveEventProps {
    readonly maxUsersPerInstance?: number;
    readonly onCreate: (liveEvent: Entities.LiveEvent, ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, leaderBoardType: LeaderBoardType) => void;
}

interface State extends CreateLiveEventState {
    readonly maxUsersPerInstance: number;
    readonly leaderBoardType: LeaderBoardType;
    readonly ranks: Entities.EntityLeaderBoardRank[];
}

export class CreateNewLeaderBoardEventComponent extends BaseCreateNewEventComponent<Props, State> {
    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Schedule':
                activeStep = 1;
                detailContent = this.renderScheduleContent();
                detailButtons = this.renderScheduleButtons();
                break;
            case 'Rewards':
                activeStep = 2;
                detailContent = this.renderRewardsContent();
                detailButtons = this.renderRewardsButtons();
                break;
            case 'FinishActions':
                activeStep = 3;
                detailContent = this.renderFinishActionsContent();
                detailButtons = this.renderFinishActionsButtons();
                break;
            case 'Confirm':
                activeStep = 4;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Grid size={12}>
                    <Stepper alternativeLabel={true} nonLinear={true} activeStep={activeStep}>
                        <MaterialStep key={'Details'} completed={activeStep > 0}>
                            <StepButton onClick={this.onDetailStepSet} >
                                {'Details'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Schedule'} completed={activeStep > 1}>
                            <StepButton onClick={this.onScheduleStepSet} >
                                {'Schedule'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Rewards'} completed={activeStep > 2}>
                            <StepButton onClick={this.onRewardsStepSet} >
                                {'Rewards'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'FinishActions'} completed={activeStep > 3}>
                            <StepButton onClick={this.onFinishActionsStepSet} >
                                {'Finish Actions'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Confirm'} completed={activeStep > 4}>
                            <StepButton onClick={this.onConfirmStepSet} >
                                {'Confirm'}
                            </StepButton>
                        </MaterialStep>
                    </Stepper>
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <div style={{
                        display: 'grid',
                        justifyContent: 'flex-end',
                        gridAutoFlow: 'column',
                        gridColumnGap: 10,
                    }}>
                        {detailButtons}
                    </div>
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
        const { oldLiveEvent, maxUsersPerInstance, type } = this.props;
        if (!oldLiveEvent) {
            return;
        }

        const { name, startTimestamp, endTimestamp, gameData, finishActions, scheduleColor, developerOnly } = oldLiveEvent;
        let ranks: Entities.EntityLeaderBoardRank[] = [];
        let maxUsers = 200;
        if (maxUsersPerInstance) {
            maxUsers = maxUsersPerInstance;
        }
        if (oldLiveEvent.ranks) {
            ranks = oldLiveEvent.ranks;
        }

        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                gameData,
                maxUsersPerInstance: maxUsers,
                finishActions: finishActions ? finishActions : [],
                developerOnly,
                ranks,
            });
            return;
        }

        this.setState({
            name,
            start: moment(startTimestamp),
            duration: getDurationValueFromTimeStamp(startTimestamp, endTimestamp),
            color: getColorIndexByTitle(scheduleColor),
            gameData,
            maxUsersPerInstance: 200,
            finishActions: finishActions ? finishActions : [],
            developerOnly,
            ranks,
        });
    }

    protected renderDetailContent = () => {
        const { developerOnly } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} alignContent={'start'} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            color={'secondary'}
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleDeveloperOnlyChange}
                    />
                </Grid>
                {this.renderNameField()}
                {this.renderMaxUsersPerInstanceField()}
                {/*this.renderLeaderBoardTypeField()*/}
                {this.renderImageField()}
                {this.renderGameDataField()}
            </Grid>
        );
    };

    protected renderRewardsContent = () => {
        const { ranks } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid size={12}>
                    <DialogContentText>
                        List here the prizes ranks that Nucleo will be automatically calculate at the moment to end the event.
                    </DialogContentText>
                </Grid>
                <Grid size={12}>
                    <LeaderBoardRanks ranks={ranks} onUpdateRanks={this.updateRanks} />
                </Grid>
            </Grid>
        );
    };

    protected renderConfirmContent = () => {
        const {
            name,
            start,
            duration,
            gameData,
            maxUsersPerInstance,
            leaderBoardType,
            finishActions,
            file,
            useDuration,
            developerOnly,
            timerType,
            timerRecurringType,
            timerRecurringStart,
            timerRecurringDuration,
            timerRecurringDays,
        } = this.state;
        let { end } = this.state;
        if (useDuration) {
            end = start.clone().add(duration, 'minutes');
        }

        const dayNames = timerRecurringDays.map(day => {
            let dayName = '';
            Object.keys(daysMenus).forEach(key => {
                const dayMenuType = key as DaysMenuType;
                if (daysMenus[dayMenuType].value === day) {
                    dayName = daysMenus[dayMenuType].title;
                }
            });
            return dayName;
        });

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                {timerType === TimerType.Scheduled && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start)}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end)}
                                        </Grid>
                                    </>
                                )}
                                {timerType === TimerType.Recurring && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Repeat</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {timerRecurringType === TimerRecurringType.Daily && (
                                                <>
                                                    {`Daily at ${formatMomentDate(timerRecurringStart, 'LT')} for ${timerRecurringDuration} minutes`}
                                                </>
                                            )}
                                            {timerRecurringType === TimerRecurringType.Weekly && (
                                                <>
                                                    {`Weekly ${dayNames.join(' - ')} at ${formatMomentDate(timerRecurringStart, 'LT')} for ${timerRecurringDuration} minutes`}
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Max Users Per Instance</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {maxUsersPerInstance}
                                </Grid>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Leader Board Type</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {LeaderBoardType[leaderBoardType].replace(/([A-Z])/g, ' $1').trim()}
                                </Grid>
                                {file && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Image</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            <CardMedia image={URL.createObjectURL(file)} style={{width: 200, height: 200, margin: 10}}/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {gameData !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={gameData}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {finishActions.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Rewards
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <AutomatePlayerAction
                                    actions={finishActions}
                                    readOnly={true}
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 5,
                                        backgroundColor: 'white',
                                    }}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    };

    protected initState(): State {
        const baseState = super.initState();

        return {
            ...baseState,
            maxUsersPerInstance: 200,
            leaderBoardType: LeaderBoardType.RandomOld,
            ranks: [],
        };
    }

    protected onCreate = () => {
        const {
            name,
            start,
            end,
            duration,
            gameData,
            developerOnly,
            maxUsersPerInstance,
            leaderBoardType,
            finishActions,
            ranks,
            color,
            useDuration,
            timerType,
            timerRecurringType,
            timerRecurringStart,
            timerRecurringDuration,
            timerRecurringDays,
        } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        timerRecurringStart.set('seconds', 0);
        timerRecurringStart.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        const liveEvent: Entities.LiveEvent = {
            eventId: '',
            name,
            gameData,
            timerType,
            startTimestamp: 0,
            endTimestamp: 0,
            lastUpdatedTimestamp: 0,
            createdTimestamp: 0,
            eventType: EventType.LeaderBoard,
            scheduleColor: getColorTitleByIndex(color),
            developerOnly,
            finishActions,
        };

        switch (timerType) {
            case TimerType.Scheduled:
                liveEvent.startTimestamp = start.clone().utc().valueOf();
                liveEvent.endTimestamp = useDuration ? start.clone().add(duration, 'minutes').utc().valueOf() : end.clone().utc().valueOf();
                break;
            case TimerType.Recurring:
                liveEvent.startTimestamp = start.clone().utc().startOf('day').valueOf();
                liveEvent.endTimestamp = end.clone().utc().endOf('day').valueOf();
                liveEvent.timerRecurringType = timerRecurringType;
                liveEvent.timerRecurringStart = timerRecurringStart.utc().valueOf() - liveEvent.startTimestamp;
                liveEvent.timerRecurringDuration = timerRecurringDuration * 60 * 1000;
                liveEvent.timerRecurringDays = timerRecurringDays;
                break;
        }


        this.props.onCreate(liveEvent, ranks, maxUsersPerInstance, leaderBoardType);
    };

    private updateRanks = (ranks: Entities.EntityLeaderBoardRank[]) => this.setState({ ranks });

    private handleMaxUsersPerInstanceChange = (maxUsersPerInstance: number) => this.setState({maxUsersPerInstance});

    private renderMaxUsersPerInstanceField = (disabled: boolean = false) => {
        const { maxUsersPerInstance } = this.state;

        return (
            <>
                <Grid size={12}>
                    <InputLabel>Max Users Per Instance</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxUsersPerInstance}
                        onChange={this.handleMaxUsersPerInstanceChange}
                        fullWidth={true}
                        disabled={disabled}
                        variant="outlined"
                    />
                </Grid>
            </>
        );
    };

    /*
    private renderLeaderBoardTypeField = () => {
        const { leaderBoardType } = this.state;

        const onChangeMethod = (event: SelectChangeEvent<number>) => {
            const type =  event.target.value as LeaderBoardType;
            this.setState({ leaderBoardType: type })
        }

        const leaderBoardTypeKeys = Object.keys(LeaderBoardType)
            .filter((key) => Number.isNaN(+key))
            .map((key: string, value: number) => ({
                key,
                value,
            }));
        return (
            <>
                <Grid size={12}>
                    <InputLabel>LeaderBoard Type</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Select
                        value={leaderBoardType}
                        onChange={onChangeMethod}
                        variant={'outlined'}
                        style={{ width: '100%', backgroundColor: 'white' }}
                    >
                        {leaderBoardTypeKeys.map(leaderBoardType => (
                            <MenuItem key={leaderBoardType.key} value={leaderBoardType.value}>
                                {`${leaderBoardType.key.replace(/([A-Z])/g, ' $1').trim()}`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </>
        );
    };
    */
}
