import {
  Grid2 as Grid,
  IconButton,
  Select,
  MenuItem, SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  setMonth,
  getMonth,
  setYear,
  getYear,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  icon: {
    padding: 10,
    '&:hover': {
      background: 'none',
    },
  },
}));

interface HeaderProps {
  date: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const generateYears = (relativeTo: Date, count: number) => {
  const half = Math.floor(count / 2);
  return Array(count)
    .fill(0)
    .map((_y, i) => relativeTo.getFullYear() - half + i); // TODO: make part of the state
};

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
}: HeaderProps) => {
  const classes = useStyles();

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setDate(setMonth(date, event.target.value as number));
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setDate(setYear(date, event.target.value as number));
  };

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <Grid size={2}>
        <IconButton
          className={classes.icon}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <ChevronLeft />
        </IconButton>
      </Grid>
      <Grid size={4}>
        <Select
          value={getMonth(date)}
          onChange={handleMonthChange}
          MenuProps={{ disablePortal: true }}
          fullWidth={true}
        >
          {MONTHS.map((month, idx) => (
            <MenuItem key={month} value={idx}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid size={4}>
        <Select
          value={getYear(date)}
          onChange={handleYearChange}
          MenuProps={{ disablePortal: true }}
          fullWidth={true}
        >
          {generateYears(date, 30).map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>

        {/* <Typography>{format(date, "MMMM YYYY")}</Typography> */}
      </Grid>
      <Grid size={2}>
        <IconButton className={classes.icon} disabled={nextDisabled} onClick={onClickNext}>
          <ChevronRight />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
