import React from 'react';
import {
    Divider,
    ListItemText,
    Select,
    Menu,
    List,
    ListItemButton, OutlinedInput,
} from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { UserTitleRole } from '../../enums';
import { Button } from '../common';
import { styled } from '@mui/styles';

interface Props {
    readonly role: UserTitleRole;
    readonly updateRole: (role: UserTitleRole) => void;
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
    readonly role: UserTitleRole;
}

export class UserTitleRoleSelect extends React.Component<Props, State> {
    state = {
        anchorEl: undefined,
        role: UserTitleRole.NoRole,
    };

    componentDidMount() {
        const { role } = this.props;
        this.setState({ role });
    }

    componentDidUpdate(prevProps: Props) {
        const { role } = this.props;
        if ( role === prevProps.role ) {
            return;
        }

        this.setState({ role });
    }

    render() {
        const { role, anchorEl } = this.state;
        const open = !!anchorEl;
        const roles: any = [
            {
                role: UserTitleRole.Owner,
                description: 'Full access to all features. Including user management and permissions.',
            },
            {
                role: UserTitleRole.Editor,
                description: 'Edit access to all features.',
            },
            {
                role: UserTitleRole.Viewer,
                description: 'Read access to all features.',
            },
        ];

        const handleMenu = (event: any) => this.setState({ anchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ anchorEl: undefined });

        const onCancelButton = () => this.setState({
            role: this.props.role,
            anchorEl: undefined
        });
        const onChangeButton = () => {
            closeMenu();
            this.props.updateRole(role);
        };

        const renderValue = (value: number) => `${UserTitleRole[value]}`;

        return (
            <>
                <Select
                    onClose={onCancelButton}
                    onOpen={handleMenu}
                    open={open}
                    value={role}
                    fullWidth={true}
                    variant={'outlined'}
                    renderValue={renderValue}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        style: {
                            opacity: 0,
                            width: 0,
                        },
                    }}
                />
                <Menu
                    id={`filter`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    onClose={onCancelButton}
                >
                    <div style={{ display: 'flex' }}>
                        <MainList>
                            {roles.map((r: any, i: number) => {
                                const selected = r.role === role;
                                const handleListItemClick = (event: any) => this.setState({role: r.role});
                                return (
                                    <MainListItem
                                        key={`role_list_item_${i}`}
                                        selected={selected}
                                        onClick={handleListItemClick}
                                    >
                                        <div style={{ display: 'grid', gridAutoFlow: 'column', justifyContent: 'flex-start', gridColumnGap: 5}}>
                                            {selected && (
                                                <RadioButtonChecked style={{ color: '#0073bb', margin: 'auto' }}/>
                                            )}
                                            {!selected && (
                                                <RadioButtonUnchecked style={{ margin: 'auto' }}/>
                                            )}
                                            <ListItemText primary={`${UserTitleRole[r.role]}`} />
                                        </div>
                                        {selected && (
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'rgba(0,0,0,.54)',
                                                fontSize: 12,
                                                margin: '8px 32px',
                                                width: 150,
                                            }}>
                                                {r.description}
                                            </div>
                                        )}
                                    </MainListItem>
                                );
                            })}
                        </MainList>
                    </div>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8, gridColumnGap: 10 }}>
                        <Button text="Cancel" variant="text" onClick={onCancelButton} />
                        <Button text="Apply" variant="contained" onClick={onChangeButton} />
                    </div>
                </Menu>
            </>
        );
    }
}

const MainList = styled(List)(({
    '& .MuiList-root': {
        minWidth: 300,
        padding: '14px 0',
    },
}));

const MainListItem = styled(ListItemButton)(({
    '& .MuiListItem-root': {
        padding: '0px 4px 0px 16px',
        display: 'table',
        "&$selected": {
            backgroundColor: 'transparent',
            "&:disabled": {
                backgroundColor: 'transparent',
            },
            "&:hover": {
                backgroundColor: 'transparent',
            },
        }
    },
    '& .MuiListItem-selected': {
        backgroundColor: 'transparent',
        '&:disabled': {
            backgroundColor: 'transparent',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    padding: 0,
}));
