import * as React from 'react';
import { connect } from 'react-redux';
import { formatDate, getPlayerDetails, getPlayerPlatform, localeCurrencyNumber } from '../utils';
import { BaseElement, BasePlayerContainer, HeaderButton, PlayersFilter, Table, WarningDialog } from '../components';
import {
    CancelScheduleSendOutlined,
    DeveloperModeOutlined,
    ReportOutlined,
    ReportProblemOutlined,
} from '@mui/icons-material';
import { ContainerContext, mapProps } from './index';
import { Chip, Grid2 as Grid } from '@mui/material';
import actions, { ActionRequest } from '../actions';
import { useNavigate } from 'react-router';
import { styled } from '@mui/styles';
import { UserTitleRole } from '../enums';
import { default as moment } from 'moment/moment';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

type DialogType = 'ConfirmExpireSession';
interface State {
    readonly openDialog: DialogType | null;
    readonly bannedOnly: boolean;
    readonly cheaterOnly: boolean;
    readonly developerOnly: boolean;
    readonly customLoginLinkOnly: boolean;
    readonly gameCenterLoginLinkOnly: boolean;
    readonly googlePlayGamesLoginLinkOnly: boolean;
    readonly facebookLoginLinkOnly: boolean;
    readonly appleIdLoginLinkOnly: boolean;
    readonly googleLoginLinkOnly: boolean;
}

class Container extends BaseElement<Props, State> {
    state = {
        openDialog: null,
        totalCount: 0,
        bannedOnly: false,
        cheaterOnly: false,
        developerOnly: false,
        customLoginLinkOnly: false,
        gameCenterLoginLinkOnly: false,
        googlePlayGamesLoginLinkOnly: false,
        facebookLoginLinkOnly: false,
        appleIdLoginLinkOnly: false,
        googleLoginLinkOnly: false,
    };

    protected renderContainer(): React.JSX.Element {
        let buttons: HeaderButton[] | undefined;
        if (this.props.userTitleRole > UserTitleRole.Viewer) {
            buttons = [];
            buttons.push({
                text: 'Expire Players Season',
                icon: CancelScheduleSendOutlined,
                onClick: this.openConfirmExpireSessionDialog,
                color: 'secondary',
            });
        }
        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Players"}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderConfirmExpireSessionDialog()}
            </>
        );
    }

    private renderContent = () => {
        const {
            bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
        } = this.state;

        const tableRef = React.createRef();

        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onFilter = (
            banned: boolean,
            cheater: boolean,
            developer: boolean,
            customLoginLink: boolean,
            gameCenterLoginLink: boolean,
            googlePlayGamesLoginLink: boolean,
            facebookLoginLink: boolean,
            appleIdLoginLink: boolean,
            googleLoginLink: boolean,
        ) => {
            this.setState({
                bannedOnly: banned,
                cheaterOnly: cheater,
                developerOnly: developer,
                customLoginLinkOnly: customLoginLink,
                gameCenterLoginLinkOnly: gameCenterLoginLink,
                googlePlayGamesLoginLinkOnly: googlePlayGamesLoginLink,
                facebookLoginLinkOnly: facebookLoginLink,
                appleIdLoginLinkOnly: appleIdLoginLink,
                googleLoginLinkOnly: googleLoginLink,
            });
            onSearch();
        };

        const onResetBanned = () => {
            this.setState({ bannedOnly: false });
            onSearch();
        };
        const onResetCheater = () => {
            this.setState({ cheaterOnly: false });
            onSearch();
        };
        const onResetDeveloper = () => {
            this.setState({ developerOnly: false });
            onSearch();
        };

        const onResetCustomLoginLink = () => {
            this.setState({ customLoginLinkOnly: false });
            onSearch();
        };
        const onResetGameCenterLoginLink = () => {
            this.setState({ gameCenterLoginLinkOnly: false });
            onSearch();
        };
        const onResetGooglePlayGamesLoginLink = () => {
            this.setState({ googlePlayGamesLoginLinkOnly: false });
            onSearch();
        };
        const onResetFacebookLoginLink = () => {
            this.setState({ facebookLoginLinkOnly: false });
            onSearch();
        };
        const onResetAppleIdLoginLink = () => {
            this.setState({ appleIdLoginLinkOnly: false });
            onSearch();
        };
        const onResetGoogleLoginLink = () => {
            this.setState({ googleLoginLinkOnly: false });
            onSearch();
        };

        const columns = [
            { title: 'Details', field: 'details', sorting: false },
            { title: 'Platform', field: 'platform' },
            { title: 'Last Login', field: 'lastLogin', render: (rowData: any) => formatDate(rowData.lastLogin) },
            { title: 'Created', field: 'created', render: (rowData: any) => formatDate(rowData.created) },
            { title: 'Client Version', field: 'clientVersion' },
            { title: 'VTD', field: 'valueToDateUSD', render: (rowData: any) => localeCurrencyNumber(rowData.valueToDateUSD, 'USD') },
        ];

        const showChips = bannedOnly || cheaterOnly  || developerOnly ||
            customLoginLinkOnly || gameCenterLoginLinkOnly || googlePlayGamesLoginLinkOnly ||
            facebookLoginLinkOnly || appleIdLoginLinkOnly || googleLoginLinkOnly;

        const title = (
            <Grid container={true} spacing={1}>
                <Grid size={12}>
                    <PlayersFilter
                        themeMode={this.props.app.themeMode}
                        bannedOnly={bannedOnly}
                        cheaterOnly={cheaterOnly}
                        developerOnly={developerOnly}
                        customLoginLinkOnly={customLoginLinkOnly}
                        gameCenterLoginLinkOnly={gameCenterLoginLinkOnly}
                        googlePlayGamesLoginLinkOnly={googlePlayGamesLoginLinkOnly}
                        facebookLoginLinkOnly={facebookLoginLinkOnly}
                        appleIdLoginLinkOnly={appleIdLoginLinkOnly}
                        googleLoginLinkOnly={googleLoginLinkOnly}
                        onFilter={onFilter}
                    />
                </Grid>
                {showChips &&
                    <Grid size={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gridColumnGap: 5, gridGap: 5}} >
                            {bannedOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<ReportOutlined />}
                                    label='Player Type: "Banned only"'
                                    onDelete={onResetBanned}
                                />
                            )}
                            {cheaterOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<ReportProblemOutlined />}
                                    label='Player Type: "Cheater only"'
                                    onDelete={onResetCheater}
                                />
                            )}
                            {developerOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    icon={<DeveloperModeOutlined />}
                                    label='Player Type: "Developer only"'
                                    onDelete={onResetDeveloper}
                                />
                            )}
                            {customLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Custom Id"'
                                    onDelete={onResetCustomLoginLink}
                                />
                            )}
                            {gameCenterLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Game Center"'
                                    onDelete={onResetGameCenterLoginLink}
                                />
                            )}
                            {googlePlayGamesLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Google Play Games"'
                                    onDelete={onResetGooglePlayGamesLoginLink}
                                />
                            )}
                            {facebookLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Facebook"'
                                    onDelete={onResetFacebookLoginLink}
                                />
                            )}
                            {appleIdLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Apple Id"'
                                    onDelete={onResetAppleIdLoginLink}
                                />
                            )}
                            {googleLoginLinkOnly && (
                                <StyledChip
                                    color={'secondary'}
                                    size={'small'}
                                    label='Link Type: "Google"'
                                    onDelete={onResetGoogleLoginLink}
                                />
                            )}
                        </div>
                    </Grid>
                }
            </Grid>
        );

        const onRowClick = (event: any, rowData: any) => this.toLink(`/players/${rowData.playerId}/overview`);

        return (
            <Table
                tableRef={tableRef}
                columns={columns}
                title={title}
                onRowClick={onRowClick}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                }}
                data={this.getPlayers}
            />
        );
    };

    private getPlayers = async (query: any) => {
        const { bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
        } = this.state;
        const { search, page, pageSize, orderBy, orderDirection } = query;

        const result = await ActionRequest.get(`players`, {
            name: search,
            bannedOnly,
            cheaterOnly,
            developerOnly,
            customLoginLinkOnly,
            gameCenterLoginLinkOnly,
            googlePlayGamesLoginLinkOnly,
            facebookLoginLinkOnly,
            appleIdLoginLinkOnly,
            googleLoginLinkOnly,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.players.map((p: any) => {
            return {
                details: getPlayerDetails(p),
                ...p,
                platform: getPlayerPlatform(p),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private openConfirmExpireSessionDialog = () => this.setState({ openDialog: 'ConfirmExpireSession' });
    private renderConfirmExpireSessionDialog = () => {
        const { openDialog } = this.state;

        const expireSession = async () => {
            const startTimeSpam = moment.parseZone().subtract(24, 'hours');
            const endTimeSpam = moment.parseZone();
            await actions.player.expireMultiplePlayerSessions(startTimeSpam.valueOf(), endTimeSpam.valueOf());
        };

        return (
            <WarningDialog
                open={openDialog === 'ConfirmExpireSession'}
                title={'Expire Players Session'}
                content={'This will expire all the players sessions in the last 24 hours the player season'}
                onClose={this.closeDialog}
                onSubmit={expireSession}
                maxWidth={'xs'}
            />
        );
    };

    private closeDialog = () => this.setState({ openDialog: null });
}

export const StyledChip = styled(Chip)(({
    backgroundColor: '#1a73e8',
    borderRadius: 0,
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
