import { ContainerContext, mapProps } from './index';
import * as React from 'react';
import actions from '../actions';
import { BaseContainer, BaseElement, CloudBuildDetails } from '../components';
import { CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import {
    Build as BuildIcon,
    CheckCircle,
    Error as ErrorIcon,
    PlayArrow,
    RemoveCircle,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly job?: Entities.CloudBuildJobDetails;
    readonly cloudBuildId: string;
    readonly buildNumber: string;
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { cloudBuildId, buildNumber } = this.props;
        await actions.devOps.getCloudBuildVersionDetails(cloudBuildId, buildNumber);
    }

    protected renderContainer(): React.JSX.Element {
        const { job, loading } = this.props;

        let title = <></>;
        if (job) {
            let icon = <div style={{ width: 24, height: 24 }}>
                <CircularProgress style={{ color: '#0073bb', width: 24, height: 24 }} />
                <PlayArrow style={{ position: 'relative', color: '#0073bb', left: 4, top: -25, width: 16, height: 16 }}/>
            </div>;
            switch (job.result) {
                case 'Queue':
                    icon = <CircularProgress style={{ color: '#9e9e9e', width: 24, height: 24 }} />;
                    break;
                case 'Success':
                    icon = <CheckCircle style={{ color: '#4eb709', width: 24, height: 24 }}/>;
                    break;
                case 'Failed':
                    icon = <ErrorIcon style={{ color: '#d20f08', width: 24, height: 24 }}/>;
                    break;
                case 'Canceled':
                    icon = <RemoveCircle style={{ color: '#e39f28', width: 24, height: 24}}/>;
                    break;

            }

            title = (
                <div style={{ display: 'flex', gridAutoColumns: 'column', gridColumnGap: 10, justifyContent: 'flex-start', alignItems: 'center' }}>
                    {icon}
                    {` #${job.number} ${job.displayName}`}
                </div>
            );
        }

        const headlines = [
            {
                text: 'Build History',
                to: `/cloudBuild/history`,
            },
            {
                text: title,
            }
        ];

        return (
            <BaseContainer
                {...this.props}
                loading = {loading}
                themeMode={this.props.app.themeMode}
                title = {'Cloud Build'}
                TitleIcon={BuildIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { job, cloudBuildId, buildNumber } = this.props;
        const { themeMode } = this.props.app;
        if (!job) {
            return <></>;
        }

        return (
            <CloudBuildDetails
                themeMode={themeMode}
                job={job}
                cloudBuildId={cloudBuildId}
                buildNumber={buildNumber}
            />
        );
    }
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    cloudBuildId: '',
    buildNumber: '',
    job: state.devOps.jobVersion,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const cloudBuildId = params.cloudBuildId || '';
    const buildNumber = params.buildNumber || '';
    return (<Container {...props} navigate={navigate} cloudBuildId={cloudBuildId} buildNumber={buildNumber} />);
};
export default connect(mapStateToProps)(AppContainer);
