import React from 'react';
import { Hidden } from '@mui/material';
import { Drawer, DrawerProps } from './Drawer';
import { styled } from '@mui/styles';
import { darkTheme, toLink as baseToLink } from '../../utils';
import { AppHeader } from './header';
import actions from '../../actions';
import { Navigate } from 'react-router-dom';
import { ContainerContext } from '../../containers';

interface State {
}

const wideDrawerWith = 256;
const narrowDrawerWith = 68;

export class BaseElement<P extends ContainerContext.Props = ContainerContext.Props, S extends State = State> extends React.Component<P, S> {

    render() {
        if (!this.props.loginUser) {
            return <Navigate to="/" replace={true} />;
        }

        return (
            <>
                <Hidden mdUp={true}>
                    {this.renderMainSmall()}
                </Hidden>
                <Hidden mdDown={true}>
                    {this.renderMainRegular()}
                </Hidden>
                {this.renderDialogs()}
            </>
        );
    }

    protected renderMainSmall() {
        const { app, titleSelected } = this.props;
        const smDrawerOpen = this.props.app.smDrawerOpen;
        const showDrawer = titleSelected !== undefined && titleSelected.id !== '';

        return (
            <>
                {showDrawer && (
                    <StyledDrawer
                        open={true}
                        themeMode={app.themeMode}
                        variant={'temporary'}
                        openDrawer={smDrawerOpen}
                        expanded={true}
                        userTitleRole={titleSelected.userTitleRole}
                    />
                )}
                <main style={{ display: 'flex', flexDirection: 'column' }}>
                    <section style={{
                            height: showDrawer ? 42 : 50,
                            paddingBottom: showDrawer ? 8 : 10,
                            paddingTop: showDrawer ? 0 : 10
                        }}
                    >
                        {this.renderHeader(showDrawer)}
                    </section>
                    <section style={{
                            height: showDrawer ? 'calc(100% - 50px)' : 'calc(100% - 70px)',
                            display: 'flex'
                        }}
                    >
                        <section style={{
                            padding: '0px 16px',
                            flexGrow: 1,
                            width: 'calc(100% - 32px)',
                            height: '100%',
                        }}>
                            {this.renderContainer()}
                        </section>
                    </section>
                </main>
            </>
        );
    }

    protected renderMainRegular() {
        const { app, titleSelected } = this.props;
        const expandDrawer = this.props.app.showDrawer;
        const showDrawer = titleSelected && titleSelected.id !== '';

        return (
            <>
                {showDrawer && (
                    <StyledDrawer
                        open={expandDrawer}
                        themeMode={app.themeMode}
                        variant={'permanent'}
                        openDrawer={true}
                        expanded={expandDrawer}
                        userTitleRole={titleSelected.userTitleRole}
                    />
                )}
                <main
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        ...this.mainStyle()
                    }}
                >
                    <section style={{
                            height: showDrawer ? 42 : 70,
                            paddingBottom: showDrawer ? 8 : 10,
                        }}
                    >
                        {this.renderHeader(false)}
                    </section>
                    <section style={{
                            height: showDrawer ? 'calc(100% - 50px)' : 'calc(100% - 70px)',
                            display: 'flex'
                        }}
                    >
                        <section style={{
                            padding: '0px 16px',
                            flexGrow: 1,
                            width: 'calc(100% - 32px)',
                            height: '100%',
                        }}>
                            {this.renderContainer()}
                        </section>
                    </section>
                </main>

            </>
        );
    }

    protected renderContainer(): React.JSX.Element {
        return <></>;
    }

    protected renderDialogs(): React.JSX.Element {
        return <></>;
    }

    protected toLink = (to: string) => {
        baseToLink(this.props, to);
    }

    private renderHeader(small: boolean) {
        const { app, loginUser, titleSelected, titles, navigate, userRole } = this.props;
        const expandDrawer = this.props.app.showDrawer;

        return (
            <AppHeader
                position="fixed"
                navigate={navigate}
                themeMode={app.themeMode}
                userRole={userRole}
                showDrawerOpener={small}
                loginUser={loginUser as Entities.User}
                titles={titles}
                titleSelected={titleSelected}
                auth={!!loginUser}
                clickLogoutMenu={this.clickLogoutMenu}
                onTitlesMenuClick={this.handleTitlesMenu}
                style={{
                    width: small || !titleSelected || titleSelected.id === '' ? `100%` :
                        expandDrawer ? `calc(100% - ${wideDrawerWith}px)`
                            : `calc(100% - ${narrowDrawerWith}px)`,
                    height: titleSelected ? 50 : 70,
                    padding: '8px 16px',
                    backgroundColor: !titleSelected ? 'transparent' : app.themeMode === 'light' ? '#fafafa' : '#121212',
                    backgroundImage: !titleSelected ? 'url("/images/nucleo-background.png")' : 'none',
                    backgroundSize: 'cover',
                    borderRadius: 0,
                }}
            />
        );
    }

    private mainStyle(): React.CSSProperties {
        const expandDrawer = this.props.app.showDrawer;
        const { titleSelected } = this.props;

        return {
            width: !titleSelected || titleSelected.id === '' ? `100%` :
                expandDrawer ? `calc(100% - ${wideDrawerWith}px)`
                    : `calc(100% - ${narrowDrawerWith}px)`,
            marginLeft: !titleSelected || titleSelected.id === '' ? 0 : expandDrawer ? wideDrawerWith : narrowDrawerWith,
        };
    }

    private clickLogoutMenu = async () => {
        actions.title.resetTitle();
        await actions.auth.logout();
        this.redirectTo('/');
    }

    private redirectTo = (redirectTo: string) => {
        const { navigate } = this.props;
        this.toLink(redirectTo);
    }

    private handleTitlesMenu = (index: number) => {
        actions.title.resetTitle();
        if (index === -1) {
            this.redirectTo(`/titles`);
            return;
        }
        if (!this.props.titles) {
            return;
        }

        const title = this.props.titles[index];
        actions.title.setTitle(title);

        this.toLink('/dashboard');
    };
}

interface StyledDrawerProps extends DrawerProps, ThemeModeProps {
    readonly open: boolean;
}

const StyledDrawer = styled((props: StyledDrawerProps) => {
    const { themeMode } = props;
    let style = {
        width: wideDrawerWith,
        backgroundColor: themeMode === 'light' ? '#fafafa' : '#121212',
        overflow: 'hidden',
        height: '100%',
        boxShadow: 'none',
        borderRight: themeMode === 'light' ? '1px solid #e5e7e9' : '1px solid #26272b',
        borderRadius: 0,
        transition: darkTheme.transitions.create('width', {
            easing: darkTheme.transitions.easing.sharp,
            duration: darkTheme.transitions.duration.enteringScreen,
        }),
    };
    if (!props.open) {
        style = {
            width: narrowDrawerWith,
            backgroundColor: themeMode === 'light' ? '#fafafa' : '#121212',
            overflow: 'hidden',
            height: '100%',
            boxShadow: 'none',
            borderRight: themeMode === 'light' ? '1px solid #e5e7e9' : '1px solid #26272b',
            borderRadius: 0,
            transition: darkTheme.transitions.create('width', {
                easing: darkTheme.transitions.easing.sharp,
                duration: darkTheme.transitions.duration.leavingScreen,
            }),
        };
    }
    return (
        <Drawer
            {...props}
            sx={{ '& .MuiDrawer-paper': style }}
        />
    );
})(() => ({}));
