import * as React from 'react';
import { Button } from '../';
import {
    DialogContentText,
} from '@mui/material';
import { BaseDialog, BaseDialogProps } from './BaseDialog';

interface AlertDialogProps extends BaseDialogProps {
    readonly content: string;
    readonly onSubmit: () => void;
    readonly submitButtonText?: string;
    readonly disabledSubmitButton?: boolean;
}

export class AlertDialog extends BaseDialog<AlertDialogProps> {
    protected renderContent(): React.JSX.Element {
        const { content, children } = this.props;
        return (
            <>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                {children}
            </>

        );
    }

    protected renderActions(): React.JSX.Element {
        const { submitButtonText, disabledSubmitButton } = this.props;
        return (
            <Button text={submitButtonText || 'Confirm'} onClick={this.onSubmit} variant="contained" disabled={disabledSubmitButton}/>
        );
    }

    private onSubmit = () => {
        this.props.onSubmit();
        this.onClose();
    }
}
