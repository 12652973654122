import * as React from 'react';
import { ContainerContext } from '../../containers';
import { BaseContainer, CommonHeaderProps } from '../common';
import { toLink } from '../../utils';
import { PropsWithChildren } from 'react';

interface Props extends ContainerContext.Props, CommonHeaderProps, PropsWithChildren {
}

export class BaseSettingsContainer extends React.Component<Props> {
    render() {
        const { loading, themeMode, TitleIcon, headline, headlines, children, buttons } = this.props;
        const subTabsTitles = ['General', 'User and permissions'];

        return (
            <BaseContainer
                loading= {loading}
                themeMode={themeMode}
                title = {'Settings'}
                TitleIcon={TitleIcon}
                headline = {headline}
                headlines = {headlines}
                subTabs={{
                    titles: subTabsTitles,
                    selected: this.subTabsSelected(),
                    onChange: this.changeSubTabs,
                }}
                buttons={buttons}
            >
                {children}
            </BaseContainer>
        );
    }

    private subTabsSelected = () => {
        if (location.pathname.includes(this.getPath('general'))) {
            return  0;
        } else if (location.pathname.includes(this.getPath('iam'))) {
            return  1;
        }

        return 0;
    };

    private changeSubTabs = (tab: number) => {
        let to = '';
        switch (tab) {
            case 0:
                to = this.getPath('general');
                break;
            case 1:
                to = this.getPath('iam');
                break;
        }
        toLink(this.props, to);
    };

    private getPath = (component: string) => {
        return `/settings/${component}`;
    };
}
