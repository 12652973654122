import React from 'react';
import { TextField, Button } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface Props extends BaseDialogProps {
    readonly onSend: (title: string, body: string) => void;
}

interface State extends BaseDialogState {
    readonly title: string;
    readonly body: string;
}

export class PlayerSendPushNotificationDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { title, body } = this.state;

        return (
            <>
                <TextField
                    fullWidth={true}
                    label="Title"
                    multiline={true}
                    value={title}
                    onChange={this.onTitleChange}
                />
                <TextField
                    fullWidth={true}
                    label="Body"
                    multiline={true}
                    value={body}
                    onChange={this.onBodyChange}
                />
            </>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Send" variant="contained" onClick={this.onSend}/>
        );
    }

    protected initState(): State {
        return {
            title: '',
            body: '',
        };
    }

    private onTitleChange = (title: string) => this.setState({ title });

    private onBodyChange = (body: string) => this.setState({ body });

    private onSend = () => {
        const { title, body } = this.state;
        this.props.onSend(title, body);
        this.onClose();
    }
}
