import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { ContainerContext, mapProps } from './';
import { BaseContainer, AceEditor, BaseElement } from '../components';
import { Grid2 as Grid } from '@mui/material';
import { CloudOutlined as CloudIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly revision: string;
    readonly cloudCodeScripts: Entities.CloudCode[];
    readonly cloudCodeSelected?: Entities.CloudCode;
}

type DialogType = 'Upload';
interface State {
    readonly openDialog: DialogType | null;
    readonly file: any;
    readonly filename: string;
    readonly isLive: boolean;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        file: null,
        filename: '',
        isLive: false,
    };

    async componentDidMount() {
        const { revision } = this.props;
        await actions.cloudCode.get(parseInt(revision, 10));
    }

    protected renderContainer(): React.JSX.Element {
        const { loading, revision } = this.props;
        const headlines = [
            {
                text: 'Cloud Code',
                to: `/cloudCode/all`,
            },
            {
                text: revision,
            },
        ];
        return (
            <BaseContainer
                loading = {loading}
                themeMode={this.props.app.themeMode}
                title = "Cloud Code Details"
                TitleIcon = {CloudIcon}
                headlines={headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { cloudCodeScripts, cloudCodeSelected } = this.props;
        const { themeMode } = this.props.app

        if (!cloudCodeSelected) {
            return;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <Grid size={12}>
                    <div style={{
                        margin: 'auto',
                        borderRadius: 8,
                        boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                            : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                        backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                        border: 'none',
                    }}>
                        <AceEditor
                            mode={"javascript"}
                            value={cloudCodeSelected.file}
                            readOnly={true}
                            height={`${screen.height-320}px`}
                            showGoTo={true}
                            style={{ width: '100%', borderRadius: 8 }}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    revision: '',
    cloudCodeScripts: state.cloudCode.scripts,
    cloudCodeSelected: state.cloudCode.selected,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const revision = params.revision || '';
    return (<Container {...props} navigate={navigate} revision={revision}/>);
};
export default connect(mapStateToProps)(AppContainer);
