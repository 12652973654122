import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { ContainerContext, mapProps } from './';
import {
    formatDate,
    getCloudCodeRevisionLink,
    getContentVersionLink,
    getTitleDataVersionLink,
} from '../utils';
import {
    BaseContainer,
    CreateClientVersionDialog,
    UpdateClientVersionDialog,
    AlertDialog,
    WarningDialog,
    CreateClientVersionOverrideDialog,
    DeveloperClientVersionOverride,
    Table,
    BaseElement,
    HeaderButton,
} from '../components';
import {
    AddOutlined,
    CheckCircleOutline,
    NotesOutlined,
    LabelOutlined,
    LabelOffOutlined,
    DeleteForeverOutlined,
    EditOutlined,
    DeveloperModeOutlined,
    CheckCircleOutlineOutlined,
    NotesOutlined as NotesIcon
} from '@mui/icons-material';
import * as _ from 'lodash';
import { Chip, Grid2 as Grid, Typography } from '@mui/material';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly clientVersions?: Entities.ClientVersion[];
    readonly clientVersionOverrides?: Entities.ClientVersionOverride[];
    readonly cloudCodeRevisions?: Entities.CloudCode[];
    readonly titleDataVersions?: Entities.TitleData[];
    readonly contentVersions?: Entities.FileVersion[];
}

type DialogType = 'Create' | 'ConfirmSetAsDefault' | 'ConfirmForceUpdate' | 'ConfirmRemoveForceUpdate' | 'Update' | 'Delete' | 'CreateOverride' | 'DeleteOverride';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedVersion: string | null;
    readonly selectedSubVersion: string | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedVersion: null,
        selectedSubVersion: null,
    };

    async componentDidMount() {
        await actions.clientVersion.list();
    }

    protected renderContainer(): React.JSX.Element {
        const { loading } = this.props;
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseContainer
                loading = {loading}
                themeMode={this.props.app.themeMode}
                title = "Client Versions"
                TitleIcon = {NotesOutlined}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderUpdateDialog()}
                {this.renderConfirmDeleteDialog()}
                {this.renderSetAsDefaultConfirmDialog()}
                {this.renderConfirmForceUpdateDialog()}
                {this.renderConfirmRemoveForceUpdateDialog()}
                {this.renderCreateClientVersionOverrideDialog()}
                {this.renderConfirmDeleteOverrideDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { app, clientVersions, clientVersionOverrides, userTitleRole } = this.props;
        if (!clientVersions || !clientVersionOverrides) {
           return;
        }
        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Content', field: 'content'},
        ];

        const data = _.map(
            clientVersions.sort((c1, c2) => (c1.version < c2.version) ? 1 : (c1.version > c2.version) ? -1 : 0),
            v => {
                const overrides = clientVersionOverrides.filter(c => c.version === v.version);
                return {
                    details: this.getDetails(v),
                    content: this.getContent(v),
                    version: v.version,
                    forceUpdate: v.forceUpdate,
                    cloudCodeRevision: v.cloudCodeRevision,
                    titleDataVersion: v.titleDataVersion,
                    contentVersion: v.contentVersion,
                    updated: formatDate(v.updated),
                    live: v.live,
                    overrides,
                };
            }
        );

        return (
            <Table
                data={data}
                columns={columns}
                detailPanel={[
                    rowData => ({
                        disabled: rowData.overrides.length === 0,
                        tooltip: 'Show Overrides',
                        render: this.getOverridePanel,
                    })
                ]}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    rowStyle: ((rowData, index, level) => {
                        if (rowData.live) {
                            return {
                                backgroundColor: app.themeMode === 'light' ? '#f1faff' : '#3f3f3f'
                            };
                        }
                        return {
                            backgroundColor: 'transparent',
                        };
                    }),
                    actionsColumnIndex: -1
                }}
                actions={ userTitleRole > UserTitleRole.Viewer ?
                    [
                        rowData => ({
                            icon: CheckCircleOutlineOutlined,
                            tooltip: 'Set Default',
                            hidden: rowData.live,
                            onClick: () => this.setState({ openDialog: 'ConfirmSetAsDefault', selectedVersion: rowData.version})
                        }),
                        rowData => ({
                            icon: LabelOutlined,
                            tooltip: 'Force Update',
                            hidden: !rowData.live || rowData.forceUpdate,
                            onClick: () => this.setState({ openDialog: 'ConfirmForceUpdate', selectedVersion: rowData.version})
                        }),
                        rowData => ({
                            icon: LabelOffOutlined,
                            tooltip: 'Remove Force Update',
                            hidden: !rowData.forceUpdate,
                            onClick: () => this.setState({ openDialog: 'ConfirmRemoveForceUpdate', selectedVersion: rowData.version})
                        }),
                        rowData => ({
                            icon: DeveloperModeOutlined,
                            tooltip: 'Add Developer Override',
                            hidden: rowData.overrides.length > 0,
                            onClick: () => this.setState({ openDialog: 'CreateOverride', selectedVersion: rowData.version})
                        }),
                        rowData => ({
                            icon: EditOutlined,
                            tooltip: 'Edit',
                            onClick: () => this.setState({ openDialog: 'Update', selectedVersion: rowData.version})
                        }),
                        rowData => ({
                            icon: DeleteForeverOutlined,
                            tooltip: 'Delete',
                            hidden: rowData.live,
                            onClick: () => this.setState({ openDialog: 'Delete', selectedVersion: rowData.version})
                        }),
                    ]
                    : undefined
                }
            />
        );
    };

    private getDetails = (clientVersion: Entities.ClientVersion) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0}>
                {clientVersion.live && (
                    <Grid size={12}>
                        <Chip
                            color={'secondary'}
                            icon={<CheckCircleOutline />}
                            size='small'
                            label='Default'
                            style={{
                                width: 120,
                                backgroundColor: '#2c3e50',
                                color: 'white',
                                margin: '2px 0px',
                                borderRadius: 16,
                            }}
                        />
                    </Grid>
                )}
                <Grid size={12}>
                    <Typography
                        variant={'subtitle1'}
                        style={{
                            textDecoration: 'none',
                            fontSize: 14,
                            fontWeight: 600,
                            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                        }}>
                        {clientVersion.version}
                    </Typography>
                </Grid>
                <Grid size={12}>
                    Last edited: {formatDate(clientVersion.updated)}
                </Grid>
                {clientVersion.forceUpdate && (
                    <Grid size={12}>
                        <Typography
                            variant={'subtitle2'}
                            style={{
                                textDecoration: 'none',
                                color: '#21ba47',
                                fontWeight: 400,
                                display: 'inline-flex',
                                alignItems: 'center',
                                fontSize: 13,
                                fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                            }}
                        >
                            <LabelOutlined style={{ width: 18, height: 18, paddingRight: 4 }} />
                            Force Update
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    };

    private getContent = (clientVersion: Entities.ClientVersion) => {
        return (
            <Grid container={true} justifyContent="center" spacing={0}>
                {clientVersion.cloudCodeRevision &&
                    <Grid size={12}>
                        CloudCode Revision: {getCloudCodeRevisionLink(clientVersion.cloudCodeRevision)}
                    </Grid>
                }
                {clientVersion.titleDataVersion &&
                    <Grid size={12}>
                        Title Data Version: {getTitleDataVersionLink(clientVersion.titleDataVersion)}
                    </Grid>
                }
                {clientVersion.contentVersion &&
                    <Grid size={12}>
                        Content Version: {getContentVersionLink(clientVersion.contentVersion)}
                    </Grid>
                }
            </Grid>
        );
    };

    private getOverridePanel = (rowData: any) => {
        if(!rowData || !rowData.overrides || rowData.overrides.length === 0) {
            return null;
        }

        const override = rowData.overrides.find((o: any) => o.subVersion === 'developer');
        if (!override) {
            return null;
        }

        const { cloudCodeRevisions, titleDataVersions, contentVersions, userTitleRole } = this.props;
        if (!cloudCodeRevisions ||  !titleDataVersions || !contentVersions) {
            return null;
        }
        const onUpdate = (version: string, clientVersionOverride: Entities.ClientVersionOverride) => actions.clientVersion.updateOverride(version, clientVersionOverride);
        const onDelete = (version: string, subVersion: string) => this.setState({ openDialog: 'DeleteOverride', selectedVersion: version, selectedSubVersion: subVersion});

        return (
            <div style={{ padding: '10px 16px' }}>
                <DeveloperClientVersionOverride
                    clientVersionOverride = {override}
                    cloudCodeRevisions = {cloudCodeRevisions}
                    titleDataVersions = {titleDataVersions}
                    contentVersions = {contentVersions}
                    userTitleRole = {userTitleRole}
                    onUpdate = {onUpdate}
                    onDelete = {onDelete}
                />
            </div>
        );
    };

    private closeDialog = () => this.setState({
        openDialog: null,
        selectedVersion: null,
        selectedSubVersion: null,
    });

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });

    private renderCreateDialog = () => {
        const { clientVersions, cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { openDialog } = this.state;
        if (!clientVersions || !cloudCodeRevisions || !titleDataVersions || !contentVersions) {
            return;
        }

        return (
            <CreateClientVersionDialog
                open = {openDialog === 'Create'}
                title={'Create Client Version'}
                TitleIcon={NotesIcon}
                onClose = {this.closeDialog}
                onCreate = {this.createClientVersion}
                clientVersions = {clientVersions}
                cloudCodeRevisions = {cloudCodeRevisions}
                titleDataVersions = {titleDataVersions}
                contentVersions = {contentVersions}
            />
        );
    };
    private createClientVersion = async (clientVersion: Entities.ClientVersion) => {
        await actions.clientVersion.create(clientVersion);
    };

    private renderUpdateDialog = () => {
        const { clientVersions, cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { openDialog, selectedVersion } = this.state;

        if (!clientVersions || !cloudCodeRevisions || !titleDataVersions || !contentVersions) {
            return;
        }
        const selectedClientVersion = clientVersions.find(c => c.version === selectedVersion);
        if (!selectedClientVersion) {
            return;
        }
        return (
            <UpdateClientVersionDialog
                open = {openDialog === 'Update'}
                title={'Update Client Version'}
                TitleIcon={NotesIcon}
                onClose = {this.closeDialog}
                onUpdate = {this.updateClientVersion}
                selectedClientVersion={selectedClientVersion}
                cloudCodeRevisions = {cloudCodeRevisions}
                titleDataVersions = {titleDataVersions}
                contentVersions = {contentVersions}
            />
        );
    };
    private updateClientVersion = async (clientVersion: Entities.ClientVersion) => {
        await actions.clientVersion.update(clientVersion);
    };

    private renderConfirmDeleteDialog = () => {
        const { openDialog, selectedVersion } = this.state;
        if (!selectedVersion) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title="Delete"
                content={`This will permanently delete '${selectedVersion}' client version.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteClientVersion}
                maxWidth={'xs'}
            />
        );
    };
    private deleteClientVersion = async () => {
        this.closeDialog();
        const { selectedVersion } = this.state;
        if(!selectedVersion) {
            return;
        }
        await actions.clientVersion.remove(selectedVersion);
    };

    private renderConfirmForceUpdateDialog = () => {
        const { openDialog, selectedVersion } = this.state;
        if (!selectedVersion) {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ConfirmForceUpdate'}
                title="Set Force Update"
                content={`Are you sure you want set ${selectedVersion} as force update?`}
                submitButtonText={'Confirm'}
                onClose={this.closeDialog}
                onSubmit={this.onForceUpdate}
            />
        );
    };
    private onForceUpdate = async () => {
        this.closeDialog();
        const { clientVersions } = this.props;
        const { selectedVersion } = this.state;
        if(!clientVersions || !selectedVersion) {
            return;
        }

        const selectedClientVersion = clientVersions.find(c => c.version === selectedVersion);
        if (!selectedClientVersion) {
            return;
        }

        selectedClientVersion.forceUpdate = true;
        await actions.clientVersion.update(selectedClientVersion);
    };

    private renderConfirmRemoveForceUpdateDialog = () => {
        const { openDialog, selectedVersion } = this.state;
        if (!selectedVersion) {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ConfirmRemoveForceUpdate'}
                title="Remove Force Update"
                content={`Are you sure you want to remove force update from ${selectedVersion}?`}
                submitButtonText={'Confirm'}
                onClose={this.closeDialog}
                onSubmit={this.onRemoveForceUpdate}
            />
        );
    };
    private onRemoveForceUpdate = async () => {
        this.closeDialog();
        const { clientVersions } = this.props;
        const { selectedVersion } = this.state;
        if(!clientVersions || !selectedVersion) {
            return;
        }

        const selectedClientVersion = clientVersions.find(c => c.version === selectedVersion);
        if (!selectedClientVersion) {
            return;
        }

        selectedClientVersion.forceUpdate = false;
        await actions.clientVersion.update(selectedClientVersion);
    };

    private renderSetAsDefaultConfirmDialog = () => {
        const { openDialog, selectedVersion } = this.state;
        if (!selectedVersion) {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ConfirmSetAsDefault'}
                title="Set as Default"
                content={`Are you sure you want set ${selectedVersion} as default?`}
                submitButtonText={'Confirm'}
                onClose={this.closeDialog}
                onSubmit={this.setLiveClientVersion}
            />
        );
    };
    private setLiveClientVersion = async () => {
        this.closeDialog();
        const { selectedVersion } = this.state;
        if(!selectedVersion) {
            return;
        }
        await actions.clientVersion.setLive(selectedVersion);
    };

    private renderCreateClientVersionOverrideDialog = () => {
        const { cloudCodeRevisions, titleDataVersions, contentVersions } = this.props;
        const { openDialog, selectedVersion } = this.state;
        if (!cloudCodeRevisions || !titleDataVersions || !contentVersions) {
            return;
        }
        if (!selectedVersion) {
            return;
        }
        return (
            <CreateClientVersionOverrideDialog
                open = {openDialog === 'CreateOverride'}
                title={'Create Client Version'}
                TitleIcon={NotesIcon}
                onClose = {this.closeDialog}
                onCreate = {this.createClientVersionOverride}
                version = {selectedVersion}
                subVersion= {'developer'}
                cloudCodeRevisions = {cloudCodeRevisions}
                titleDataVersions = {titleDataVersions}
                contentVersions = {contentVersions}
            />
        );
    };
    private createClientVersionOverride = async (version: string, clientVersionOverride: Entities.ClientVersionOverride) => {
        this.closeDialog();
        await actions.clientVersion.createOverride(version, clientVersionOverride);
    };

    private renderConfirmDeleteOverrideDialog = () => {
        const { openDialog, selectedVersion, selectedSubVersion } = this.state;
        if (!selectedVersion) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'DeleteOverride'}
                title="Delete"
                content={`This will permanently delete '${selectedSubVersion}' client version override.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteClientVersionOverride}
                maxWidth={'xs'}
            />
        );
    };
    private deleteClientVersionOverride = async () => {
        this.closeDialog();
        const { selectedVersion, selectedSubVersion } = this.state;
        if(!selectedVersion || !selectedSubVersion) {
            return;
        }
        await actions.clientVersion.deleteOverride(selectedVersion, selectedSubVersion);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    clientVersions: state.clientVersion.clientVersions,
    clientVersionOverrides: state.clientVersion.clientVersionOverrides,
    cloudCodeRevisions: state.clientVersion.cloudCodeRevisions,
    titleDataVersions: state.clientVersion.titleDataVersions,
    contentVersions: state.clientVersion.contentVersions,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
