import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import actions from '../actions';
import { darkTheme, lightTheme } from '../utils';
import { ErrorDialog, Loading } from '../components';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import Achievements from './Achievements';
import Admin from './Admin';
import AnalyzePlayerAchievements from './AnalyzePlayerAchievements';
import AnalyzePlayerCompensations from './AnalyzePlayerCompensations';
import AnalyzePlayerCustomLoginKeys from './AnalyzePlayerCustomLoginKeys';
import AnalyzePlayerFacebookProfiles from './AnalyzePlayerFacebookProfiles';
import AnalyzePlayerFirebaseData from './AnalyzePlayerFirebaseData';
import AnalyzePlayerGameCenterLoginKeys from './AnalyzePlayerGameCenterLoginKeys';
import AnalyzePlayerGoogleLoginKeys from './AnalyzePlayerGoogleLoginKeys';
import AnalyzePlayerLiveEvents from './AnalyzePlayerLiveEvents';
import AnalyzePlayerMatchMakings from './AnalyzePlayerMatchMakings';
import AnalyzePlayerPayments from './AnalyzePlayerPayments';
import AnalyzePlayerSeasons from './AnalyzePlayerSeasons';
import AnalyzePlayerStats from './AnalyzePlayerStats';
import EconomyBundles from './EconomyBundles';
import EconomyItems from './EconomyItems';
import EconomyItemNew from './EconomyItemNew';
import EconomyItemDetail from './EconomyItemDetail';
import EconomyItemDuplicate from './EconomyItemDuplicate';
import EconomyStore from './EconomyStore';
import EconomyStoreItemDetails from './EconomyStoreItemDetails';
import EconomyStoreItemNew from './EconomyStoreItemNew';
import EconomyStoreItemDuplicate from './EconomyStoreItemDuplicate';
import EconomyStoreItemEdit from './EconomyStoreItemEdit';
import ClientVersion from './ClientVersion';
import CloudCode from './CloudCode';
import CloudCodeDetails from './CloudCodeDetails';
import EconomyCurrencies from './EconomyCurrencies';
import PlayerCompensations from './PlayerCompensations';
import Dashboard from './Dashboard';
import FileManagement from './FileManagement';
import Groups from './Groups';
import GroupDetails from './GroupDetails';
import LeaderBoardDetails from './LeaderBoardDetails';
import LeaderBoards from './LeaderBoards';
import LiveEventCreateLeaderBoard from './LiveEventCreateLeaderBoard';
import LiveEventCreateRegular from './LiveEventCreateRegular';
import LiveEventDuplicate from './LiveEventDuplicate';
import LiveEventEdit from './LiveEventEdit';
import LiveEvents from './LiveEvents';
import LiveEventsDetail from './LiveEventsDetail';
import Localization from './Localization';
import Login from './Login';
import PlayerAchievement from './PlayerAchievements';
import PlayerData from './PlayerData';
import PlayerInventory from './PlayerInventory';
import PlayerOverview from './PlayerOverview';
import PlayerPayments from './PlayerPayments';
import PlayerWallet from './PlayerWallet';
import Players from './Players';
import PlayerStatistics from './PlayerStatistics';
import PlayerLiveEvents from './PlayerLiveEvents';
import PlayerSeasons from './PlayerSeasons';
import PlayerMatchMaking from './PlayerMatchMaking';
import PlayerCloudCode from './PlayerCloudCode';
import PromoCampaigns from './PromoCampaigns';
import PromoCampaignCreate from './PromoCampaignCreate';
import PromoCampaignDetails from './PromoCampaignDetails';
import Seasons from './Seasons';
import SeasonDetails from './SeasonDetails';
import SeasonCreateRegular from './SeasonCreateRegular';
import SeasonCreateLeaderBoard from './SeasonCreateLeaderBoard';
import SeasonDuplicate from './SeasonDuplicate';
import SeasonEdit from './SeasonEdit';
import Segments from './Segments';
import SettingsGeneral from './SettingsGeneral';
import SettingsIam from './SettingsIam';
import TitleData from './TitleData';
import TitleNews from './TitleNews';
import TitleNewsCreate from './TitleNewsCreate';
import Titles from './Titles';
import AppleSupport from './AppleSupport';
import CompensationBundles from './CompensationBundles';
import CompensationPreDefineItems from './CompensationPreDefineItems';
import CloudBuildHistory from './CloudBuildHistory';
import CloudBuildDetails from './CloudBuildDetails';
import CloudBuildConfig from './CloudBuildConfig';
import { StateMap } from '../reducers';

export interface AppContainerProps extends ThemeModeProps {
    readonly loginUser: Entities.User | null | undefined;
    readonly error: Error | null;
}

interface AppContainerState {
    readonly loading: boolean;
}

class Container<P extends AppContainerProps = AppContainerProps> extends React.PureComponent<P, AppContainerState> {
    state: AppContainerState = {
        loading: true,
    };

    async componentDidMount() {
        await actions.app.getInfo();
        this.setState({ loading: false });
    }

    render() {
        const { themeMode } = this.props;
         const { loading} = this.state;

        return (
            <ThemeProvider theme={themeMode === 'dark' ? darkTheme : lightTheme}>
                <CssBaseline />
                <GlobalStyles
                    styles={{
                        body: {
                            backgroundColor: themeMode === 'light' ? '#fafafa' : '#121212',
                            color: themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffffe6',
                        },
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    {this.renderLoading(themeMode, loading)}
                    {this.renderRoutes(loading)}
                    <ErrorDialog error={this.props.error} />
                </LocalizationProvider>
            </ThemeProvider>
        );
    }

    private renderLoading(themeMode: ThemeMode, loading: boolean): React.ReactNode {
        if (!loading) {
            return <></>;
        }

        return (
            <Loading themeMode={themeMode}/>
        );
    }

    private renderRoutes(loading: boolean): React.ReactNode {
        if (loading) {
            return <></>;
        }

        return (
            <BrowserRouter>
                <Routes>
                    <Route key="login" path="/" element={<Login />}/>
                    <Route key="titles" path="/titles" element={<Titles />} />
                    <Route key="admin" path="/admin" element={<Admin />} />
                    <Route key="overview" path={`/:titleId/dashboard`} element={<Dashboard />} />
                    <Route key="economyItemDetail" path={`/:titleId/economy/item/:itemId/edit`} element={<EconomyItemDetail />} />
                    <Route key="economyItems" path={`/:titleId/economy/items`} element={<EconomyItems />} />
                    <Route key="economyItemNew" path={`/:titleId/economy/newItem`} element={<EconomyItemNew />} />
                    <Route key="economyItemDuplicate" path={`/:titleId/economy/item/:itemId/duplicate`} element={<EconomyItemDuplicate />} />
                    <Route key="economyCurrencies" path={`/:titleId/economy/currencies`} element={<EconomyCurrencies />} />
                    <Route key="economyBundles" path={`/:titleId/economy/bundles`} element={<EconomyBundles />} />
                    <Route key="economyStore" path={`/:titleId/economy/store`} element={<EconomyStore />} />
                    <Route key="economyStoreItemDetails" path={`/:titleId/economy/store/:storeItemId/details`} element={<EconomyStoreItemDetails />} />
                    <Route key="economyStoreNew" path={`/:titleId/economy/store/new`} element={<EconomyStoreItemNew />} />
                    <Route key="economyStoreDuplicate" path={`/:titleId/economy/store/:storeItemId/duplicate`} element={<EconomyStoreItemDuplicate />} />
                    <Route key="economyStoreEdit" path={`/:titleId/economy/store/:storeItemId/edit`} element={<EconomyStoreItemEdit />} />
                    <Route key="cloudCode" path={`/:titleId/cloudCode/all`} element={<CloudCode />} />
                    <Route key="cloudCodeDetails" path={`/:titleId/cloudCode/:revision`} element={<CloudCodeDetails />} />
                    <Route key="titleDataVersion" path={`/:titleId/titleData/:version`} element={<TitleData />} />
                    <Route key="titleNews" path={`/:titleId/titleNews/all`} element={<TitleNews />} />
                    <Route key="titleNewsCreate" path={`/:titleId/titleNews/create`} element={<TitleNewsCreate />} />
                    <Route key="files" path={`/:titleId/files/:version`} element={<FileManagement />} />
                    <Route key="achievements" path={`/:titleId/achievements/all`} element={<Achievements />} />
                    <Route key="players" path={`/:titleId/players/all`} element={<Players />} />
                    <Route key="segments" path={`/:titleId/segments/all`} element={<Segments />} />
                    <Route key="playerOverview" path={`/:titleId/players/:playerId/overview`} element={<PlayerOverview />} />
                    <Route key="playerCloudCode" path={`/:titleId/players/:playerId/cloudCode`} element={<PlayerCloudCode />} />
                    <Route key="playerData" path={`/:titleId/players/:playerId/data`} element={<PlayerData />} />
                    <Route key="playerAchievements" path={`/:titleId/players/:playerId/achievements`} element={<PlayerAchievement />} />
                    <Route key="playerCompensations" path={`/:titleId/players/:playerId/compensations`} element={<PlayerCompensations />} />
                    <Route key="playerInventory" path={`/:titleId/players/:playerId/inventory`} element={<PlayerInventory />} />
                    <Route key="playerWallet" path={`/:titleId/players/:playerId/wallet`} element={<PlayerWallet />} />
                    <Route key="playerPayments" path={`/:titleId/players/:playerId/purchases`} element={<PlayerPayments />} />
                    <Route key="playerStatistics" path={`/:titleId/players/:playerId/statistics`} element={<PlayerStatistics />} />
                    <Route key="playerLiveEvents" path={`/:titleId/players/:playerId/liveEvents`} element={<PlayerLiveEvents />} />
                    <Route key="playerSeasons" path={`/:titleId/players/:playerId/seasons`} element={<PlayerSeasons />} />
                    <Route key="playerMatchMaking" path={`/:titleId/players/:playerId/matchMaking`} element={<PlayerMatchMaking />} />
                    <Route key="season" path={`/:titleId/season/all`} element={<Seasons />} />
                    <Route key="seasonDetails" path={`/:titleId/season/:seasonId/details`} element={<SeasonDetails />} />
                    <Route key="seasonCreateRegular" path={`/:titleId/season/createRegular`} element={<SeasonCreateRegular />} />
                    <Route key="seasonCreateLeaderBoard" path={`/:titleId/season/createLeaderBoard`} element={<SeasonCreateLeaderBoard />} />
                    <Route key="seasonEdit" path={`/:titleId/season/:seasonId/edit`} element={<SeasonEdit />} />
                    <Route key="seasonDuplicate" path={`/:titleId/season/:seasonId/duplicate`} element={<SeasonDuplicate />} />
                    <Route key="liveEvents" path={`/:titleId/liveEvent/all`} element={<LiveEvents />} />
                    <Route key="liveEventsDetail" path={`/:titleId/liveEvent/:liveEventId/details`} element={<LiveEventsDetail />} />
                    <Route key="liveEventsCreateRegular" path={`/:titleId/liveEvent/createRegular`} element={<LiveEventCreateRegular />} />
                    <Route key="liveEventsCreateLeaderBoard" path={`/:titleId/liveEvent/createLeaderBoard`} element={<LiveEventCreateLeaderBoard />} />
                    <Route key="liveEventsDuplicate" path={`/:titleId/liveEvent/:liveEventId/duplicate`} element={<LiveEventDuplicate />} />
                    <Route key="liveEventsEdit" path={`/:titleId/liveEvent/:liveEventId/edit`} element={<LiveEventEdit />} />
                    <Route key="localization" path={`/:titleId/localization`} element={<Localization />} />
                    <Route key="leaderBoard" path={`/:titleId/leaderBoard/all`} element={<LeaderBoards />} />
                    <Route key="leaderBoardDetails" path={`/:titleId/leaderBoard/:name/details`} element={<LeaderBoardDetails />} />
                    <Route key="groups" path={`/:titleId/group/all`} element={<Groups />} />
                    <Route key="groups" path={`/:titleId/group/:groupId/details`} element={<GroupDetails />} />
                    <Route key="clientVersion" path={`/:titleId/clientVersion/all`} element={<ClientVersion />} />
                    <Route key="settings" path={`/:titleId/settings/general`} element={<SettingsGeneral />} />
                    <Route key="settings" path={`/:titleId/settings/iam`} element={<SettingsIam />} />
                    <Route key="analyzePlayerAchievements" path={`/:titleId/analyzePlayersData/achievements`} element={<AnalyzePlayerAchievements />} />
                    <Route key="analyzePlayerCompensations" path={`/:titleId/analyzePlayersData/compensations`} element={<AnalyzePlayerCompensations />} />
                    <Route key="analyzePlayerCustomLoginKeys" path={`/:titleId/analyzePlayersData/customLoginKeys`} element={<AnalyzePlayerCustomLoginKeys />} />
                    <Route key="analyzePlayerFacebookProfiles" path={`/:titleId/analyzePlayersData/facebookProfiles`} element={<AnalyzePlayerFacebookProfiles />} />
                    <Route key="analyzePlayerFirebaseData" path={`/:titleId/analyzePlayersData/firebaseData`} element={<AnalyzePlayerFirebaseData />} />
                    <Route key="analyzePlayerGameCenterLoginKeys" path={`/:titleId/analyzePlayersData/gameCenterLoginKeys`} element={<AnalyzePlayerGameCenterLoginKeys />} />
                    <Route key="analyzePlayerGoogleLoginKeys" path={`/:titleId/analyzePlayersData/googleLoginKeys`} element={<AnalyzePlayerGoogleLoginKeys />} />
                    <Route key="analyzePlayerLiveEvents" path={`/:titleId/analyzePlayersData/liveEvents`} element={<AnalyzePlayerLiveEvents />} />
                    <Route key="analyzePlayerMatchMakings" path={`/:titleId/analyzePlayersData/matchMakings`} element={<AnalyzePlayerMatchMakings />} />
                    <Route key="analyzePlayerPayments" path={`/:titleId/analyzePlayersData/payments`} element={<AnalyzePlayerPayments />} />
                    <Route key="analyzePlayerSeasons" path={`/:titleId/analyzePlayersData/seasons`} element={<AnalyzePlayerSeasons />} />
                    <Route key="analyzePlayerStats" path={`/:titleId/analyzePlayersData/stats`} element={<AnalyzePlayerStats />} />
                    <Route key="compensationPreDefinedItems" path={`/:titleId/compensation/preDefinedItems`} element={<CompensationPreDefineItems />} />
                    <Route key="compensationBundles" path={`/:titleId/compensation/bundles`} element={<CompensationBundles />} />
                    <Route key="compensation" path={`/:titleId/appleSupport/all`} element={<AppleSupport />} />
                    <Route key="cloudBuildHistory" path={`/:titleId/cloudBuild/history`} element={<CloudBuildHistory />} />
                    <Route key="cloudBuildDetails" path={`/:titleId/cloudBuild/details/:cloudBuildId/:buildNumber`} element={<CloudBuildDetails />} />
                    <Route key="cloudBuildConfig" path={`/:titleId/cloudBuild/config`} element={<CloudBuildConfig />} />
                    <Route key="promoCampaigns" path={`/:titleId/marketing/promoCampaigns`} element={<PromoCampaigns />} />
                    <Route key="promoCampaignCreate" path={`/:titleId/marketing/promoCampaign/create`} element={<PromoCampaignCreate />} />
                    <Route key="promoCampaignDetails" path={`/:titleId/marketing/promoCampaign/:campaignId/details`} element={<PromoCampaignDetails />} />
                </Routes>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state: StateMap): AppContainerProps => ({
    themeMode: state.app.themeMode,
    error: state.app.error,
    loginUser: state.user.loginUser,
});
const AppContainer = (props: AppContainerProps) =>
{
    return (<Container {...props} />);
};
export default connect(mapStateToProps)(AppContainer);
