import * as React from 'react';
import { Select as MuiSelect, OutlinedInput, SelectChangeEvent, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
    readonly value: number;
    readonly labelId?: string;
    readonly children: React.ReactNode;
    readonly onChange?: (value: number) => void;
    readonly sx?: SxProps<Theme>;
}

export class NumberSelect extends React.PureComponent<Props> {
    render() {
        return (
            <MuiSelect
                {...this.props}
                onChange={this.onChange}
                native={true}
                fullWidth={true}
                input={<OutlinedInput style={{ border: 'none' }}/>}
                variant={'outlined'}
            >
                {this.props.children}
            </MuiSelect>
        );
    }

    private onChange = (event: SelectChangeEvent<number>) => this.props.onChange && this.props.onChange(Number(event.target.value));
}