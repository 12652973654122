import * as React from 'react';
import { formatNumber } from '../utils';
import { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import { BaseElement, BasePlayerContainer, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
}

class Container extends BaseElement<Props> {
    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Statistics"}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Version', field: 'version'},
            { title: 'Value', field: 'value'},
        ];
        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { playerId } = this.props;
        if (!playerId) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`player/${playerId}/statistics`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.PlayerStatistic) => {
            return {
                entity: e,
                name: e.name,
                version: e.version,
                value: formatNumber(e.value),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
