import React from 'react';
import {
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    List, ListItemButton, ListProps, DividerProps,
} from '@mui/material';
import { Button } from './input';
import { ArrowForwardIos, FilterList } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { ListItemButtonProps } from '@mui/material/ListItemButton/ListItemButton';

interface Props extends ThemeModeProps {
    readonly title: string;
    readonly menuTitles: string[];
    readonly menuElements: React.ReactNode[];
    readonly onFilter: () => void;
    readonly onClose: () => void;
}

interface State {
    readonly anchorEl: HTMLElement | undefined;
    readonly selectedIndex: number;
}

export class Filter extends React.Component<Props, State> {
    state: State = {
        anchorEl: undefined,
        selectedIndex: 0,
    };

    render() {
        const { themeMode, title, menuTitles, menuElements, onFilter } = this.props;
        const { anchorEl, selectedIndex } = this.state;
        const handleMenu = (event: any) => this.setState({ anchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ anchorEl: undefined, selectedIndex: 0 });
        const onCancelButton = () => {
            closeMenu();
            this.props.onClose();
        };
        const onFilterButton = () => {
            closeMenu();
            onFilter();
        };

        const open = !!anchorEl;

        return (
            <>
                <Button
                    icon={FilterList}
                    iconSize={18}
                    text={title}
                    variant="contained"
                    onClick={handleMenu}
                    style={{
                        fontWeight: 600,
                        fontSize: 13,
                        borderRadius: 16,
                        height: 24,
                    }}
                />
                <MainMenu
                    id={`filter`}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={open}
                    style={{ padding: 0 }}
                    onClose={onCancelButton}
                >
                    <div style={{ display: 'flex' , minHeight: 200}}>
                        <MainList themeMode={themeMode} style={{ minWidth: 150 }}>
                            {menuTitles.map((menuTitle, index) => {
                                const selected = selectedIndex === index;
                                const handleListItemClick = () => this.setState({selectedIndex: index});
                                const primary = (<div style={{ fontSize: 14 }}>{menuTitle}</div>);
                                return (
                                    <MainListItemButton
                                        themeMode={themeMode}
                                        key={`filter_list_item_${index}`}
                                        selected={selected}
                                        onClick={handleListItemClick}
                                    >
                                        <ListItemText primary={primary}/>
                                        {selected && (
                                            <MainListItemIcon>
                                                <ArrowForwardIos style={{  width: 15, height: 15 }}/>
                                            </MainListItemIcon>
                                        )}
                                    </MainListItemButton>
                                );
                            })}
                        </MainList>
                        <StyleDivider themeMode={themeMode} orientation="vertical" flexItem={true} />
                        <div style={{ minWidth: 150, padding: '14px 0', display: 'flex', flexDirection: 'column'}}>
                            {menuElements[selectedIndex]}
                        </div>
                    </div>
                    <StyleDivider themeMode={themeMode}/>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 8, gridColumnGap: 10 }}>
                        <Button text="Cancel" variant="text" onClick={onCancelButton} />
                        <Button text="Apply" variant="contained" onClick={onFilterButton} />
                    </div>
                </MainMenu>
            </>
        );
    }
}

const MainMenu = styled(Menu)(({
    '& .MuiMenu-paper': {
        marginTop: 2,
        padding: 5,
    },
    '& .MuiMenu-list': {
        padding: 0,
    },
}));

interface FilterListProps extends ListProps, ThemeModeProps {
}
const MainList = styled((props: FilterListProps) => (
    <List
        {...props}
        sx={{
            '&.MuiList-root': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                padding: '14px 0',
            },
        }}
>
{props.children}

</List>
))(() => ({}));

interface FilterListItemButtonProps extends ListItemButtonProps, ThemeModeProps {
}
const MainListItemButton = styled((props: FilterListItemButtonProps) => (
    <ListItemButton
        {...props}
        sx={{
            '&.MuiListItem-root': {
                padding: '0px 4px 0px 16px',
                height: 32,
                "&$selected": {
                    backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                    "&:disabled": {
                        backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                    },
                    "&:hover": {
                        backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                    },
                }
            },
            '&.MuiListItem-selected': {
                backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                '&:disabled': {
                    backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                },
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ?  'rgba(0, 0, 0, 0.08)' : 'rgba(174, 203, 250, 0.04)',
                },
            },
            padding: 0,
        }}
    >
        {props.children}
    </ListItemButton>
))(() => ({}));

const MainListItemIcon = styled(ListItemIcon)(({
    '&.MuiListItemIcon-root': {
        color: 'unset',
        padding: 0,
        minWidth: 50,
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

interface StyleDividerProps extends DividerProps, ThemeModeProps {
}
const StyleDivider = styled((props: StyleDividerProps) => (
    <Divider
        {...props}
        sx={{
            backgroundColor: props.themeMode === 'light' ? 'rgba(4, 30, 73, 0.2)\n' : 'rgba(211, 227, 253, 0.3)',
            borderColor: props.themeMode === 'light' ? 'rgba(4, 30, 73, 0.2)\n' : 'rgba(211, 227, 253, 0.3)',
        }}
    />
))(() => ({}));
