import * as _ from 'lodash';
import * as React from 'react';
import { AceEditor } from '../../';
import { formatJSON } from '../../../utils';
import { BaseDialog, BaseDialogProps, BaseDialogState } from './BaseDialog';

interface Props extends BaseDialogProps {
    readonly value: string;
    readonly maxWidth?: 'xs' | 'sm' | 'md';
}

interface State extends BaseDialogState {
    readonly value: string;
}

export class JsonDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.value !== prevProps.value) {
            this.setState(_.pick(this.props, 'value'));
        }
    }

    protected renderContent(): React.JSX.Element {
        const value = formatJSON(this.state.value);
        return (
            <AceEditor
                mode="json"
                readOnly={true}
                value={value}
            />
        );

    }

    protected initState(): State {
        return {
            value: '',
        };
    }
}
