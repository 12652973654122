import * as React from 'react';
import { connect } from 'react-redux';
import actions, { ActionRequest } from '../actions';
import {
    AceEditor,
    BaseElement,
    BasePlayerContainer,
    Button,
    Metadata, NumberSelect, TextSelect,
} from '../components';
import { ContainerContext, mapProps } from './index';
import {
    CircularProgress,
    Grid2 as Grid,
    InputLabel,
    Typography,
} from '@mui/material';
import { CloudDoneOutlined, CloudOutlined } from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { useParams, useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
    readonly scripts: Entities.CloudCode[];
    readonly methods?: string[];
    readonly titleData?: Entities.TitleData[];
}

interface State {
    readonly revisionSelected: number;
    readonly titleDataSelected: string;
    readonly methodSelected: string;
    readonly args: string;
    readonly running: boolean | null;
    readonly response: any | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        revisionSelected: -1,
        titleDataSelected: '',
        methodSelected: '',
        args: '{}',
        running: null,
        response: null,
    };

    async componentDidMount() {
        await actions.cloudCode.getMethods();
    }

    componentDidUpdate(prevProps: Props) {
        const { revisionSelected, methodSelected } = this.state;
        if (revisionSelected !== -1 || methodSelected !== '') {
            return;
        }

        const { scripts, methods, titleData } = this.props;
        if (!scripts || !methods) {
            return;
        }

        const scriptSelected = scripts.find(s => s.live);
        const titleDataSelected = titleData?.find(t => t.isDefault);
        this.setState({
            revisionSelected: scriptSelected ? scriptSelected.revision : -1,
            methodSelected: methods[0],
            titleDataSelected: titleDataSelected ? titleDataSelected.version : '',
        });
    }

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    private renderContent = () => {
        const { app, scripts, methods, titleData, userTitleRole } = this.props;
        const { revisionSelected, titleDataSelected, methodSelected, args, running, response } = this.state;

        if(!scripts || !methods || !titleData) {
            return <></>;
        }

        if (scripts.length === 0) {
            return (
                <Grid container={true} spacing={2} style={{textAlign: 'center'}}>
                    <Grid size={12}>
                        <CloudOutlined style={{ width: 80, height: 80}}/>
                    </Grid>
                    <Grid size={12}>
                        <Typography variant="h6">
                            Please create a cloud code version
                        </Typography>
                    </Grid>
                    <Grid size={12}>
                        <Button text="Cloud Code" variant="contained" onClick={this.goToCloudCode}/>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container={true} spacing={4}>
                <Grid size={{ xs:12, sm:4 }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        padding: 10,
                    }}>
                        <Grid container={true} spacing={0}>
                            <Grid size={12}>
                                <Typography variant="subtitle2" style={{margin: 2, fontWeight: 'bold', fontSize: 12}}>
                                    REVISION AND FUNCTION
                                </Typography>
                            </Grid>
                            <Grid size={12}>
                                <InputLabel id="revision-label" >Revision</InputLabel>
                            </Grid>
                            <Grid size={12}>
                                <NumberSelect
                                    labelId="revision-label"
                                    value={revisionSelected}
                                    onChange={this.onChangeRevision}
                                >
                                    {scripts.map(value => (
                                        <option key={value.revision} value={value.revision}>
                                            {`Revision ${value.revision} ${value.live ? '(live)' : ''}`}
                                        </option>
                                    ))}
                                </NumberSelect>
                            </Grid>
                            <Grid size={12}>
                                <InputLabel id="title-data-label" >Title Data Version</InputLabel>
                            </Grid>
                            <Grid size={12}>
                                <TextSelect
                                    labelId="title-data-label"
                                    value={titleDataSelected}
                                    onChange={this.onChangeTitleData}
                                >
                                    {titleData.map(value => (
                                        <option key={value.version} value={value.version}>
                                            {`${value.version} ${value.isDefault ? '(live)' : ''}`}
                                        </option>
                                    ))}
                                </TextSelect>
                            </Grid>
                            <Grid size={12}>
                                <InputLabel id="function-name-label" >Function Name</InputLabel>
                            </Grid>
                            <Grid size={12}>
                                <TextSelect
                                    labelId="function-name-label"
                                    value={methodSelected}
                                    onChange={this.onChangeMethod}
                                >
                                    {methods.map(method => (
                                        <option key={method} value={method}>
                                            {method}
                                        </option>
                                    ))}
                                </TextSelect>
                            </Grid>
                            <Grid size={12}>
                                <InputLabel id="function-name-label" >Arguments</InputLabel>
                            </Grid>
                            <Grid size={12}>
                                <Metadata
                                    text={args}
                                    onChange={this.onChangeArguments}
                                />
                            </Grid>
                            {userTitleRole > UserTitleRole.Viewer && (
                                <Grid size={12} style={{ display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'center', paddingTop: 20 }}>
                                    <Button text="Run" icon={CloudDoneOutlined} onClick={this.runCloudCode} variant="contained" />
                                    <Button text="Refresh" onClick={this.refresh} variant="contained" color="secondary" />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>
                <Grid size={{ xs:12, sm:8 }}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 8,
                        boxShadow: app.themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                            : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                        backgroundColor: app.themeMode === 'light' ? '#fff' : '#212121',
                        boxSizing: 'border-box',
                        border: 'none',
                        padding: 10,
                    }}>
                        <Grid container={true} spacing={2}>
                            <Grid size={12}>
                                <Typography variant="subtitle2" style={{margin: 2, fontWeight: 'bold', fontSize: 12}}>
                                    RESPONSE
                                </Typography>
                            </Grid>
                            {running !== null && (
                                <Grid size={12}>
                                    {running && (
                                        <CircularProgress style={{margin: 20}}/>
                                    )}
                                    {!running && response !== null && (
                                        <AceEditor
                                            mode="json"
                                            showGutter={true}
                                            setOptions={{
                                                showLineNumbers: false,
                                            }}
                                            readOnly={true}
                                            value={JSON.stringify(response, null, 2)}
                                            style={{border: 'thin solid rgb(223, 223, 223)', backgroundColor: 'rgb(235, 235, 235)', width: 'calc(100% - 60px)'}}
                                        />
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    };

    private goToCloudCode = () => {
        this.toLink(`/cloudCode`);
    };

    private onChangeRevision = (revisionSelected: number ) => {
        this.setState({revisionSelected});
        actions.cloudCode.getMethods(revisionSelected).then();
    };

    private onChangeTitleData = (event: any) => {
        const titleDataSelected = event.target.value;
        this.setState({titleDataSelected});
    };

    private onChangeMethod = (event: any) => {
        const methodSelected = event.target.value;
        this.setState({methodSelected});
    };

    private onChangeArguments = (args: string) => {
        this.setState({ args });
    };

    private runCloudCode = async () => {
        const { playerId } = this.props;
        const { revisionSelected, titleDataSelected, methodSelected, args } = this.state;

        this.setState({ running: true});
        const response = await ActionRequest.post(`player/${playerId}/executeCloudCode`, {
            revision: revisionSelected,
            titleDataVersion: titleDataSelected,
            method: methodSelected,
            params: args
        });
        if (!response) {
            return;
        }
        this.setState({ response, running: false});
    };

    private refresh = async () => {
        await actions.cloudCode.getMethods();
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
    scripts: state.cloudCode.scripts,
    methods: state.cloudCode.methods,
    titleData: state.cloudCode.titleData,
});
const AppContainer = (props: Props) =>
{
    const params = useParams();
    const navigate = useNavigate();
    const playerId = params.playerId || '';
    return (<Container {...props} playerId={playerId} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
