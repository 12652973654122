import * as React from 'react';
import { CommonHeader, CommonHeaderProps } from './header';
import { Loading } from './index';
import { Tabs, Tab, Grid2 as Grid, Divider } from '@mui/material';
import * as _ from 'lodash';
import { styled } from '@mui/system';
import { PropsWithChildren } from 'react';

interface TabProps {
    readonly titles: string[];
    readonly selected: number;
    readonly onChange: (tab: number) => void;
}

interface Props extends CommonHeaderProps, PropsWithChildren {
    readonly loading?: boolean;
    readonly mainTabs?: TabProps;
    readonly subTabs?: TabProps;
}

export class BaseContainer extends React.Component<Props> {
    render() {
        const { props } = this;
        const { themeMode, title, TitleIcon, headline, headlines, onRefresh, subTabs } = props;

        const loading = props.loading;
        const buttons = loading ? undefined : props.buttons;
        const children = loading ? undefined : props.children;

        return (
            <Grid container={true} justifyContent="center" spacing={0}>
                <Grid size={12}>
                    {this.renderMainTabs()}
                </Grid>
                <Grid size={12}>
                    <Grid container={true} justifyContent="center" spacing={0}>
                        <Grid size={12} style={{ flexGrow: 0, flexShrink: 0, padding: 5 }}>
                            <CommonHeader
                                themeMode={themeMode}
                                title={title}
                                TitleIcon={TitleIcon}
                                headline={headline}
                                headlines={headlines}
                                onRefresh={onRefresh}
                                buttons={buttons}
                            />
                        </Grid>
                        {subTabs !== undefined && (
                            <Grid size={12} style={{ margin: '8px 0px' }}>
                                {this.renderSubTabs()}
                            </Grid>
                        )}
                        {(title !== undefined || headline !== undefined || headlines !== undefined || buttons !== undefined) && subTabs === undefined && (
                            <Grid size={12} style={{ margin: '8px 0px' }}>
                                <Divider />
                            </Grid>
                        )}
                        {loading && (
                            <div style={{ marginTop: 50 }}>
                                <Loading themeMode={themeMode}/>
                            </div>
                        )}
                        {!loading && (
                            <Grid size={12} style={{ padding: '20px 0px 30px 0px' }}>
                                {children}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderMainTabs = () => {
        const { mainTabs } = this.props;
        if(!mainTabs) {
            return <></>;
        }
        return (
            <MainTabs
                value={mainTabs.selected}
                onChange={this.changeMainTab}
                indicatorColor="primary"
                textColor="primary"
            >
                {_.map(mainTabs.titles, (name, index) => (
                    <MainTab
                        key={`tab_${index}`}
                        disableRipple={true}
                        label={name}
                        value={index}
                    />
                ))}
            </MainTabs>
        );
    };

    private changeMainTab = (event: any, value: any) => {
        if (this.props.mainTabs) {
            this.props.mainTabs.onChange(value);
        }
    };

    private renderSubTabs = () => {
        const { themeMode, subTabs } = this.props;
        if(!subTabs) {
            return;
        }
        return (
            <Tabs
                value={subTabs.selected}
                onChange={this.changeSubTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                allowScrollButtonsMobile={true}
                scrollButtons="auto"
                style={{
                    borderTop: themeMode === 'light' ? '1px solid #e5e7e9' : '1px solid #26272b',
                    borderBottom: themeMode === 'light' ? '1px solid #e5e7e9' : '1px solid #26272b',
                }}
            >
                {_.map(subTabs.titles, (name, index) => (
                    <Tab
                        key={`tab_${index}`}
                        disableRipple={true}
                        label={name}
                        value={index}
                    />
                ))}
            </Tabs>
        );
    };

    private changeSubTab = (event: any, value: any) => {
        if (this.props.subTabs) {
            this.props.subTabs.onChange(value);
        }
    };
}

const MainTabs = styled(Tabs)(({
    '&.MuiTabs-root': {
        backgroundColor: '#dfdfdf',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
    },
    '& .MuiTabs-indicator': {
        display: 'contents',
        backgroundColor: 'white',
    },
}));

const MainTab = styled(Tab)(({
    '&.MuiTab-root': {
        fontSize: '0.8rem',
        color: '#0009',
        minWidth: 0,
        margin: 0,
        padding: '0px 25px',
        textTransform: 'none',
        '&:hover': {
            color: '#0073bb',
        },
        '&$selected': {
            color: '#0073bb',
        },
    },
    '& .Mui-selected': {
        backgroundColor: 'white',
    },
}));
