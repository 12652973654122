import React, { PureComponent } from 'react';
import AceEditorBase, { IAceEditorProps } from 'react-ace';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { IconButton } from '@mui/material';
import { Ace } from 'ace-builds';
import { getThemeMode } from '../../reducers';
import { IAceOptions } from 'react-ace/src/types';

export interface AceEditorProps {
    readonly showGoTo?: boolean;
    readonly mode?: string | object;
    readonly value?: string;
    readonly height?: string;
    readonly width?: string;
    readonly readOnly?: boolean;
    readonly style?: React.CSSProperties;
    readonly showGutter?: boolean;
    readonly setOptions?: IAceOptions;
    readonly showPrintMargin?: boolean;
    readonly highlightActiveLine?: boolean;
    readonly fontSize?: number | string;
    readonly lineHeight?: number | string;
    readonly onChange?: (text: string) => void;
}

interface State {
    readonly editor?: Ace.Editor;
}

import('ace-builds/src-noconflict/mode-json');
import('ace-builds/src-noconflict/mode-javascript');
import('ace-builds/src-noconflict/mode-sh');
import('ace-builds/src-noconflict/theme-github');
import('ace-builds/src-noconflict/theme-monokai');
import('ace-builds/src-noconflict/theme-terminal');
import('ace-builds/src-noconflict/theme-twilight');
import('ace-builds/src-noconflict/theme-terminal');
import('ace-builds/src-noconflict/ext-searchbox');

export class AceEditor extends PureComponent<AceEditorProps, State> {
    state: State = {};

    render() {
        const { showGoTo } = this.props;
        const onAceEditorLoad = (editor: any) => this.setState({ editor });
        const themeMode = getThemeMode();
        return (
            <>
                <AceEditorBase
                    theme={themeMode === 'light' ? 'tomorrow' : 'monokai'}
                    showPrintMargin={false}
                    style={style}
                    {...this.props}
                    onLoad={onAceEditorLoad}
                />
                {showGoTo && (
                    <>
                        <StyledIconButton onClick={this.onGoToTop} style={{ bottom: '56px', right: '8px'}}>
                            <ExpandLess />
                        </StyledIconButton>
                        <StyledIconButton onClick={this.onGoToBottom} style={{ bottom: '8px', right: '8px'}}>
                            <ExpandMore />
                        </StyledIconButton>
                    </>
                )}
            </>
        );
    }

    private onGoToTop = () => {
        const { editor } = this.state;
        if (!editor) {
            return;
        }
        editor.scrollToRow(0);
    }

    private onGoToBottom = () => {
        const { editor } = this.state;
        if (!editor) {
            return;
        }

        const n = editor.getValue().split("\n").length - 2;
        editor.scrollToRow(n);
        editor.navigateLineEnd();
    }
}

const style = {
    width: '100%',
};

const StyledIconButton = styled(IconButton)(({
    position: 'fixed',
    padding: 10,
    backgroundColor: '#2196F3',
    color: '#fff',
    textAlign: 'center',
    zIndex: 10,
    '&:hover': {
        backgroundColor: '#1883EF',
    },
}));
