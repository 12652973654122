import * as _ from 'lodash';
import React from 'react';
import { Loading, Table } from '../common';
import { ActionRequest } from '../../actions';
import { Divider, Grid2 as Grid, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { formatBytes } from '../../utils';
import { styled } from '@mui/styles';
import {
    AdjustOutlined,
    AllOutOutlined,
    AudiotrackOutlined,
    CodeOutlined,
    ExtensionOutlined,
    FontDownloadOutlined,
    ImageOutlined, Inventory2Outlined, TextSnippetOutlined, TokenOutlined,
} from '@mui/icons-material';

interface Props extends ThemeModeProps {
    readonly cloudBuildId: string;
    readonly buildNumber: string;
    readonly reportArtifact: Entities.CloudBuildJobArtifact;
}

interface CloudBuildReportData {
    buildName: string;
    sceneNames: string[];
    scriptingDefineSymbols: string[];
    summary: any;
    files: any[];
    steps: any[];
    packedAssets: any[];
    assetBundleReport: AssetBundleBuildReport;
    addressablesReport: AddressablesBuildReport;
    gitBranch: string;
    gitBranchShort: string;
    gitCommit: string;
    gitCommitShort: string;
}

interface AssetBundleBuildReport {
    totalSize: number;
    ignoreFolders: string[];
    ignoreFiles: string[];
    bundles: BundleDetails[];
}

interface BundleDetails {
    fileName: string;
    hash: string;
    size: number;
    dependencies: string[];
    assetDependencies: BundleDetailsAssetDependency[];
}

interface BundleDetailsAssetDependency {
    path: string;
    hash: string;
    size: number;
    type: string;
    bundle: string;
}

interface AddressablesBuildReport {
    duration: number;
    locationCount: number;
    error: string;
    outputPath: string;
    totalSize: number;
    files: AddressablesDetails[];
    groups: AddressablesGroup[];
}

interface AddressablesDetails {
    fileName: string;
    size: number;
}

interface AddressablesGroup {
    name: string;
    entries: AddressableAssetEntry[];
}

interface AddressableAssetEntry {
    assetPath: string;
    isFolder: boolean;
    isScene: boolean;
    isInResources: boolean;
    size: number;
    type: string;
    subAssets: AddressableAssetEntry[];
    assetDependencies: AddressableAssetDependency[];
}

interface AddressableAssetDependency {
    path: string;
    hash: string;
    size: number;
    type: string;
}

interface State {
    readonly loading: boolean;
    readonly tagIndex: number;
    readonly data?: any;
}

enum BuildTarget {
    NoTarget = -2,
    StandaloneOSX = 2,
    StandaloneWindows = 5,
    iOS = 9,
    Android = 13,
    StandaloneWindows64 = 19,
    WebGL = 20,
    WSAPlayer = 21,
    StandaloneLinux64 = 24,
    PS4 = 31,
    XboxOne = 33,
    tvOS = 37,
    Switch = 38,
    Lumin = 39,
    Stadia = 40,
    LinuxHeadlessSimulation = 41,
    GameCoreXboxSeries = 42,
    GameCoreXboxOne = 43,
    PS5 = 44,
    EmbeddedLinux = 45,
}

enum BuildTargetGroup {
    Unknown = 0,
    Standalone = 1,
    iOS = 4,
    Android = 7,
    WebGL = 13,
    WSA = 14,
    PS4 = 19,
    XboxOne = 21,
    tvOS = 25,
    Switch = 27,
    Lumin = 28,
    Stadia = 29,
    LinuxHeadlessSimulation = 30,
    GameCoreXboxSeries = 31,
    GameCoreXboxOne = 32,
    PS5 = 33,
    EmbeddedLinux = 34,
}

enum BuildResult {
    Unknown,
    Succeeded,
    Failed,
    Cancelled,
}

export class CloudBuildReport extends React.Component<Props, State> {
    state: State = {
        loading: true,
        tagIndex: 0,
    };

    async componentDidMount() {
        const { cloudBuildId, buildNumber, reportArtifact } = this.props;
        const url = `devOps/cloudBuild/${cloudBuildId}/${buildNumber}/getReport`;
        const params = { relativePath: reportArtifact.file.relativePath };
        const data = await ActionRequest.get(url, params);
        this.setState({
            loading: false,
            data,
        });
    }

    render() {
        const { themeMode, reportArtifact } = this.props;
        const { loading, data, tagIndex } = this.state;
        if (loading) {
            return (
                <div style={{ padding: 20 }}>
                    <Loading themeMode={themeMode}/>
                </div>
            );
        }

        if (!data) {
            return <></>;
        }

        if (reportArtifact.name.endsWith("-Assets-Bundles.json")) {
            return (
                <div style={{ margin: 10 }}>
                    {this.renderAssetBundle(data)}
                </div>
            );
        }

        if (reportArtifact.name.endsWith("-Addressables.json")) {
            return (
                <div style={{ margin: 10 }}>
                    {this.renderAddressables(data)}
                </div>
            );
        }

        return (
            <Grid container={true} spacing={1}>
                <Grid
                    size={{ xs:4, sm:3, md:2 }}
                    style={{
                        margin: 0,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 0,
                        borderRight: themeMode === 'light' ? '1px solid #e5e7e9' : '1px solid #26272b',
                    }}
                >
                    <TabsWithStyle
                        themeMode={themeMode}
                        orientation="vertical"
                        variant="scrollable"
                        value={tagIndex}
                        onChange={this.handleChange}
                    >
                        <TabWithStyle themeMode={themeMode} label="Summary" />
                        <TabWithStyle themeMode={themeMode} label="Files" />
                        <TabWithStyle themeMode={themeMode} label="Steps" />
                        <TabWithStyle themeMode={themeMode} label="Packed Assets" />
                        <TabWithStyle themeMode={themeMode} label="Asset Bundle" />
                        <TabWithStyle themeMode={themeMode} label="Addressables" />
                    </TabsWithStyle>
                </Grid>
                <Grid size={{ xs:8, sm:9, md:10 }} style={{ padding: '8px 0px 0px 0px' }}>
                    <TabPanel value={tagIndex} index={0}>
                        {this.renderSummary(data)}
                    </TabPanel>
                    <TabPanel value={tagIndex} index={1}>
                        {this.renderFiles(data)}
                    </TabPanel>
                    <TabPanel value={tagIndex} index={2}>
                        {this.renderSteps(data)}
                    </TabPanel>
                    <TabPanel value={tagIndex} index={3}>
                        {this.renderPackedAssets(data)}
                    </TabPanel>
                    <TabPanel value={tagIndex} index={4}>
                        {this.renderAssetBundle(data.assetBundleReport)}
                    </TabPanel>
                    <TabPanel value={tagIndex} index={5}>
                        {this.renderAddressables(data.addressablesReport)}
                    </TabPanel>
                </Grid>
            </Grid>
        );
    }

    private renderSummary(data: CloudBuildReportData) {
        if (!data) {
            return <></>;
        }

        const { themeMode } = this.props;
        const sceneNamesViews: React.JSX.Element[] = [];
        if (data.sceneNames) {
            data.sceneNames.forEach(sceneName => {
                sceneNamesViews.push(
                    <Grid size={12}>
                        <PSubtitle themeMode={themeMode}>{sceneName}</PSubtitle>
                    </Grid>
                );
            });
        }

        const scriptingDefineSymbolsViews: React.JSX.Element[] = [];
        if (data.scriptingDefineSymbols) {
            data.scriptingDefineSymbols.forEach( scriptingDefineSymbol => {
                scriptingDefineSymbolsViews.push(
                    <Grid size={12}>
                        <PSubtitle themeMode={themeMode}>{scriptingDefineSymbol}</PSubtitle>
                    </Grid>
                );
            });
        }

        return (
            <Grid container={true} spacing={2} style={{ padding: 20 }}>
                <Grid size={{ xs:4, sm:3, md:2 }}>
                    <PTitle themeMode={themeMode}>Build Name:</PTitle>
                </Grid>
                <Grid size={{ xs:8, sm:9, md:10 }}>
                    <PSubtitle themeMode={themeMode}>{data.buildName}</PSubtitle>
                </Grid>
                {sceneNamesViews.length > 0 && (
                    <>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Scene Names:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <Grid container={true} spacing={1}>
                                {sceneNamesViews}
                            </Grid>
                        </Grid>
                    </>
                )}
                {scriptingDefineSymbolsViews.length > 0 && (
                    <>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Scripting Define Symbols:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <Grid container={true} spacing={1}>
                                {scriptingDefineSymbolsViews}
                            </Grid>
                        </Grid>
                    </>
                )}
                {data.summary && (
                    <>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Platform:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{BuildTarget[data.summary.platform]}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Platform Group:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{BuildTargetGroup[data.summary.platformGroup]}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Total Size:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{formatBytes(data.summary.totalSize)}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Total Errors:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{data.summary.totalErrors}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Total Warnings:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{data.summary.totalWarnings}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Result:</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{BuildResult[data.summary.result]}</PSubtitle>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private renderSteps(data: CloudBuildReportData) {
        if (!data) {
            return <></>;
        }
        if (!data.steps) {
            return <></>;
        }
        if (data.steps.length === 0) {
            return <></>;
        }

        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Duration', field: 'duration', render: (rowData: any) => `${rowData.duration/1000}s`},
        ];

        return (
            <Table
                data={data.steps}
                columns={columns}
                showBox={false}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: true,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                }}
            />
        );
    }

    private renderFiles(data: CloudBuildReportData) {
        if (!data) {
            return <></>;
        }
        if (!data.files) {
            return <></>;
        }
        if (data.files.length === 0) {
            return <></>;
        }

        const columns = [
            { title: 'Path', field: 'path'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size), searchable: false},
        ];

        const files = _.map(
            _.orderBy(data.files, ['size'], ['desc']),
            file => {
                return {
                    path: file.path,
                    size: file.size,
                };
            }
        );

        return (
            <Table
                data={files}
                columns={columns}
                showBox={false}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: true,
                    draggable: false,
                    emptyRowsWhenPaging: false,
                }}
            />
        );
    }

    private renderPackedAssets(data: CloudBuildReportData) {
        if (!data) {
            return <></>;
        }
        if (!data.packedAssets) {
            return <></>;
        }
        if (data.packedAssets.length === 0) {
            return <></>;
        }

        const { themeMode } = this.props;
        const assetTypes: Record<string, number> = { };
        data.packedAssets.forEach(packedAsset => {
            const type = this.getAssetType(packedAsset.type, packedAsset.path);
            if (!assetTypes.hasOwnProperty(type))
            {
                assetTypes[type] = 0;
            }
            assetTypes[type] += packedAsset.size;
        });

        const assetTypesViews: React.JSX.Element[] = [];
        Object.keys(assetTypes).forEach(key => {
            const icon = this.getAssetIcon(key);
            assetTypesViews.push(
                <>
                    <Grid size={{ xs:4, sm:3, md:2 }}
                          style={{
                              margin: 'auto 0px',
                              display: 'grid',
                              justifyContent: 'flex-start',
                              gridAutoFlow: 'column',
                              gridColumnGap: 5,
                          }}
                    >
                        {icon}
                        <PTitle themeMode={themeMode}>{key}</PTitle>
                    </Grid>
                    <Grid size={{ xs:8, sm:9, md:10 }} style={{ margin: 'auto' }}>
                        <PSubtitle themeMode={themeMode}>{formatBytes(assetTypes[key])}</PSubtitle>
                    </Grid>
                </>
            );
        });

        const columns = [
            { title: 'Path', field: 'data', searchable: false},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size), searchable: false},
            { title: 'path', field: 'path', searchable: true, hidden: true}
        ];

        const packedAssets = _.orderBy(data.packedAssets, ['size'], ['desc'])
            .map(packedAsset => ({
                ...packedAsset,
                data: <div>
                    <PTitle themeMode={themeMode} style={{
                        margin: 'auto 0px',
                        display: 'grid',
                        justifyContent: 'flex-start',
                        gridAutoFlow: 'column',
                        gridColumnGap: 5,
                    }}>
                        {this.getAssetIcon(this.getAssetType(packedAsset.type, packedAsset.path))}
                        {packedAsset.path}
                    </PTitle>
                </div>
            }));

        return (
            <Grid container={true} spacing={1} style={{ padding: '10px 20px' }}>
                <Grid size={12}>
                    <Grid container={true} spacing={1}>
                        {assetTypesViews}
                    </Grid>
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <Table
                        data={packedAssets}
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 30, 50],
                            search: true,
                            sorting: true,
                            draggable: false,
                            emptyRowsWhenPaging: false,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    private renderAssetBundle(assetBundleReport: AssetBundleBuildReport) {
        if (!assetBundleReport) {
            return <></>;
        }

        const { themeMode } = this.props;
        const ignoreFoldersViews: React.JSX.Element[] = [];
        if (assetBundleReport.ignoreFolders) {
            assetBundleReport.ignoreFolders.forEach( ignoreFolder => {
                ignoreFoldersViews.push(
                    <Grid size={12}>
                        <PSubtitle themeMode={themeMode}>{ignoreFolder}</PSubtitle>
                    </Grid>
                );
            });
        }

        const ignoreFilesViews: React.JSX.Element[] = [];
        if (assetBundleReport.ignoreFiles) {
            assetBundleReport.ignoreFiles.forEach(ignoreFile => {
                ignoreFilesViews.push(
                    <Grid size={12}>
                        <PSubtitle themeMode={themeMode}>{ignoreFile}</PSubtitle>
                    </Grid>
                );
            });
        }

        const renderDependencies = (dependencies: string[]) => {
            return (
                <Grid container={true} spacing={1}>
                    {dependencies.map(dependency => {
                        return ( <Grid  key={dependency} size={{ xs:12 }}>{dependency}</Grid>);
                    })}
                </Grid>
            );
        };

        const columns = [
            { title: 'File Name', field: 'fileName'},
            { title: 'Hash', field: 'hash'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
            { title: 'Dependencies', field: 'dependencies', render: (rowData: any) => renderDependencies(rowData.dependencies)},
        ];

        const bundles = _.orderBy(assetBundleReport.bundles, ['size'], ['desc']);

        return (
            <Grid container={true} spacing={2} style={{ padding: '10px 20px' }}>
                <Grid size={12}>
                    <Grid container={true} spacing={1}>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Total Size</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{formatBytes(assetBundleReport.totalSize)}</PSubtitle>
                        </Grid>
                        {ignoreFoldersViews.length > 0 && (
                            <>
                                <Grid size={{ xs:4, sm:3, md:2 }}>
                                    <PTitle themeMode={themeMode}>Ignore Folders</PTitle>
                                </Grid>
                                <Grid size={{ xs:8, sm:9, md:10 }}>
                                    <Grid container={true} spacing={1}>
                                        {ignoreFoldersViews}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {ignoreFilesViews.length > 0 && (
                            <>
                                <Grid size={{ xs:4, sm:3, md:2 }}>
                                    <PTitle themeMode={themeMode}>Ignore Files</PTitle>
                                </Grid>
                                <Grid size={{ xs:8, sm:9, md:10 }}>
                                    <Grid container={true} spacing={1}>
                                        {ignoreFilesViews}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                {assetBundleReport.bundles.length > 0 && (
                    <>
                        <Grid size={{ xs:12 }}/>
                        <Grid size={{ xs:12 }}/>
                        <Grid size={12}>
                            <Table
                                data={bundles}
                                columns={columns}
                                showBox={false}
                                options={{
                                    showTitle: false,
                                    selection: false,
                                    paging: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 30, 50],
                                    search: true,
                                    sorting: true,
                                    draggable: false,
                                    emptyRowsWhenPaging: false,
                                }}
                                detailPanel={this.getAssetBundlePanel}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private getAssetBundlePanel = (bundleDetails: BundleDetails) => {
        const columns = [
            { title: 'Data', field: 'data'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
        ];

        const { themeMode } = this.props;
        const data = _.orderBy(bundleDetails.assetDependencies, ['bundle', 'size'], ['desc', 'desc'])
            .map(assetDependency => ({
                ...assetDependency,
                data: <div>
                    <PTitle themeMode={themeMode} style={{
                        margin: 'auto 0px',
                        display: 'grid',
                        justifyContent: 'flex-start',
                        gridAutoFlow: 'column',
                        gridColumnGap: 5,
                    }}>
                        {this.getAssetIcon(this.getAssetType(assetDependency.type, assetDependency.path))}
                        {assetDependency.path}
                    </PTitle>
                    <PSubtitle themeMode={themeMode}>{assetDependency.hash}</PSubtitle>
                    {assetDependency.bundle === 'auto' &&
                        <PSubtitle themeMode={themeMode}>{'auto included'}</PSubtitle>
                    }
                </div>
            }));

        const assetTypes: Record<string, number> = { };
        bundleDetails.assetDependencies.forEach(assetDependency => {
            const type = this.getAssetType(assetDependency.type, assetDependency.path);
            if (!assetTypes.hasOwnProperty(type))
            {
                assetTypes[type] = 0;
            }
            assetTypes[type] += assetDependency.size;
        });

        const assetTypesViews: React.JSX.Element[] = [];
        Object.keys(assetTypes).forEach(key => {
            if (assetTypes[key] === 0) {
                return;
            }
            const icon = this.getAssetIcon(key);
            assetTypesViews.push(
                <>
                    <Grid size={{ xs:4, sm:3, md:2 }}
                          style={{
                              margin: 'auto 0px',
                              display: 'grid',
                              justifyContent: 'flex-start',
                              gridAutoFlow: 'column',
                              gridColumnGap: 5,
                        }}
                    >
                        {icon}
                        <PTitle themeMode={themeMode}>{key}</PTitle>
                    </Grid>
                    <Grid size={{ xs:8, sm:9, md:10 }} >
                        <PSubtitle themeMode={themeMode}>{formatBytes(assetTypes[key])}</PSubtitle>
                    </Grid>
                </>
            );
        });

        return (
            <Grid container={true} spacing={1} style={{ padding: '10px 20px' }}>
                {assetTypesViews}
                <Grid size={{ xs:12 }} />
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <Table
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 20, 50],
                            emptyRowsWhenPaging: false,
                            search: false,
                            sorting: true,
                            draggable: false,
                        }}
                        data={data}
                    />
                </Grid>
            </Grid>
        );
    };

    private renderAddressables(addressablesReport: AddressablesBuildReport) {
        if (!addressablesReport) {
            return <></>;
        }

        const { themeMode } = this.props;
        const columns = [
            { title: 'File Name', field: 'fileName'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
        ];

        const addressables = _.orderBy(addressablesReport.files, ['size'], ['desc']);

        const groupColumns = [
            { title: 'Name', field: 'name'},
            { title: 'Entries', field: 'entriesLength'},
        ];

        const groups = addressablesReport.groups.map(e => ({
            ...e,
            entriesLength: e.entries.length,
        }));

        return (
            <Grid container={true} spacing={1} style={{ padding: '10px 20px' }}>
                <Grid size={{ xs:12 }} >
                    <Grid container={true} spacing={1}>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Duration</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{`${addressablesReport.duration}s`}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Location Count</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{addressablesReport.locationCount}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }} >
                            <PTitle themeMode={themeMode}>Error</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{addressablesReport.error}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Output Path</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{addressablesReport.outputPath}</PSubtitle>
                        </Grid>
                        <Grid size={{ xs:4, sm:3, md:2 }}>
                            <PTitle themeMode={themeMode}>Total Size</PTitle>
                        </Grid>
                        <Grid size={{ xs:8, sm:9, md:10 }}>
                            <PSubtitle themeMode={themeMode}>{formatBytes(addressablesReport.totalSize)}</PSubtitle>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid size={{ xs:12 }} />
                <Grid size={{ xs:12 }} />
                <Grid size={12}>
                    <Table
                        title={'Files'}
                        data={addressables}
                        columns={columns}
                        showBox={false}
                        options={{
                            showTitle: false,
                            selection: false,
                            paging: true,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 30, 50],
                            search: true,
                            sorting: true,
                            draggable: false,
                            emptyRowsWhenPaging: false,
                        }}
                    />
                </Grid>
                {groups && (
                    <>
                        <Grid size={{ xs:12 }} />
                        <Grid size={{ xs:12 }} />
                        <Grid size={12}>
                            <Table
                                title={'Groups'}
                                data={groups}
                                columns={groupColumns}
                                showBox={false}
                                options={{
                                    showTitle: false,
                                    selection: false,
                                    paging: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 30, 50],
                                    search: true,
                                    sorting: true,
                                    draggable: false,
                                    emptyRowsWhenPaging: false,
                                }}
                                detailPanel={this.getGroupsEntriesPanel}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        );
    }

    private getGroupsEntriesPanel = (group: AddressablesGroup) => {
        const columns = [
            { title: 'Data', field: 'data'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
        ];

        const { themeMode } = this.props;
        const data = _.orderBy(group.entries, ['size'], ['desc'])
            .map(entry => ({
                ...entry,
                data: <div>
                    <PTitle themeMode={themeMode} style={{
                        margin: 'auto 0px',
                        display: 'grid',
                        justifyContent: 'flex-start',
                        gridAutoFlow: 'column',
                        gridColumnGap: 5,
                    }}>
                        {this.getAssetIcon(this.getAssetType(entry.type, entry.assetPath))}
                        {entry.assetPath}
                    </PTitle>
                    {entry.isFolder &&
                        <PSubtitle themeMode={themeMode}>{'is folder'}</PSubtitle>
                    }
                    {entry.isScene &&
                        <PSubtitle themeMode={themeMode}>{'is scene'}</PSubtitle>
                    }
                    {entry.isInResources &&
                        <PSubtitle themeMode={themeMode}>{'is in resources'}</PSubtitle>
                    }
                </div>
            }));

        return (
            <div style={{ padding: 10 }}>
                <Table
                    columns={columns}
                    showBox={false}
                    options={{
                        showTitle: false,
                        selection: false,
                        paging: false,
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: true,
                        draggable: false,
                    }}
                    data={data}
                    detailPanel={this.getGroupEntryAssetDependenciesPanel}
                />
            </div>
        );
    };

    private getGroupEntryAssetDependenciesPanel = (addressableAssetEntry: AddressableAssetEntry) => {
        const columns = [
            { title: 'Data', field: 'data'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
        ];

        const { themeMode } = this.props;
        const data = _.orderBy(addressableAssetEntry.assetDependencies, ['bundle', 'size'], ['desc', 'desc'])
            .map(assetDependency => ({
                ...assetDependency,
                data: <div>
                    <PTitle themeMode={themeMode} style={{
                        margin: 'auto 0px',
                        display: 'grid',
                        justifyContent: 'flex-start',
                        gridAutoFlow: 'column',
                        gridColumnGap: 5,
                    }}>
                        {this.getAssetIcon(this.getAssetType(assetDependency.type, assetDependency.path))}
                        {assetDependency.path}
                    </PTitle>
                    <PSubtitle themeMode={themeMode}>{assetDependency.hash}</PSubtitle>
                </div>
            }));

        const assetTypes: Record<string, number> = { };
        addressableAssetEntry.assetDependencies.forEach(assetDependency => {
            const type = this.getAssetType(assetDependency.type, assetDependency.path);
            if (!assetTypes.hasOwnProperty(type))
            {
                assetTypes[type] = 0;
            }
            assetTypes[type] += assetDependency.size;
        });

        const assetTypesViews: React.JSX.Element[] = [];
        Object.keys(assetTypes).forEach(key => {
            if (assetTypes[key] === 0) {
                return;
            }
            const icon = this.getAssetIcon(key);
            assetTypesViews.push(
                <>
                    <Grid size={{ xs:4, sm:3, md:2 }}
                          style={{
                              margin: 'auto 0px',
                              display: 'grid',
                              justifyContent: 'flex-start',
                              gridAutoFlow: 'column',
                              gridColumnGap: 5,
                          }}
                    >
                        {icon}
                        <PTitle themeMode={themeMode}>{key}</PTitle>
                    </Grid>
                    <Grid size={{ xs:8, sm:9, md:10 }} >
                        <PSubtitle themeMode={themeMode}>{formatBytes(assetTypes[key])}</PSubtitle>
                    </Grid>
                </>
            );
        });

        return (
            <div style={{ width: 'calc(100% - 40px)', padding: 20, backgroundColor: 'rgb(245, 245, 245)' }}>
                <Grid container={true} spacing={1}>
                    {assetTypesViews}
                    <Grid size={12}>
                        <Table
                            columns={columns}
                            showBox={false}
                            options={{
                                showTitle: false,
                                selection: false,
                                paging: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 20, 50],
                                emptyRowsWhenPaging: false,
                                search: false,
                                sorting: true,
                                draggable: false,
                                headerStyle: {
                                    backgroundColor: 'transparent',
                                }
                            }}
                            data={data}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }

    private handleChange = (event: any, newValue: any) => this.setState({tagIndex: newValue});

    private getAssetType(type: string, path: string) {
        if (!path) {
            return '';
        }
        if (path.includes('Built-in Texture2D')) {
            type = 'Texture';
        }
        return type;
    }

    private getAssetIcon(type: string) {
        let icon = <></>;
        const style = { width: 15, height: 15, margin: 'auto' };
        switch (type) {
            case "Unknown":
                break;
            case "Asset":
                icon = <Inventory2Outlined style={style}/>;
                break;
            case "Texture":
                icon = <ImageOutlined style={style}/>;
                break;
            case "Prefab":
                icon = <TokenOutlined style={style}/>;
                break;
            case "TextScript":
                icon = <TextSnippetOutlined style={style}/>;
                break;
            case "Shader":
                icon = <AllOutOutlined style={style}/>;
                break;
            case "TrueTypeFont":
                icon = <FontDownloadOutlined style={style}/>;
                break;
            case "Mono":
                icon = <CodeOutlined style={style}/>;
                break;
            case "ShaderGraph":
                icon = <AdjustOutlined style={style}/>;
                break;
            case "Plugin":
                icon = <ExtensionOutlined style={style}/>;
                break;
            case "Audio":
                icon = <AudiotrackOutlined style={style}/>;
                break;
        }
        return icon;
    }
}

interface TabsWithStyleProps extends TabsProps, ThemeModeProps {}
const TabsWithStyle = styled((props: TabsWithStyleProps) => (
    <Tabs
        {...props}
        sx={{
            '&.MuiTabs-root': {
                marginLeft: '0px',
                borderBottom: 'none',
            },
            '&.MuiTabs-indicator': {
                backgroundColor: 'transparent',
            }
        }}
    >
        {props.children}
    </Tabs>
))(() => ({}));

interface TabWithStyleProps extends TabProps, ThemeModeProps {}
const TabWithStyle = styled((props: TabWithStyleProps) => (
    <Tab
        {...props}
        sx={{
            '&.MuiTab-root': {
                padding: '6px 20px',
                fontSize: '0.8rem',
                color: props.themeMode === 'light' ? '#404040' : '#adadad',
                fontWeight: 500,
                minWidth: 0,
                margin: 0,
                textTransform: 'none',
                minHeight: 40,
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#f2f2f2' : '#313131',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#f2f2f2' : '#313131',
                },
            },
            '&.Mui-selected': {
                fontWeight: 'bold',
                color: props.themeMode === 'light' ? '#175dd1' : '#8ab4f8',
                backgroundColor: props.themeMode === 'light' ? '#d3e3fd' : '#1c325c',
                borderBottom: 'none',
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#d3e3fd' : '#1c325c',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#d3e3fd' : '#1c325c',
                },
            },
        }}
    >
        {props.children}
    </Tab>
))(() => ({}));

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const hidden = value !== index;
    if (hidden) {
        return (<></>);
    }

    return (
        <div
            role="cloud-build-report"
            id={`full-width-cloud-build-report-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

interface StyledProps extends ThemeModeProps {
    readonly children?: React.ReactNode;
    readonly style?: React.CSSProperties;
}
const PTitle = styled((props: StyledProps) => (
    <div
        style={{
            color: props.themeMode === 'light' ? '#404040' : '#adadad',
            fontWeight: 600,
            fontSize: '0.75rem',
            lineHeight: '1.25rem',
            letterSpacing: '-0.006rem',
            padding: 0,
            margin: 0,
            ...props.style,
        }}
    >
        {props.children}
    </div>
))(() => ({}));

const PSubtitle = styled((props: StyledProps) => (
    <div
        style={{
            color: props.themeMode === 'light' ? '#404040' : '#adadad',
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: '1.25rem',
            letterSpacing: '-0.006rem',
            padding: 0,
            margin: 0,
        }}
    >
        {props.children}
    </div>
))(() => ({}));
