import React from 'react';
import {
    MobileTimePicker,
    DesktopTimePicker,
    PickerChangeHandlerContext,
    DateTimeValidationError,
} from '@mui/x-date-pickers';
import { Hidden } from '@mui/material';
import { Moment } from 'moment';

interface Props {
    readonly value: Moment;
    readonly onChange: (from: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void;
    readonly disablePast?: boolean;
    readonly ampm?: boolean;
}

export class TimePicker extends React.Component<Props> {
    render() {
        const { value, onChange, disablePast, ampm } = this.props;
        return (
            <>
                <Hidden smUp={true}>
                    <MobileTimePicker value={value} onChange={onChange} disablePast={disablePast} ampm={ampm} />
                </Hidden>
                <Hidden smDown={true}>
                    <DesktopTimePicker value={value} onChange={onChange} disablePast={disablePast} ampm={ampm} />
                </Hidden>
            </>
        );
    }
}