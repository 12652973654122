import * as React from 'react';
import { BaseContainer, CommonHeaderProps } from '../common';
import { ContainerContext } from '../../containers';
import { toLink } from '../../utils';
import { GroupOutlined as GroupIcon } from '@mui/icons-material';
import { PropsWithChildren } from 'react';

interface Props extends ContainerContext.Props, CommonHeaderProps, PropsWithChildren {
    readonly playerId?: string;
    readonly showSubTabs?: boolean;
}

export class BasePlayerContainer extends React.Component<Props> {
    render() {
        const { loading, themeMode, title, headline, children, buttons, showSubTabs, playerId } = this.props;
        let { headlines } = this.props;
        const subTabsTitles = ['Overview', 'Cloud Code', 'Player Data', 'Compensations', 'Inventory', 'Wallet', 'Statistics', 'Live Events', 'Seasons', 'Match Making', 'Achievements', 'Purchases'];

        if( playerId ) {
            headlines = [
                {
                    text: 'Players',
                    to: `/players/all`,
                },
                {
                    text: playerId,
                },
            ];
        }

        if(showSubTabs) {
            return (
                <BaseContainer
                    loading= {loading}
                    themeMode={themeMode}
                    title = {'Players'}
                    TitleIcon={GroupIcon}
                    headline = {headline}
                    headlines = {headlines}
                    subTabs={{
                        titles: subTabsTitles,
                        selected: this.subTabsSelected(),
                        onChange: this.changeSubTabs,
                    }}
                    buttons={buttons}
                >
                    {children}
                </BaseContainer>
            );
        }

        return (
            <BaseContainer
                loading= {loading}
                themeMode={themeMode}
                title = {title}
                TitleIcon={GroupIcon}
                headline = {headline}
                headlines = {headlines}
                buttons={buttons}
            >
                {children}
            </BaseContainer>
        );
    }

    private subTabsSelected = () => {
        if (location.pathname.includes(this.getPath('overview'))) {
            return  0;
        } else if (location.pathname.includes(this.getPath('cloudCode'))) {
            return  1;
        } else if (location.pathname.includes(this.getPath('data'))) {
            return  2;
        } else if (location.pathname.includes(this.getPath('compensations'))) {
            return  3;
        } else if (location.pathname.includes(this.getPath('inventory'))) {
            return  4;
        } else if (location.pathname.includes(this.getPath('wallet'))) {
            return  5;
        } else if (location.pathname.includes(this.getPath('statistics'))) {
            return 6;
        } else if (location.pathname.includes(this.getPath('liveEvents'))) {
            return 7;
        } else if (location.pathname.includes(this.getPath('seasons'))) {
            return 8;
        } else if (location.pathname.includes(this.getPath('matchMaking'))) {
            return 9;
        } else if (location.pathname.includes(this.getPath('achievements'))) {
            return 10;
        } else if (location.pathname.includes(this.getPath('purchases'))) {
            return 11;
        }

        return 0;
    };

    private changeSubTabs = (tab: number) => {
        let to = '';
        switch (tab) {
            case 0:
                to = this.getPath('overview');
                break;
            case 1:
                to = this.getPath('cloudCode');
                break;
            case 2:
                to = this.getPath('data');
                break;
            case 3:
                to = this.getPath('compensations');
                break;
            case 4:
                to = this.getPath('inventory');
                break;
            case 5:
                to = this.getPath('wallet');
                break;
            case 6:
                to = this.getPath('statistics');
                break;
            case 7:
                to = this.getPath('liveEvents');
                break;
            case 8:
                to = this.getPath('seasons');
                break;
            case 9:
                to = this.getPath('matchMaking');
                break;
            case 10:
                to = this.getPath('achievements');
                break;
            case 11:
                to = this.getPath('purchases');
                break;
        }
        toLink(this.props, to);
    };

    private getPath = (component: string) => {
        const { playerId } = this.props;
        return `/players/${playerId}/${component}`;
    };
}
