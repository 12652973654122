import * as _ from 'lodash';
import * as React from 'react';
import {
    Avatar,
    CardMedia,
    Divider,
    Grid2 as Grid,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuProps,
    AppBar,
    MenuItem,
    Button as MaterialButton,
    useScrollTrigger,
    AppBarProps,
    Typography, ButtonProps,
} from '@mui/material';
import {
    ArrowDropDown,
    CasinoOutlined,
    DarkModeOutlined,
    LightModeOutlined,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { styled } from '@mui/styles';
import { AccountDialog } from '../../admin';
import actions from '../../../actions';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { UserRole } from '../../../enums';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FilterList, FilterListItemButton, FilterListItemIcon, FilterListItemText } from '../../../utils';

interface Props extends ThemeModeProps {
    readonly position?: 'fixed' | 'absolute' | 'sticky' | 'static';
    readonly navigate?: NavigateFunction;
    readonly userRole: UserRole;
    readonly showDrawerOpener: boolean;
    readonly loginUser: Entities.User;
    readonly titles?: Entities.Title[];
    readonly titleSelected?: Entities.Title;
    readonly auth: boolean;
    readonly clickLogoutMenu: () => any;
    readonly onTitlesMenuClick: (index: number) => any;
    readonly style: React.CSSProperties;
}

interface State {
    readonly authAnchorEl: HTMLElement | undefined;
    readonly themeModeAnchorEl: HTMLElement | undefined;
    readonly titlesAnchorEl: HTMLElement | undefined;
    readonly openAdminDialog: boolean;
}

export class AppHeader extends React.PureComponent<Props, State> {
    state = {
        authAnchorEl: undefined,
        themeModeAnchorEl: undefined,
        titlesAnchorEl: undefined,
        openAdminDialog: false,
    };

    render() {
        const { position, style } = this.props;
        return (
            <>
                <ElevateAppBar position={position} style={style}>
                    <Grid container={true} justifyContent="center" alignItems="center" spacing={0}>
                        <Grid size={10} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            {this.renderNucleoIcon()}
                            {this.renderDrawerOpener()}
                            {this.renderSelectTitlesMenu()}
                        </Grid>
                        <Grid size={2} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                            {this.renderAuthMenu()}
                            {this.renderThemeModeSelector()}
                        </Grid>
                    </Grid>
                </ElevateAppBar>
                {this.renderTitlesMenu()}
                {this.renderAccountDialog()}
            </>
        );
    }

    private renderNucleoIcon() {
        const { titleSelected } = this.props;
        if (titleSelected) {
            return;
        }

        return (
            <StyledTitleLink to={'/'} >
                <CardMedia
                    sx={{ width: 50, height: 52, backgroundSize: 50 }}
                    image={`${process.env.PUBLIC_URL}/images/nucleo-logo.png`}
                    title="Logo"
                />
                <Typography children={'Nucleo Portal'} variant="h1" color="inherit" sx={{ color: '#ecedee', fontSize: '18px', fontWeight: 600 }}/>
            </StyledTitleLink>
        );
    }

    private renderDrawerOpener() {
        const { themeMode, showDrawerOpener } = this.props;
        if(!showDrawerOpener) {
            return <></>;
        }

        return (
            <DrawerOpenerButton themeMode={themeMode} onClick={this.handleDrawerOpen}>
                <MenuIcon style={{ width: 24, height: 24 }}/>
            </DrawerOpenerButton>
        );
    }

    private renderSelectTitlesMenu() {
        const { titleSelected, titles, themeMode } = this.props;
        if (!titles || !titleSelected || titleSelected.id === '') {
            return;
        }

        const openMenu = (event: any) => this.setState({ titlesAnchorEl: event.currentTarget });

        return (
            <MaterialButton onClick={openMenu} sx={{
                justifyContent: 'flex-start',
                fontSize: '14px',
                fontWeight: 500,
                padding: 0,
                textTransform: 'none',
                color: themeMode === 'light' ? '#191919' : '#ebebeb',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: 'transparent'
                },
            }}>
                <Grid container={true} justifyContent="center" alignItems="center" spacing={1}>
                    <Grid style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {!titleSelected.imageUrl && (
                            <CasinoOutlined style={{width: 28, height: 28, borderRadius: 5}}/>
                        )}
                        {titleSelected.imageUrl && (
                            <CardMedia image={titleSelected.imageUrl} style={{width: 28, height: 28, borderRadius: 5}}/>
                        )}
                    </Grid>
                    <Grid style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {titleSelected.name}
                    </Grid>
                    <Grid style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <ArrowDropDown style={{ width: 20 , height: 20}}/>
                    </Grid>
                </Grid>
            </MaterialButton>
        );
    }

    private renderTitlesMenu() {
        const { titles } = this.props;
        const { titlesAnchorEl } = this.state;
        const open = !!titlesAnchorEl;

        const closeMenu = () => this.setState({ titlesAnchorEl: undefined });

        const menuItems = _.map(titles, (title, index) => {
            const clickTitlesMenu = () => {
                const { onTitlesMenuClick } = this.props;
                closeMenu();
                if (onTitlesMenuClick) {
                    onTitlesMenuClick(index);
                }
            };
            return (
                <MenuItem color="inherit" key={title.name} value={index} onClick={clickTitlesMenu}>
                    <ListItemIcon>
                        {!title.imageUrl && (
                            <CasinoOutlined
                                style={{ width: 28, height: 28, borderRadius: 5, color: 'rgba(0,0,0,0.8)' }} />
                        )}
                        {title.imageUrl && (
                            <CardMedia image={title.imageUrl} style={{ width: 28, height: 28, borderRadius: 5 }} />
                        )}
                    </ListItemIcon>
                    <ListItemText primary={title.name} />
                </MenuItem>
            );
        });

        const clickAllProjectsMenu = () => {
            const { onTitlesMenuClick } = this.props;
            closeMenu();
            if (onTitlesMenuClick) {
                onTitlesMenuClick(-1);
            }
        };
        return (
            <TitlesMenu
                id="menu-title"
                anchorEl={titlesAnchorEl}
                open={open}
                onClose={closeMenu}
                style={{ margin: '30px 0px' }}
            >
                <MenuItem color="inherit" key={'all projects'} value={-1} onClick={clickAllProjectsMenu}>
                    {'See all projects'}
                </MenuItem>
                <Divider style={{ backgroundColor: '#0000001f', margin: '8px 0'}}/>
                <div style={{color: '#0000008a', padding: '8px 30px'}}>
                    All projects
                </div>
                {menuItems}
            </TitlesMenu>
        );
    }

    private renderAuthMenu() {
        const { auth, loginUser } = this.props;
        const { authAnchorEl } = this.state;
        if (!auth) {
            return;
        }

        const openMenu = (event: any) => this.setState({ authAnchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ authAnchorEl: undefined });

        const clickAccountMenu = () => {
            closeMenu();
            this.clickAccountMenu();
        };

        const clickLogoutMenu = () => {
            closeMenu();
            if (this.props.clickLogoutMenu) {
                this.props.clickLogoutMenu();
            }
        };

        const open = !!authAnchorEl;

        return (
            <>
                <AuthButton
                    style={{ padding: 0, minWidth: 32 }}
                    onClick={openMenu}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={loginUser.picture}
                        style={{
                            height: 32,
                            width: 32,
                            backgroundColor: 'rgba(0,0,0,0.025)',
                        }}
                    />
                </AuthButton>
                <AuthMenu
                    id="menu-appbar"
                    anchorEl={authAnchorEl}
                    open={open}
                    onClose={closeMenu}
                >
                    <Grid container={true}>
                        <Grid size={12} style={{ display: 'inline-block', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <Avatar
                                src={loginUser.picture}
                                style={{
                                    height: 80,
                                    width: 80,
                                    backgroundColor: 'rgba(0,0,0,0.025)',
                                    margin: '10px auto'
                                }}
                            />
                            <div>
                                <div style={{
                                    fontSize: '0.875rem',
                                    lineHeight: '1.25rem',
                                }}>
                                    {loginUser.name}
                                </div>
                                <div style={{
                                    fontSize: '0.75rem',
                                    lineHeight: '1.2',
                                    fontWeight: 400,
                                }}>
                                    {loginUser.username}
                                </div>
                            </div>
                            <MaterialButton
                                style={{
                                    padding: '0px 12px',
                                    width: 150,
                                    color: '#1a73e8',
                                    margin: '10px auto',
                                    height: 30,
                                }}
                                onClick={clickAccountMenu}
                            >
                                {'Account'}
                            </MaterialButton>
                            {this.props.userRole === UserRole.Admin && (
                                <MaterialButton
                                    style={{
                                        padding: '0px 12px',
                                        width: 150,
                                        color: '#1a73e8',
                                        margin: '10px auto',
                                        height: 30,
                                    }}
                                    onClick={this.goToAdmin}
                                >
                                    {'Admin'}
                                </MaterialButton>
                            )}
                        </Grid>
                        <Grid size={12}>
                            <Divider/>
                        </Grid>
                        <Grid size={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                            <MaterialButton
                                variant="contained"
                                style={{
                                    padding: '0px 12px',
                                    width: 150,
                                    margin: '10px auto',
                                    height: 38,
                                }}
                                onClick={clickLogoutMenu}
                            >
                                {'Sign Out'}
                            </MaterialButton>
                        </Grid>
                    </Grid>
                </AuthMenu>
            </>
        );
    }

    private renderAccountDialog = () => {
        const { loginUser } = this.props;
        const { openAdminDialog } = this.state;
        if (!loginUser) {
            return;
        }

        return (
            <AccountDialog
                open={openAdminDialog}
                title={'Account'}
                loginUser={loginUser as Entities.User}
                onClose={this.closeAdminDialog}
                onUpdatePassword={this.onUpdatePassword}
            />
        );
    }

    private renderThemeModeSelector = () => {
        const { themeMode, titleSelected } = this.props;
        const { themeModeAnchorEl } = this.state;

        const openMenu = (event: any) => this.setState({ themeModeAnchorEl: event.currentTarget });
        const closeMenu = () => this.setState({ themeModeAnchorEl: undefined });
        const open = !!themeModeAnchorEl;

        const changeThemeMode = (themeMode: ThemeMode) => {
            closeMenu();
            actions.app.changeThemeMode(themeMode);
        }

        return (
            <>
                <AuthButton
                    style={{ color: !titleSelected ? '#ecedee' : themeMode === 'light' ? '#717171' : '#aeaeae', padding: 0, minWidth: 32, margin: '0px 10px' }}
                    onClick={openMenu}
                >
                    {themeMode === 'light' && (
                        <LightModeOutlined style={{ height: 24, width: 24 }} />
                    )}
                    {themeMode === 'dark' && (
                        <DarkModeOutlined style={{ height: 24, width: 24, }} />
                    )}
                </AuthButton>
                <ThemeModeMenu
                    id="menu-appbar"
                    anchorEl={themeModeAnchorEl}
                    open={open}
                    onClose={closeMenu}
                >
                    <FilterList themeMode={themeMode} style={{ minWidth: 125, padding: '10px 0px'}}>
                        <FilterListItemButton disabled={themeMode === 'light'} themeMode={themeMode} style={{ minHeight: 32, padding: '6px 16px'}} key={'light'} onClick={(event) => changeThemeMode('light')}>
                            <FilterListItemIcon themeMode={themeMode}>
                                <LightModeOutlined style={{ marginRight: 16 }} />
                            </FilterListItemIcon>
                            <FilterListItemText text={'Light Theme'} />
                        </FilterListItemButton>
                        <FilterListItemButton disabled={themeMode === 'dark'} themeMode={themeMode} style={{ minHeight: 32, padding: '6px 16px'}} key={'dark'} onClick={(event) => changeThemeMode('dark')}>
                            <FilterListItemIcon  themeMode={themeMode}>
                                <DarkModeOutlined style={{ marginRight: 16 }} />
                            </FilterListItemIcon>
                            <FilterListItemText text={'Dark Theme'} />
                        </FilterListItemButton>
                    </FilterList>
                </ThemeModeMenu>
            </>
        );
    }

    private clickAccountMenu = () => this.setState({ openAdminDialog: true });

    private closeAdminDialog = () => this.setState({ openAdminDialog: false });

    private onUpdatePassword = async (password: string) => {
        if (password) {
            await actions.admin.updatePassword(password);
        }
        this.closeAdminDialog();
    };

    private handleDrawerOpen = () => actions.app.changeSmDrawerOpen();

    private goToAdmin = () => {
        if (this.props.navigate) {
            this.props.navigate('/admin');
        }
    }
}

const ElevateAppBar = styled((props: AppBarProps) => {
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 5});
    return (
        <AppBar
            {...props}
            style={{ ...props.style, boxShadow: trigger ? '0px 1px 2px 0px rgba(60,64,67,.3), 0px 1px 3px 1px rgba(60,64,67,.15)' : 'none'}}
        />
    );
})(() => ({}));


const TitlesMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        marginTop: 0,
    },
}));

const AuthMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        borderRadius: 28,
        marginTop: 12,
        display: 'grid',
    },
    '& .MuiMenu-list': {
        padding: 0,
    }
}));

const ThemeModeMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(() => ({
    '& .MuiMenu-paper': {
        borderRadius: 8,
        marginTop: 12,
        display: 'grid',
    },
    '& .MuiMenu-list': {
        padding: 0,
    }
}));

interface DrawerOpenerButtonProps extends ButtonProps, ThemeModeProps {}
const DrawerOpenerButton = styled((props: DrawerOpenerButtonProps) => (
    <MaterialButton
        {...props}
        sx={{
            justifyContent: 'flex-start',
            fontSize: '16px',
            fontWeight: 500,
            margin: '0px 10px 0px 0px',
            textTransform: 'none',
            color: props.themeMode === 'light' ? '#191919' : '#ebebeb',
            padding: '5px',
            minWidth: 0,
            borderRadius: '50%',
        }}
    />
))(() => ({}));

const AuthButton = styled(MaterialButton)(({
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent'
    },
}));

const StyledTitleLink = styled(ReactRouterLink)(({
    display: 'flex',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#F2F2F2',
    gridColumnGap: 10,
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: 'transparent',
    },
}));
