import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    CreateSeasonRegularComponent,
    CreateSeasonLeaderBoardComponent,
    BaseElement,
} from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { EventType, LeaderBoardType, SeasonType } from '../enums';
import { GamesOutlined as GamesIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly seasonId: string;
    readonly season?: Entities.Season;
    readonly leaderBoardDefinition?: Entities.EntityLeaderBoard;
}

class Container extends BaseElement<Props> {
    async componentDidMount() {
        const { seasonId } = this.props;
        await actions.season.get(seasonId, true);
    }

    protected renderContainer(): React.JSX.Element {
        const { season } = this.props;
        let headlineText = '';
        if (season) {
            switch (season.type) {
                case SeasonType.Regular:
                    headlineText = 'New Regular';
                    break;
                case SeasonType.LeaderBoard:
                    headlineText = 'New Leaderboard';
                    break;
                default:
                    break;
            }
        }

        const headlines = [
            {
                text: 'Season',
                to: `/season/all`,
            },
            {
                text: headlineText,
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"New Season"}
                TitleIcon = {GamesIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { season, leaderBoardDefinition } = this.props;
        if (!season) {
            return;
        }

        switch (season.type) {
            case SeasonType.Regular:
                return (
                    <CreateSeasonRegularComponent
                        type='Create'
                        onCreate={this.onCreateRegular}
                        oldSeason={season}
                    />
                );
            case EventType.LeaderBoard:
                return (
                    <CreateSeasonLeaderBoardComponent
                        type='Create'
                        onCreate={this.onCreateLeaderBoard}
                        oldSeason={season}
                        maxUsersPerInstance={leaderBoardDefinition ? leaderBoardDefinition.maxUsersPerInstance : 200}
                    />
                );
            default:
                return;
        }
    }

    private onCreateRegular = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], maxAmountPerReport: number) => {
        await actions.season.createRegular(name, start, end, metadata, developerOnly, tiers, maxAmountPerReport);
        this.toSeason();
    };

    private onCreateLeaderBoard = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, maxAmountPerReport: number, leaderBoardType: LeaderBoardType) => {
        await actions.season.createLeaderBoard(name, start, end, metadata, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType);
        this.toSeason();
    };

    private toSeason = () => this.toLink(`/season/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    seasonId: '',
    season: state.season.season,
    leaderBoardDefinition: state.season.leaderBoardDefinition,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const seasonId = params.seasonId || '';
    return (<Container {...props} navigate={navigate} seasonId={seasonId}/>);
};
export default connect(mapStateToProps)(AppContainer);
