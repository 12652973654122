import * as React from 'react';
import { connect } from 'react-redux';
import { BaseAnalyzePlayers, BaseElement, FilterDates, Table } from '../components';
import { ContainerContext, mapProps } from './index';
import { ActionRequest } from '../actions';
import { formatDate, getPlayerLink, localeCurrencyNumber } from '../utils';
import { default as moment, Moment } from 'moment';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

interface State {
    readonly startTimeSpam: Moment;
    readonly endTimeSpam: Moment;
}

class Container extends BaseElement<Props, State> {
    state = {
        startTimeSpam: moment.parseZone().subtract(24, 'hours'),
        endTimeSpam: moment.parseZone(),
    };

    protected renderContainer(): React.JSX.Element {
        const { themeMode } = this.props.app;
        return (
            <BaseAnalyzePlayers themeMode={themeMode} {...this.props} >
                {this.renderContent()}
            </BaseAnalyzePlayers>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Player Id', field: 'playerId'},
            { title: 'Transaction Id', field: 'transactionId'},
            { title: 'Product Id', field: 'productId'},
            { title: 'Purchase Date', field: 'purchaseTime'},
            { title: 'Price', field: 'purchasePrice'},
            { title: 'Environment', field: 'environment'},
            { title: 'Platform', field: 'platform'},
        ];

        const { startTimeSpam, endTimeSpam} = this.state;
        const tableRef = React.createRef();
        const onSearch = () => {
            // @ts-ignore
            tableRef.current.onSearchChangeDebounce();
        };

        const onFilterDates = (start: Moment, end: Moment) => {
            this.setState({ startTimeSpam: start, endTimeSpam: end});
            onSearch();
        };

        return (
            <Table
                tableRef={tableRef}
                title={<div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px 0px' }}>
                    <FilterDates
                        start={startTimeSpam}
                        end={endTimeSpam}
                        onFilter={onFilterDates}
                    />
                </div>}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                }}
                data={this.getData}
            />
        );
    };

    private getData = async (query: any) => {
        const { startTimeSpam, endTimeSpam } = this.state;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const result = await ActionRequest.get(`analyze/players/payments`, {
            filter: search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
            startTimeSpam: startTimeSpam.valueOf(),
            endTimeSpam: endTimeSpam.valueOf(),
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.data.map((p: any) => {
            return {
                playerId: getPlayerLink(p.playerId),
                transactionId: p.transactionId,
                productId: p.productId,
                purchaseTime: formatDate(p.purchaseTime),
                environment: (p.environment === 1) ? 'Production' : 'Sandbox',
                purchasePrice: localeCurrencyNumber(p.purchasePrice / 100, p.currencyCode),
                platform: p.platform === 1 ? 'Android' : 'iOS',
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
