import * as React from 'react';
import { EventType, TimerRecurringType, TimerType } from '../../enums';
import {
    Grid2 as Grid,
    Step as MaterialStep,
    StepButton,
    Stepper,
    CardMedia, Chip,
} from '@mui/material';
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    DeveloperModeOutlined, ExpandMore,
} from '@mui/icons-material';
import {
    daysMenus, DaysMenuType,
    formatMomentDate,
    getColorIndexByTitle, getColorTitleByIndex,
    getDurationValueFromTimeStamp,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../../utils';
import { BaseCreateNewEventComponent, CreateLiveEventProps, CreateLiveEventState } from './BaseCreateNewEventComponent';
import { AutomatePlayerAction, Button, LabelCheckBox, Metadata } from '../common';
import { default as moment } from 'moment';

interface Props extends CreateLiveEventProps {
    readonly onCreate: (liveEvent: Entities.LiveEvent) => void;
}

export class CreateNewRegularEventComponent extends BaseCreateNewEventComponent<Props> {

    state = this.initState();

    render() {
        const { step } = this.state;

        let detailContent: React.JSX.Element;
        let detailButtons: React.JSX.Element;

        let activeStep;

        switch (step) {
            case 'Detail':
                activeStep = 0;
                detailContent = this.renderDetailContent();
                detailButtons = this.renderDetailButtons();
                break;
            case 'Schedule':
                activeStep = 1;
                detailContent = this.renderScheduleContent();
                detailButtons = this.renderScheduleButtons();
                break;
            case 'FinishActions':
                activeStep = 2;
                detailContent = this.renderFinishActionsContent();
                detailButtons = this.renderFinishActionsButtons();
                break;
            case 'Confirm':
                activeStep = 3;
                detailContent = this.renderConfirmContent();
                detailButtons = this.renderConfirmButtons();
                break;
            default:
                activeStep = -1;
                detailContent = <></>;
                detailButtons = <></>;
                break;
        }

        return (
            <Grid container={true} spacing={1} style={{ width: '90%', margin: 'auto' }}>
                <Grid size={12}>
                    <Stepper
                        alternativeLabel={true}
                        nonLinear={true}
                        activeStep={activeStep}
                        style={{
                            width: '80%',
                            margin: 'auto',
                            borderRadius: 5,
                        }}
                    >
                        <MaterialStep key={'Details'} completed={activeStep > 0}>
                            <StepButton onClick={this.onDetailStepSet} >
                                {'Details'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Schedule'} completed={activeStep > 1}>
                            <StepButton onClick={this.onScheduleStepSet} >
                                {'Schedule'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'FinishActions'} completed={activeStep > 3}>
                            <StepButton onClick={this.onFinishActionsStepSet} >
                                {'Finish Actions'}
                            </StepButton>
                        </MaterialStep>
                        <MaterialStep key={'Confirm'} completed={activeStep > 4}>
                            <StepButton onClick={this.onConfirmStepSet} >
                                {'Confirm'}
                            </StepButton>
                        </MaterialStep>
                    </Stepper>
                </Grid>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12}>
                    {detailContent}
                </Grid>
                <Grid size={12} style={{height: 30}}/>
                <Grid size={12} style={{
                    display: 'grid',
                    justifyContent: 'flex-end',
                    gridAutoFlow: 'column',
                    gridColumnGap: 10,
                }}>
                    {detailButtons}
                </Grid>
            </Grid>
        );
    }

    protected updateFromOld() {
        const { oldLiveEvent, type } = this.props;
        if (!oldLiveEvent) {
            return;
        }

        const { name, startTimestamp, endTimestamp, gameData, finishActions, scheduleColor, developerOnly } = oldLiveEvent;
        if (type === 'Create') {
            this.setState({
                name: `${name} (Copy)`,
                gameData,
                finishActions: finishActions ? finishActions : [],
                developerOnly,
            });
            return;
        }

        this.setState({
            name,
            start: moment(startTimestamp),
            duration: getDurationValueFromTimeStamp(startTimestamp, endTimestamp),
            color: getColorIndexByTitle(scheduleColor),
            gameData,
            finishActions: finishActions ? finishActions : [],
            developerOnly,
        });
    }

    protected renderDetailContent(): React.JSX.Element {
        const { developerOnly } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={8} style={{ margin: 'auto' }}>
                    {developerOnly && (
                        <Chip
                            color={'secondary'}
                            icon={<DeveloperModeOutlined />}
                            size='medium'
                            label='Developer Only'
                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                        />
                    )}
                </Grid>
                <Grid size={4} alignContent={'end'} >
                    <LabelCheckBox
                        label="Developer Only"
                        labelSize={'14px'}
                        icon={DeveloperModeOutlined}
                        iconColor={'inherit'}
                        labelPlacement={'start'}
                        checked={developerOnly}
                        justifyContent={'flex-end'}
                        onChange={this.handleDeveloperOnlyChange}
                    />
                </Grid>
                {this.renderNameField()}
                {this.renderImageField()}
                {this.renderGameDataField()}
            </Grid>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        const {
            name,
            start,
            duration,
            gameData,
            finishActions,
            file,
            useDuration,
            developerOnly,
            timerType,
            timerRecurringType,
            timerRecurringStart,
            timerRecurringDuration,
            timerRecurringDays,
        } = this.state;
        let { end } = this.state;
        if (useDuration) {
            end = start.clone().add(duration, 'minutes');
        }

        const dayNames = timerRecurringDays.map(day => {
            let dayName = '';
            Object.keys(daysMenus).forEach(key => {
                const dayMenuType = key as DaysMenuType;
                if (daysMenus[dayMenuType].value === day) {
                    dayName = daysMenus[dayMenuType].title;
                }
            });
            return dayName;
        });

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    <StyledAccordion defaultExpanded={true}>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            Details
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} justifyContent="center" spacing={1}>
                                {developerOnly && (
                                    <Grid size={12}>
                                        <Chip
                                            color={'secondary'}
                                            icon={<DeveloperModeOutlined />}
                                            size='small'
                                            label='Developer Only'
                                            style={{ backgroundColor: '#21BA47', color: 'white', padding: '10px 40px' }}
                                        />
                                    </Grid>
                                )}
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong>Name</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {name}
                                </Grid>
                                {timerType === TimerType.Scheduled && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start)}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end)}
                                        </Grid>
                                    </>
                                )}
                                {timerType === TimerType.Recurring && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Start</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(start, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>End</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {formatMomentDate(end, 'LL')}
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Repeat</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            {timerRecurringType === TimerRecurringType.Daily && (
                                                <>
                                                    {`Daily at ${formatMomentDate(timerRecurringStart, 'LT')} for ${timerRecurringDuration} minutes`}
                                                </>
                                            )}
                                            {timerRecurringType === TimerRecurringType.Weekly && (
                                                <>
                                                    {`Weekly ${dayNames.join(' - ')} at ${formatMomentDate(timerRecurringStart, 'LT')} for ${timerRecurringDuration} minutes`}
                                                </>
                                            )}
                                        </Grid>
                                    </>
                                )}
                                {file && (
                                    <>
                                        <Grid size={{ xs: 12, sm: 3 }}>
                                            <strong>Image</strong>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 9 }}>
                                            <CardMedia image={URL.createObjectURL(file)} style={{width: 200, height: 200, margin: 10}}/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                {gameData !== '' && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Metadata
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <Metadata
                                    text={gameData}
                                    readOnly={true}
                                    style={{ width: '100%' }}
                                    keyValueContainerStyle={{ boxShadow: 'none' }}
                                    showBox={false}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
                {finishActions.length > 0 && (
                    <Grid size={12}>
                        <StyledAccordion defaultExpanded={true}>
                            <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                Actions
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                                <AutomatePlayerAction
                                    actions={finishActions}
                                    readOnly={true}
                                />
                            </StyledAccordionDetails>
                        </StyledAccordion>
                    </Grid>
                )}
            </Grid>
        );
    }

    protected initState(): CreateLiveEventState {
        const baseState = super.initState();

        return {
            ...baseState,
        };
    }

    protected onCreate = () => {
        const {
            name,
            start,
            end,
            duration,
            gameData,
            color,
            developerOnly,
            finishActions,
            useDuration,
            timerType,
            timerRecurringType,
            timerRecurringStart,
            timerRecurringDuration,
            timerRecurringDays,
        } = this.state;

        start.set('seconds', 0);
        start.set('millisecond', 0);
        timerRecurringStart.set('seconds', 0);
        timerRecurringStart.set('millisecond', 0);
        end.set('seconds', 0);
        end.set('millisecond', 0);

        const liveEvent: Entities.LiveEvent = {
            eventId: '',
            name,
            gameData,
            timerType,
            startTimestamp: 0,
            endTimestamp: 0,
            lastUpdatedTimestamp: 0,
            createdTimestamp: 0,
            eventType: EventType.Regular,
            scheduleColor: getColorTitleByIndex(color),
            developerOnly,
            finishActions
        };

        switch (timerType) {
            case TimerType.Scheduled:
                liveEvent.startTimestamp = start.clone().utc().valueOf();
                liveEvent.endTimestamp = useDuration ? start.clone().add(duration, 'minutes').utc().valueOf() : end.clone().utc().valueOf();
                break;
            case TimerType.Recurring:
                liveEvent.startTimestamp = start.clone().utc().startOf('day').valueOf();
                liveEvent.endTimestamp = end.clone().utc().endOf('day').valueOf();
                liveEvent.timerRecurringType = timerRecurringType;
                liveEvent.timerRecurringStart = timerRecurringStart.utc().valueOf() - liveEvent.startTimestamp;
                liveEvent.timerRecurringDuration = timerRecurringDuration * 60 * 1000;
                liveEvent.timerRecurringDays = timerRecurringDays;
                break;
        }

        this.props.onCreate(liveEvent);
    }

    protected renderScheduleButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onFinishActionsStepSet} variant="contained" />
            </>
        );
    };

    protected renderFinishActionsButtons = () => {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary" />
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained" />
            </>
        );
    };
}
