import React from 'react';
import { Filter } from '../common';
import { DeveloperModeOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { FilterList, FilterListItem, FilterListItemIcon, FilterListItemText } from '../../utils';

interface Props extends ThemeModeProps {
    readonly developerOnly: boolean;
    readonly onFilter: (developerOnly: boolean) => void;
}

interface State {
    readonly developerOnly: boolean;
}

export class PromoCampaignFilter extends React.Component<Props, State> {
    state: State = {
        developerOnly: false,
    };

    componentDidUpdate(prevProps: Props) {
        const { developerOnly } = this.props;
        if ( prevProps.developerOnly === developerOnly) {
            return;
        }

        this.setState({
            developerOnly,
        });
    }

    render() {
        const { themeMode } = this.props;
        const { developerOnly } = this.state;

        const onFilterDeveloper = () => this.setState({ developerOnly: !developerOnly });
        const onFilter = () => {
            this.props.onFilter(developerOnly);
        };

        const onClose = () => this.setState({
            developerOnly: this.props.developerOnly,
        });

        return (
            <Filter
                themeMode={themeMode}
                title={'Filter Promo Campaign'}
                menuTitles = {['Player Type']}
                menuElements = {[
                    (
                        <FilterList themeMode={themeMode}>
                            <FilterListItem
                                themeMode={themeMode}
                                key={`developer_only`}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={onFilterDeveloper}
                                        checked={developerOnly}
                                        style={{ color: 'inherit' }}
                                    />
                                }
                            >
                                <FilterListItemIcon themeMode={themeMode}>
                                    <DeveloperModeOutlined style={{ width: 18, height: 18, color: '#21BA47' }} />
                                </FilterListItemIcon>
                                <FilterListItemText text={'Developers Only'} />
                            </FilterListItem>
                        </FilterList>
                    ),
                ]}
                onFilter={onFilter}
                onClose={onClose}
            />
        );
    }
}
