import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement, Metadata,
} from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ExpandMore, PaidOutlined as PaidIcon } from '@mui/icons-material';
import { Grid2 as Grid } from '@mui/material';
import {
    formatDate,
    isActive,
    isEnded,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
} from '../utils';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly storeItemId: string;
    readonly item?: Entities.EconomyStoreItem;
}

class Container extends BaseElement<Props> {

    async componentDidMount() {
        const { storeItemId } = this.props;
        await actions.economy.getStoreItem(storeItemId);
    }

    protected renderContainer(): React.JSX.Element {
        const { storeItemId } = this.props;
        const headlines = [
            {
                text: 'Store',
                to: `/economy/store`,
            },
            {
                text: storeItemId,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Economy Store Item Details"}
                TitleIcon = {PaidIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { item } = this.props;
        if (!item) {
            return <></>;
        }

        return (
            <Grid container={true} justifyContent="center" spacing={4}>
                <Grid size={12}>
                    {this.renderDetails()}
                </Grid>
                <Grid size={12}>
                    {this.renderGameData()}
                </Grid>
            </Grid>
        );
    }

    private renderDetails = () => {
        const { item } = this.props;
        if (!item) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Details
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Grid container={true} justifyContent="center" spacing={1}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Status</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {this.getStatus(item)}
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong>Display Name</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {item.displayName}
                        </Grid>
                        {item.startTimestamp !== null && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>Start at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatDate(item.startTimestamp)}
                                </Grid>
                            </>
                        )}
                        {item.endTimestamp !== null && (
                            <>
                                <Grid size={{ xs: 12, sm: 3 }}>
                                    <strong style={{width: 250}}>End at</strong>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 9 }}>
                                    {formatDate(item.endTimestamp)}
                                </Grid>
                            </>
                        )}
                        <Grid size={{ xs: 12, sm: 3 }}>
                            <strong style={{width: 250}}>Edited by</strong>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 9 }}>
                            {item.username}
                        </Grid>
                    </Grid>
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private renderGameData = () => {
        const { item } = this.props;
        if (!item) {
            return <></>;
        }

        return (
            <StyledAccordion defaultExpanded={true}>
                <StyledAccordionSummary expandIcon={<ExpandMore />}>
                    Metadata
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Metadata
                        text={item.metadata}
                        readOnly={true}
                        style={{ width: '100%' }}
                        keyValueContainerStyle={{ boxShadow: 'none' }}
                        showBox={false}
                    />
                </StyledAccordionDetails>
            </StyledAccordion>
        );
    };

    private getStatus = (item: Entities.EconomyStoreItem) => {
        if (isActive(item.startTimestamp, item.endTimestamp)) {
            return <div style={{color: 'green'}}>In Progress</div>;
        }
        if (isEnded(item.endTimestamp)) {
            return <div style={{color: 'orange'}}>Completed</div>;
        }
        return <div style={{color: 'grey'}}>Scheduled</div>;
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    storeItemId: '',
    item: state.economy.storeItem,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const storeItemId = params.storeItemId || '';
    return (<Container {...props} navigate={navigate} storeItemId={storeItemId}/>);
};
export default connect(mapStateToProps)(AppContainer);
