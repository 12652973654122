import * as React from 'react';
import { ContainerContext } from '../../containers';
import { BaseContainer, CommonHeaderProps } from '../common';
import { toLink } from '../../utils';
import { PropsWithChildren } from 'react';
import { PaidOutlined as PaidIcon } from '@mui/icons-material';

interface Props extends ContainerContext.Props, CommonHeaderProps, PropsWithChildren {
}

export class BaseEconomyContainer extends React.Component<Props> {
    render() {
        const { loading, themeMode, title, headline, children, buttons, headlines } = this.props;

        const subTabsTitles = ['Items', 'Currencies', 'Bundles', 'Store'];

        return (
            <BaseContainer
                loading= {loading}
                themeMode={themeMode}
                title = {title}
                TitleIcon = {PaidIcon}
                headline = {headline}
                headlines = {headlines}
                subTabs={{
                    titles: subTabsTitles,
                    selected: this.subTabsSelected(),
                    onChange: this.changeSubTabs,
                }}
                buttons={buttons}
            >
                {children}
            </BaseContainer>
        );
    }

    private subTabsSelected = () => {
        if (location.pathname.includes(this.getSubPath('items'))) {
            return 0;
        } else if (location.pathname.includes(this.getSubPath('currencies'))) {
            return 1;
        } else if (location.pathname.includes(this.getSubPath('bundles'))) {
            return 2;
        } else if (location.pathname.includes(this.getSubPath('store'))) {
            return 3;
        }

        return 0;
    };

    private changeSubTabs = (tab: number) => {
        let to = '';
        switch (tab) {
            case 0:
                to = this.getSubPath('items');
                break;
            case 1:
                to = this.getSubPath('currencies');
                break;
            case 2:
                to = this.getSubPath('bundles');
                break;
            case 3:
                to = this.getSubPath('store');
                break;
        }
        toLink(this.props, to);
    };

    private getSubPath = (component: string) => {
        return `/economy/${component}`;
    };
}
