import * as React from 'react';
import {
    BaseElement,
    BaseContainer,
    Table,
    HeaderButton,
    CreateGroupDialog,
    EditGroupDialog,
    WarningDialog,
} from '../components';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import { formatDate, Link } from '../utils';
import actions, { ActionRequest } from '../actions';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    GroupWorkOutlined as GroupWorkIcon,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { GroupPrivacy, UserTitleRole } from '../enums';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Create' | 'Delete' | 'Edit';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedGroup?: Entities.Group;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = "Groups"
                TitleIcon={GroupWorkIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderEditDialog()}
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { loading, userTitleRole } = this.props;
        const columns = [
            { title: 'Details', field: 'details'},
            { title: 'Privacy', field: 'privacy'},
            { title: 'Player Count', field: 'playerCount'},
        ];

        const onRowClick = (event: any, rowData: any) => this.toLink(`/group/${rowData.group.id}/details`);
        return (
            <Table
                columns = {columns}
                onRowClick={onRowClick}
                options = {{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    sorting: false,
                    draggable: false,
                    search: true,
                    actionsColumnIndex: -1
                }}
                data = {this.getGroups}
                actions = {
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openEditDialog(rowData.group)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openDeleteDialog(rowData.group)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getGroups = async (query: any) => {
        const { search, page, pageSize } = query;
        const result = await ActionRequest.get<Entities.ListGroupResponse>(`group/list`, {
            search,
            page: page + 1,
            perPage: pageSize,
        });
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.entities.map(group => {
                const details = this.getDetails(group);
                return {
                    group,
                    privacy: GroupPrivacy[group.privacy],
                    details,
                    playerCount: `${group.playersCount}/${group.maxPlayersCount}`,
                };
            }
        );
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };

    private getDetails = (group: Entities.Group) => {
        const to = `/group/${group.id}/details`;
        return (
            <div>
                <div style={{ display: 'inline-block', marginLeft: 10, verticalAlign: 'top' }}>
                    <Link to={to}>
                        {group.name !== '' ? group.name : group.id}
                    </Link>
                    <div>
                        <div style={{ fontWeight: 500 }}>Created</div>
                        {formatDate(group.created)}
                    </div>
                    <div>
                        <div style={{ fontWeight: 500 }}>Last Edited</div>
                        {formatDate(group.updated)}
                    </div>
                </div>
            </div>
        );
    };

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateGroupDialog
                open={openDialog === 'Create'}
                title={'Create Group'}
                TitleIcon = {GroupWorkIcon}
                onCreate={this.onCreateGroup}
                onClose={this.closeDialog}
            />
        );
    };
    private onCreateGroup = async (name: string, privacy: number, description: string, maxSize: number, metadata: string) => {
        this.closeDialog();
        await actions.group.create(name, privacy, description, maxSize, metadata);
    };

    private openDeleteDialog = (group: Entities.Group) => this.setState({openDialog: 'Delete', selectedGroup: group});
    private renderDeleteDialog = () => {
        const { openDialog, selectedGroup } = this.state;
        if (!selectedGroup) {
            return;
        }
        const title = `Delete group '${selectedGroup.name}'?`;

        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={title}
                TitleIcon = {GroupWorkIcon}
                content="This will permanently delete the group and all associated players."
                onClose={this.closeDialog}
                onSubmit={this.onDelete}
            />
        );
    };
    private onDelete = async () => {
        const { selectedGroup } = this.state;
        if (!selectedGroup) {
            return;
        }
        await actions.group.remove(selectedGroup.id);
    };

    private openEditDialog = (group: Entities.Group) => this.setState({openDialog: 'Edit', selectedGroup: group});
    private renderEditDialog = () => {
        const { openDialog, selectedGroup } = this.state;
        if (!selectedGroup) {
            return;
        }

        return (
            <EditGroupDialog
                open={openDialog === 'Edit'}
                title={'Edit Group'}
                group={selectedGroup}
                TitleIcon = {GroupWorkIcon}
                onClose={this.closeDialog}
                onEdit={this.onEdit}
            />
        );
    };
    private onEdit = async (id: string, name: string, privacy: number, description: string, maxSize: number, metadata: string) => {
        const { selectedGroup } = this.state;
        if (!selectedGroup) {
            return;
        }
        await actions.group.update(id, name, privacy, description, maxSize, metadata);
    };

    private closeDialog = () => {
        this.setState({ openDialog: null });
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) => {
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
