import { ContainerContext, mapProps } from './index';
import * as React from 'react';
import actions from '../actions';
import {
    WarningDialog,
    BaseCloudBuildContainer,
    Table,
    AddJenkinsJobDialog,
    AddUnityCloudJobDialog,
    EditJobDialog,
    BaseElement,
    HeaderButton,
} from '../components';
import { UserTitleRole } from '../enums';
import { CardMedia, Menu, MenuItem } from '@mui/material';
import { AddOutlined, DeleteOutlineOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    jobs: Entities.CloudBuildJob[];
}

type DialogType = 'AddJenkins' | 'AddCloudBuild' | 'Edit' | 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly createAnchorEl: HTMLElement | undefined;
    readonly selectedJob: Entities.CloudBuildJob | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedJob: null,
        createAnchorEl: undefined,
    };

    async componentDidMount() {
        await actions.devOps.getCloudBuildDetails();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Add Job', icon: AddOutlined, onClick: this.handleCreateMenu},
        ];
        return (
            <BaseCloudBuildContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseCloudBuildContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateMenu()}
                {this.renderAddJenkinsDialog()}
                {this.renderAddUnityCloudDialog()}
                {this.renderDeleteDialog()}
                {this.renderEditDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const { jobs } = this.props;
        const columns = [
            { title: 'Name', field: 'name' },
        ];

        const data = jobs.map(job => {
            let name = <></>;
            switch (job.type) {
                case 'Jenkins':
                    name = <div style={{ display: "flex", alignItems: 'center'}}>
                        <CardMedia
                            style={{ height: 46, width: 46, marginRight: 10 }}
                            image={`${process.env.PUBLIC_URL}/images/jenkins_icon.png`}
                            title="Logo"
                        />
                        {job.name}
                    </div>;
                    break;
                case 'UnityCloud':
                    name = <div style={{ display: "flex", alignItems: 'center'}}>
                        <CardMedia
                            style={{ height: 46, width: 46, marginRight: 10 }}
                            image={`${process.env.PUBLIC_URL}/images/unity_icon.png`}
                            title="Logo"
                        />
                        {job.name}
                    </div>;
                    break;
            }
            return {
                name,
                job,
            };
        });

        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                }}
                actions = {
                    userTitleRole > UserTitleRole.Editor ?
                        [
                            rowData => ({
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openEditDialog(rowData.job)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openDeleteDialog(rowData.job)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private renderCreateMenu = () => {
        const { createAnchorEl } = this.state;
        const open = !!createAnchorEl;

        return (
            <Menu
                id="menu-new-job"
                anchorEl={createAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={this.closeCreateMenu}
            >
                <MenuItem color="inherit" onClick={this.openAddJenkinsDialog}>
                    <CardMedia
                        style={{ height: 46, width: 46, marginRight: 10 }}
                        image={`${process.env.PUBLIC_URL}/images/jenkins_icon.png`}
                        title="Logo"
                    />
                    Jenkins Job
                </MenuItem>
                <MenuItem color="inherit" onClick={this.openAddUnityCloudDialog}>
                    <CardMedia
                        style={{ height: 46, width: 46, marginRight: 10 }}
                        image={`${process.env.PUBLIC_URL}/images/unity_icon.png`}
                        title="Logo"
                    />
                    Unity Cloud Job
                </MenuItem>
            </Menu>
        );
    };
    private handleCreateMenu = (event: any) => this.setState({ createAnchorEl: event.currentTarget });
    private closeCreateMenu = () => this.setState({ createAnchorEl: undefined });

    private openAddJenkinsDialog = () => this.setState({ createAnchorEl: undefined, openDialog: 'AddJenkins' });
    private renderAddJenkinsDialog = () => {
        const { openDialog } = this.state;
        return (
            <AddJenkinsJobDialog
                open={openDialog === 'AddJenkins'}
                title={'Add Jenkins Job'}
                TitleIconObject={<CardMedia
                    style={{ height: 30, width: 30 }}
                    image={`${process.env.PUBLIC_URL}/images/jenkins_icon.png`}
                    title="Logo"
                />}
                onClose={this.closeDialog}
                onAdd={this.onAddJenkinsJob}
            />
        );
    };
    private onAddJenkinsJob = async (name: string, username: string, password: string, url: string, jobName: string) => {
        await actions.devOps.addJenkinsJob(name, username, password, url, jobName);
    }

    private openAddUnityCloudDialog = () => this.setState({ createAnchorEl: undefined, openDialog: 'AddCloudBuild' });
    private renderAddUnityCloudDialog = () => {
        const { openDialog } = this.state;
        return (
            <AddUnityCloudJobDialog
                open={openDialog === 'AddCloudBuild'}
                title={'Add Unity Cloud Job'}
                TitleIconObject={<CardMedia
                    style={{ height: 30, width: 30 }}
                    image={`${process.env.PUBLIC_URL}/images/unity_icon.png`}
                    title="Logo"
                />}
                onClose={this.closeDialog}
                onAdd={this.onAddUnityCloudJob}
            />
        );
    };
    private onAddUnityCloudJob = async (name: string, apiKey: string, orgId: string, projectId: string, targetId: string) => {
        await actions.devOps.addUnityCloudJob(name, apiKey, orgId, projectId, targetId);
    }

    private openEditDialog = (job: Entities.CloudBuildJob) => this.setState({ openDialog: 'Edit', selectedJob: job });
    private renderEditDialog = () => {
        const { openDialog, selectedJob } = this.state;
        if (!selectedJob) {
            return;
        }
        return (
            <EditJobDialog
                open={openDialog === 'Edit'}
                title={'Edit Job'}
                job={selectedJob}
                onClose={this.closeDialog}
                onEdit={this.onEdit}
            />
        );
    };
    private onEdit = async (id: string, name: string) => {
        await actions.devOps.editJob(id, name);
    };

    private openDeleteDialog = (job: Entities.CloudBuildJob) => this.setState({ openDialog: 'Delete', selectedJob: job });
    private renderDeleteDialog = () => {
        const { openDialog, selectedJob } = this.state;
        if (!selectedJob) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={`Delete Job`}
                content={`This will permanently delete the job '${selectedJob.name}'.`}
                onClose={this.closeDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        const { selectedJob } = this.state;
        if (!selectedJob) {
            return;
        }
        this.closeDialog();
        switch (selectedJob.type) {
            case 'Jenkins':
                await actions.devOps.removeJenkinsJob(selectedJob.id);
                break;
            case 'UnityCloud':
                await actions.devOps.removeUnityCloudJob(selectedJob.id);
                break;
        }
    };

    private closeDialog = () => this.setState({
        openDialog: null,
        selectedJob: null,
    });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    jobs: state.devOps.jobs,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
