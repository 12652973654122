import * as React from 'react';
import { Select as MuiSelect, OutlinedInput, SelectChangeEvent, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
    readonly value: string;
    readonly labelId?: string;
    readonly children: React.ReactNode;
    readonly onChange?: (value: string) => void;
    readonly sx?: SxProps<Theme>;
}

export class TextSelect extends React.PureComponent<Props> {
    render() {
        return (
            <MuiSelect
                {...this.props}
                onChange={this.onChange}
                native={true}
                fullWidth={true}
                input={<OutlinedInput style={{ border: 'none'}}/>}
                variant={'outlined'}
            >
                {this.props.children}
            </MuiSelect>
        );
    }

    private onChange = (event: SelectChangeEvent<string>) => this.props.onChange && this.props.onChange(event.target.value.toString());
}