import React from 'react';
import * as _ from 'lodash';
import { AceEditor } from './AceEditor';
import { Code, List } from '@mui/icons-material';
import { Button } from './input';
import { formatJSON, isJson, parseJson } from '../../utils';
import { MTableHeader, MTableBody } from 'material-table';
import { Paper } from '@mui/material';
import { Table } from './Table';
import { styled } from '@mui/styles';
import { JsonDialog } from './dialog';

interface Props {
    readonly text: string;
    readonly style?: React.CSSProperties;
    readonly keyValueContainerStyle?: React.CSSProperties;
    readonly jsonContainerStyle?: React.CSSProperties;
    readonly readOnly?: boolean;
    readonly showBox?: boolean;
    readonly onChange?: (text: string) => void;
}

type Type = 'EditAsKeyValue' | 'EditAsJson';
type DialogType = 'Metadata';
interface State {
    readonly text: string;
    readonly type: Type;
    readonly openDialog: DialogType | null;
    readonly metadataSelected: string;
}

export class Metadata extends React.Component<Props, State> {
    state: State = {
        text: '',
        type: 'EditAsKeyValue',
        openDialog: null,
        metadataSelected: '',
    };

    componentDidMount() {
        const { text } = this.props;
        this.setState({ text });
    }

    componentDidUpdate(prevProps: Props) {
        const { text } = this.props;
        if ( prevProps.text === text ) {
            return;
        }

        this.setState({ text });
    }


    render() {
        const { type } = this.state;
        switch (type) {
            case 'EditAsKeyValue':
                return this.renderEditAsKeyValue();
            case 'EditAsJson':
                return this.renderEditAsJson();
            default:
                return <></>;
        }
    }

    renderEditAsKeyValue() {
        const { style, readOnly, keyValueContainerStyle, showBox } = this.props;
        const columns = [
            { title: 'Key', field: 'key'},
            { title: 'Value', field: 'value'},
        ];
        const data = this.convertTextToData();

        return (
            <div style={{ width: '100%', ...style }}>
                <Table
                    data={data}
                    columns={columns}
                    showBox={showBox}
                    ignoreSmall={true}
                    options={{
                        showTitle: false,
                        paging: false,
                        emptyRowsWhenPaging: false,
                        search: false,
                        sorting: false,
                        draggable: false,
                    }}
                    components={{
                        Container: props => (
                            <Paper {...props} style={{ boxShadow: 'none', borderRadius: 8, ...keyValueContainerStyle }}/>
                        ),
                    }}
                    actions={ readOnly? undefined: [
                        {
                            tooltip: 'Edit as json',
                            position: 'toolbar',
                            icon: Code,
                            onClick: this.changeEditAsJsonType,
                            disabled: readOnly,
                        },
                    ]}
                    editable={ readOnly? undefined : {
                        onRowAdd: newData =>
                            new Promise((resolve) => {
                                const changedData = [...data, newData];
                                this.handleChangeData(changedData);
                                resolve(changedData);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                const dataUpdate = [...data];
                                const index = oldData.tableData.id;
                                dataUpdate[index] = newData;
                                const changedData = [...dataUpdate];
                                this.handleChangeData(changedData);
                                resolve(changedData);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve) => {
                                const dataDelete = [...data];
                                const index = oldData.tableData.id;
                                dataDelete.splice(index, 1);
                                const changedData = [...dataDelete];
                                this.handleChangeData(changedData);
                                resolve(changedData);
                            }),
                    }}
                />
                {this.renderMetadataDialog()}
            </div>
        );
    }

    renderEditAsJson() {
        const { readOnly, jsonContainerStyle } = this.props;
        let { text } = this.state;
        if (text === '') {
            text = '{\n\t\n}';
        }
        text = formatJSON(text);

        return (
            <>
                {!readOnly && (
                    <StyledButton
                        text="Edit as key/value pairs"
                        icon={List}
                        onClick={this.changeEditAsKeyValueType}
                    />
                )}
                <AceEditor
                    mode="json"
                    value={text}
                    onChange={this.handleChange}
                    height={'150px'}
                    style={{border: 'thin solid rgba(0, 0, 0, 0.23)', width: '100%', ...jsonContainerStyle}}
                />
            </>
        );
    }

    private convertTextToData = () => {
        const { text } = this.state;
        let json: Record<string, any> = {};
        if (isJson(text)) {
            json = JSON.parse(text);
        }
        const data: any[] = [];
        Object.keys(json).forEach( (key: string) => {
            const value = json[key];
            if (_.isNumber(value) || _.isString(value)) {
                data.push({ key, value });
            } else {
                try {
                    const jsonValue = JSON.stringify(value, null, 2);
                    data.push({ key, value: parseJson(jsonValue, this.onMetadataSelected) });
                } catch (e) {
                    console.error(e);
                }
            }
        });
        return data;
    };

    private changeEditAsKeyValueType = () => this.setState({ type: 'EditAsKeyValue' });

    private changeEditAsJsonType = () => this.setState({ type: 'EditAsJson' });

    private handleChangeData = (data: any[]) => {
        if (!this.props.onChange) {
            return;
        }
        const records: Record<string, any> = {};
        data.forEach( d => {
           records[d.key] = d.value;
        });
        let text = JSON.stringify(records);
        text = text.trim();
        this.setState({ text });
        this.props.onChange(text);
    };

    private handleChange = (text: string) => {
        if (!this.props.onChange) {
            return;
        }
        text = text.trim();
        this.setState({ text });
        this.props.onChange(text);
    }

    private onMetadataSelected = (metadataSelected: string) => {
        if (metadataSelected !== '') {
            this.setState({ openDialog: 'Metadata', metadataSelected });
        }
    };
    private renderMetadataDialog = () => {
        const { openDialog, metadataSelected } = this.state;
        return (
            <JsonDialog
                open={openDialog === 'Metadata'}
                title="Metadata"
                value={metadataSelected}
                onClose={this.closeDialog}
            />
        );
    };

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            metadataSelected: '',
        });
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    font: '.866em/1.425 "Open Sans Semibold",Helvetica,Arial,sans-serif',
    padding: '6px 10px',
}));
