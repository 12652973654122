import * as React from 'react';
import { TextField, Button, NumberField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';

interface Props extends BaseDialogProps {
    readonly open: boolean;
    readonly achievement: Entities.Achievement;
    readonly onClose: () => void;
    readonly onEdit: (achievement: Entities.Achievement) => void;
}

interface State extends BaseDialogState {
    readonly metadataId: string;
    readonly appleId: string;
    readonly googleId: string;
    readonly prizes: Entities.AchievementPrize[];
}

export class EditAchievementDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.achievement.id !== prevProps.achievement.id) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { achievement } = this.props;
        const { metadataId, appleId, googleId, prizes } = this.state;
        const prizeElements: React.JSX.Element[] = [];
        prizes.forEach( (prize, prizeIndex) => prizeElements.push(this.renderPrize(prize, prizeIndex)));

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        readOnly={true}
                        value={achievement.id}
                        label=""
                        required={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Metadata Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={metadataId}
                        label=""
                        required={true}
                        onChange={this.updateMetadataId}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Apple Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={appleId}
                        label=""
                        required={true}
                        onChange={this.updateAppleId}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Google Id</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={googleId}
                        label=""
                        required={true}
                        onChange={this.updateGoogleId}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12} style={{ padding: '8px 0px'}}/>
                <Grid size={12}>
                    <Grid container={true} spacing={0}>
                        <Grid size={12}>
                            <strong>PRIZES</strong>
                        </Grid>
                        <Grid size={12}>
                            <Grid container={true} spacing={1} style={{backgroundColor: 'white', margin: 0, width: '100%'}}>
                                <>
                                    {prizes.length === 0 && (
                                        <div style={{padding: 10}}>
                                            No prizes
                                        </div>
                                    )}
                                    {prizeElements}
                                </>
                            </Grid>
                        </Grid>
                        <Grid size={12}>
                            <StyledButton text="Add Prize" icon={AddOutlined} variant="contained" onClick={this.onAddReward} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onUpdate} variant="text" color="secondary" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        const { achievement } = this.props;
        if (!achievement) {
            return {
                metadataId: '',
                appleId: '',
                googleId: '',
                prizes: [],
            };
        }
        return {
            ...achievement,
        };
    }

    private onAddReward = () => {
        const { prizes } = this.state;
        prizes.push({
            id: 0,
            achievementId: '',
            key: '',
            amount: 0,
        });
        this.setState({ prizes });
    };

    private renderPrize = (prize: Entities.AchievementPrize, prizeIndex: number) => {
        let { prizes } = this.state;

        const onKeyChange = (key: string) => {
            prizes[prizeIndex].key = key;
            this.setState({ prizes });
        };

        const onAmountChange = (amount: number) => {
            prizes[prizeIndex].amount = amount;
            this.setState({ prizes });
        };

        const onRemovePrize = () => {
            prizes = prizes.filter((value, i) => i !== prizeIndex);
            this.setState({ prizes });
        };

        return (
            <Grid size={12}>
                <Grid container={true} spacing={0} style={{borderBottom: '1px solid rgb(233, 233, 233)', margin: 0, width: '100%'}}>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <InputLabel>Key</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={prize.key}
                            onChange={onKeyChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                        <InputLabel>Amount</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <NumberField
                            fullWidth={true}
                            label=""
                            value={prize.amount}
                            onChange={onAmountChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid size={12} style={{textAlign: 'end'}}>
                        <StyledButton text="Remove Prize" icon={DeleteOutlined} onClick={onRemovePrize} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private updateMetadataId = (metadataId: string) => this.setState({metadataId});

    private updateAppleId = (appleId: string) => this.setState({appleId});

    private updateGoogleId = (googleId: string) => this.setState({googleId});

    private validate(): boolean {
        const { achievement } = this.props;
        const { metadataId, appleId, googleId, prizes } = this.state;
        return !(achievement.metadataId === metadataId
            && achievement.appleId === appleId
            && achievement.googleId === googleId
            && achievement.prizes === prizes
        );
    }

    private onUpdate = () => {
        const { achievement } = this.props;
        const { metadataId, appleId, googleId, prizes } = this.state;
        if (!this.validate()) {
            return;
        }
        achievement.metadataId = metadataId;
        achievement.appleId = appleId;
        achievement.googleId = googleId;
        achievement.prizes = prizes;
        this.props.onEdit(achievement);
        this.onClose();
    };
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    fontWeight: 'normal',
    textTransform: 'none',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    }
}));
