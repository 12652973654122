import * as React from 'react';
import { TextField, Button, NumberField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onCreate: (name: string, key: string, amount: number) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly key: string;
    readonly amount: number;
}

export class CreateCompensationPreDefineItemDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { name, key, amount } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Display name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Key</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        value={key}
                        variant="outlined"
                        required={true}
                        onChange={this.updateKey}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Amount</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={amount}
                        variant="outlined"
                        required={true}
                        onChange={this.updateAmount}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="contained" disabled={!this.validate()} />
        );
    }

    private updateName = (name: string) => this.setState({ name });
    private updateKey = (key: string) => this.setState({ key });
    private updateAmount = (amount: number) => this.setState({ amount });

    private validate(): boolean {
        const { name, key, amount } = this.state;
        return name !== '' && key !== '' && amount > 0;
    }

    private onCreate = () => {
        const { name, key, amount } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onCreate(name, key, amount);
        this.onClose();
    };

    protected initState(): State {
        return {
            name: '',
            key: '',
            amount: 0,
        };
    }
}
