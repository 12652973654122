import * as React from 'react';
import { formatBytes, formatDate, formatJSON, isJson, parseJson } from '../utils';
import {
    AlertDialog,
    BaseElement,
    BasePlayerContainer,
    WarningDialog,
    EditorDialog,
    ImportJsonDialog,
    Table,
} from '../components';
import {
    AddOutlined,
    CloudDownloadOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
} from '@mui/icons-material';
import actions, { ActionRequest } from '../actions';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './index';
import { UserTitleRole } from '../enums';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly playerId?: string;
}

type DialogType = 'CreateData' | 'EditData' | 'Export' | 'Import' | 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedData: Entities.PlayerData | null;
}

interface TableCell {
    key: string;
    value: any;
    lastUpdated: string;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedData: null,
    };

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Player Data"}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderEditDialog()}
                {this.renderCreateNewDialog()}
                {this.renderExportDialog()}
                {this.renderImportDialog()}
                {this.renderDeletePlayerDataDialog()}
            </>
        );
    }

    private renderContent() {
        const { userTitleRole } = this.props;

        const columns = [
            { title: 'Key', field: 'key'},
            { title: 'Value', field: 'value'},
            { title: 'Last Updated', field: 'lastUpdated'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                onRowClick={this.onRowClick}
                options={{
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: true,
                    draggable: false,
                }}
                actions={[
                    {
                        tooltip: 'New',
                        position: 'toolbar',
                        icon: AddOutlined,
                        hidden: userTitleRole === UserTitleRole.Viewer,
                        onClick: this.openCreateNewDialog,
                    },
                    {
                        tooltip: 'Upload',
                        position: 'toolbar',
                        icon: CloudUploadOutlined,
                        hidden: userTitleRole === UserTitleRole.Viewer,
                        onClick: this.openImportDialog,
                    },
                    {
                        tooltip: 'Download',
                        position: 'toolbar',
                        icon: CloudDownloadOutlined,
                        hidden: userTitleRole === UserTitleRole.Viewer,
                        onClick: this.openExportDialog,
                    },
                    {
                        tooltip: 'Remove All',
                        position: 'toolbar',
                        icon: DeleteOutlined,
                        hidden: userTitleRole === UserTitleRole.Viewer,
                        onClick: this.onDeleteClick,
                    }
                ]}
            />
        );
    }

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { playerId } = this.props;
        if (!playerId) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`player/${playerId}/playerData/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.PlayerData) => {
            return {
                entity: e,
                key: e.key,
                value: parseJson(e.value),
                lastUpdated: formatDate(e.lastUpdated),
                size: this.getPlayerDataSize(e)
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private getPlayerDataSize = (playerData: Entities.PlayerData) : number  => {
        if(isJson(playerData.value)) {
            return  new TextEncoder().encode(JSON.stringify(playerData.value)).length;
        }

        return 0;
    };

    private closeDialog = () => this.setState({ openDialog: null, selectedData: null });

    private onRowClick = (event: any, rowData: any) => {
        const selectedData = rowData.entity;
        if (!selectedData) {
            return;
        }
        this.setState({ openDialog: 'EditData', selectedData });
    };

    private renderEditDialog = () => {
        const { openDialog, selectedData } = this.state;
        const { key, value } = selectedData || { key: '', value: ''};
        return (
            <EditorDialog
                open={openDialog === 'EditData'}
                title="Edit Player Data"
                id={key}
                value={formatJSON(value)}
                onClose={this.closeDialog}
                onEdit={this.updatePlayerData}
                onDelete={this.deletePlayerData}
            />
        );
    }

    private openCreateNewDialog = () => this.setState({ openDialog: 'CreateData' });
    private renderCreateNewDialog = () => {
        const { openDialog } = this.state;
        return (
            <EditorDialog
                open={openDialog === 'CreateData'}
                title="Create Player Data"
                id={''}
                value={''}
                create={true}
                onClose={this.closeDialog}
                onEdit={this.updatePlayerData}
            />
        );
    };

    private openImportDialog = () => this.setState({ openDialog: 'Import' });
    private renderImportDialog = () => {
        const { openDialog } = this.state;
        return (
            <ImportJsonDialog
                open={openDialog === 'Import'}
                title="Upload JSON"
                content="Upload player data from JSON file."
                dropzoneText={'Drop exported player data.'}
                onClose={this.closeDialog}
                onImport={this.importPlayerData}
            />
        );
    };

    private importPlayerData = async (data: string) => {
        const { playerId } = this.props;
        this.closeDialog();
        if (!playerId) {
            return;
        }
        await actions.player.importPlayerData(playerId, data);
    };

    private renderDeletePlayerDataDialog = () => {
        const { openDialog } = this.state;

        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={'Delete Player Data'}
                content="This will permanently delete all player datas."
                onClose={this.closeDialog}
                onSubmit={this.onDeletePlayerData}
                maxWidth={'xs'}
            />
        );
    };
    private onDeletePlayerData = async () => {
        const { playerId } = this.props;
        if(!playerId) {
            return;
        }
        await actions.player.deleteAllPlayerData(playerId);
    };
    private onDeleteClick = () => {
        this.setState({openDialog: 'Delete'});
    };

    private updatePlayerData = async (key: string, value: string) => {
        const { playerId } = this.props;
        if (!playerId) {
            return;
        }
        await actions.player.updatePlayerData(playerId, key, value);
    };

    private deletePlayerData = async (key: string) => {
        this.closeDialog();
        const { playerId } = this.props;
        if (!playerId) {
            return;
        }
        await actions.player.deletePlayerData(playerId, key);
    };

    private openExportDialog = () => this.setState({ openDialog: 'Export' });
    private renderExportDialog = () => {
        const { openDialog } = this.state;
        return (
            <AlertDialog
                open={openDialog === 'Export'}
                title="Export Player Data"
                content="Export the player data?"
                onClose={this.closeDialog}
                submitButtonText={'Export'}
                onSubmit={this.exportPlayerData}
            />
        );
    };

    private exportPlayerData = async () => {
        this.closeDialog();
        const { playerId } = this.props;
        if (!playerId) {
            return;
        }
        await actions.player.exportPlayerData(playerId);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
