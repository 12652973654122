import React from 'react';
import { formatDate } from '../../utils';
import axios, { AxiosRequestConfig } from 'axios';
import { AppBar, AppBarProps, Grid2 as Grid, Tab, Tabs } from '@mui/material';
import { default as moment } from 'moment';
import { LogsAceEditor, Table } from '../common';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { CloudBuildReport } from './CloudBuildReport';
import { styled } from '@mui/styles';

interface Props extends ThemeModeProps {
    readonly job: Entities.CloudBuildJobDetails;
    readonly cloudBuildId: string;
    readonly buildNumber: string;
}

interface State {
    readonly tagIndex: number;
}

export class CloudBuildDetails extends React.Component<Props, State> {
    state: State = {
        tagIndex: 0,
    };

    render() {
        const { themeMode, job } = this.props;
        const { cloudBuildId, buildNumber } = this.props;
        const { tagIndex } = this.state;

        const reportArtifact = job.artifacts.find(a => a.name.includes(".json"));
        const changesColumns = [
            { title: 'Rev #', field: 'revision'},
            { title: 'Timestamp', field: 'timestamp'},
            { title: 'User', field: 'user'},
            { title: 'File changes', field: 'changes'},
        ];

        const changesData = job.changeSet ? job.changeSet.map(item => {
            const revision = (
                <div>
                    {item.commitId}
                    <div style={{
                        backgroundColor: themeMode === 'light' ? '#d3e3fd' : '#292f36',
                        padding: '2px 8px',
                        margin: '8px 0px',
                        borderRadius: 2,
                        color: themeMode === 'light' ? '#0943a0' : '#7cacf8',
                        fontSize: '0.75rem',
                    }}>
                        <b>Notes: </b>{item.comment}
                    </div>
                </div>
            );

            return {
                revision,
                timestamp: formatDate(item.timestamp),
                user: item.user,
                changes: `${item.changesCount} file changed`,
            };
        }) : [];

        const artifactsColumns = [
            { title: 'File', field: 'fileName'},
        ];

        const artifactsData = job.artifacts.map(artifact => {
            return {
                ...artifact,
                fileName: artifact.name,
                size: '',
                created: '',
            };
        });

        const onDownloadArchive = async (artifact: any) => {
            try {
                const config: AxiosRequestConfig = {
                    url: artifact.file.href,
                    method: 'GET',
                    responseType: 'blob',
                    auth: {
                        username: "DevOps",
                        password: "6Trainstation"
                    },
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': 'true',
                        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                        'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
                    },
                };

                const response = await axios.request(config);

                if (!response) {
                    return;
                }

                const file = new Blob([response.data], {type: response.headers['content-type']});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file);
                link.download = artifact.fileName;
                link.click();
            } catch (error) {
                console.error(error);
            }
        };

        return (
            <div style={{
                width: '100%',
                boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                    : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                borderRadius: 8,
                backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                border: 'none',
                boxSizing: 'border-box',
                padding: 0,
            }}>
                <AppBarWithStyle themeMode={themeMode} position="static">
                    <Tabs
                        value={tagIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        allowScrollButtonsMobile={true}
                        scrollButtons="auto"
                    >
                        <Tab label="Overview" />
                        <Tab label="Logs" />
                        <Tab label={`Changes (${job.changeSet.length})`} />
                        <Tab label={`Artifacts (${job.artifacts.length})`} />
                        {reportArtifact && (
                            <Tab label="Report" />
                        )}
                    </Tabs>
                </AppBarWithStyle>
                <TabPanel themeMode={themeMode} value={tagIndex} index={0}>
                    <div style={{ padding: 20 }}>
                        <Grid container={true} spacing={1}>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Result:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{job.result}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Build target:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{job.displayName}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Started:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{formatDate(job.timestamp)}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Build time:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{moment.duration(job.duration).humanize()}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Branch:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{job.branch}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Last commit:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{job.commit}</PSubtitle>
                            </Grid>
                            <Grid size={{ xs:4, sm:3, md:2 }}>
                                <PTitle themeMode={themeMode}>Clean build:</PTitle>
                            </Grid>
                            <Grid size={{ xs:8, sm:9, md:10 }}>
                                <PSubtitle themeMode={themeMode}>{job.cleanBuild ? 'TRUE' : 'FALSE'}</PSubtitle>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>
                <TabPanel themeMode={themeMode} value={tagIndex} index={1}>
                    <LogsAceEditor
                        themeMode={themeMode}
                        url={`devOps/cloudBuild/${cloudBuildId}/${buildNumber}/getLogs`}
                        mode="xml"
                        value={""}
                        showPrintMargin={false}
                        showGutter={false}
                        highlightActiveLine={false}
                        height={`${screen.height-320}px`}
                        fontSize={14}
                        lineHeight={20}
                        setOptions={{
                            showLineNumbers: false,
                            tabSize: 2,
                        }}
                        showGoTo={true}
                    />
                </TabPanel>
                <TabPanel themeMode={themeMode} value={tagIndex} index={2}>
                    <div style={{ padding: 0 }}>
                        <Table
                            data={changesData}
                            columns={changesColumns}
                            showBox={false}
                            options={{
                                showTitle: false,
                                selection: false,
                                paging: false,
                                search: false,
                                sorting: false,
                                draggable: false,
                                headerStyle: {
                                    height: 30,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                },
                            }}
                        />
                    </div>
                </TabPanel>
                <TabPanel themeMode={themeMode} value={tagIndex} index={3}>
                    <div style={{ padding: 0 }}>
                        <Table
                            data={artifactsData}
                            columns={artifactsColumns}
                            showBox={false}
                            options={{
                                showTitle: false,
                                selection: false,
                                paging: false,
                                search: false,
                                sorting: false,
                                draggable: false,
                                exportButton: false,
                                headerStyle: {
                                    height: 30,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    color: 'rgba(0, 0, 0, 0.54)',
                                },
                                actionsColumnIndex: -1,
                            }}
                            actions={[
                                rowData => ({
                                    tooltip: 'Download',
                                    position: 'row',
                                    icon: CloudDownloadOutlined,
                                    onClick: () => onDownloadArchive(rowData)
                                }),
                            ]}
                        />
                    </div>
                </TabPanel>
                {reportArtifact && (
                    <TabPanel themeMode={themeMode} value={tagIndex} index={4}>
                        <CloudBuildReport
                            themeMode={themeMode}
                            cloudBuildId={cloudBuildId}
                            buildNumber={buildNumber}
                            reportArtifact={reportArtifact}
                        />
                    </TabPanel>
                )}
            </div>
        );
    }

    private handleChange = (event: any, newValue: any) => this.setState({ tagIndex: newValue });
}

interface TabPanelProps extends ThemeModeProps{
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const hidden = value !== index;
    if (hidden) {
        return (<></>);
    }

    return (
        <div
            role="cloud-build-details"
            id={`cloud-build-details-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            style={{ color: props.themeMode === 'light' ? '#404040' : '#adadad' }}
            {...other}
        >
            {children}
        </div>
    );
}

interface AppBarWithStyleProps extends AppBarProps, ThemeModeProps {}
const AppBarWithStyle = styled((props: AppBarWithStyleProps) => (
    <AppBar
        {...props}
        sx={{
            '&.MuiAppBar-root': {
                minHeight: 40,
                margin: 0,
            },
            '& .MuiAppBar-flexContainer': {
                display: 'box',
            },
            '& .MuiAppBar-indicator': {
                display: 'contents',
                margin: '0px 10px',
                backgroundColor: props.themeMode === 'light' ? '#1c73e8' : '#5594f2',
            },
        }}
    >
        {props.children}
    </AppBar>
))(() => ({}));

interface StyledProps extends ThemeModeProps {
    children?: React.ReactNode;
}
const PTitle = styled((props: StyledProps) => (
    <div
        style={{
            color: props.themeMode === 'light' ? '#404040' : '#adadad',
            fontWeight: 600,
            fontSize: '0.8rem',
            lineHeight: '1.25rem',
            letterSpacing: '-0.006rem',
            padding: 0,
            margin: 0,
        }}
    >
        {props.children}
    </div>
))(() => ({}));

const PSubtitle = styled((props: StyledProps) => (
    <div
        style={{
            color: props.themeMode === 'light' ? '#404040' : '#adadad',
            fontWeight: 400,
            fontSize: '0.8rem',
            lineHeight: '1.25rem',
            letterSpacing: '-0.006rem',
            padding: 0,
            margin: 0,
        }}
    >
        {props.children}
    </div>
))(() => ({}));
