import * as React from 'react';
import { TextField, Button, NumberField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly selected: Entities.CompensationPreDefineItem;
    readonly onUpdate: (id: string, name: string, key: string, amount: number) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly key: string;
    readonly amount: number;
}

export class UpdateCompensationPreDefineItemDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.selected.id !== prevProps.selected.id) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const { name, key, amount } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Display name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        variant="outlined"
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Key</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        value={key}
                        variant="outlined"
                        required={true}
                        onChange={this.updateKey}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Amount</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={amount}
                        variant="outlined"
                        required={true}
                        onChange={this.updateAmount}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onCreate} variant="contained" disabled={!this.validate()} />
        );
    }

    private updateName = (name: string) => this.setState({ name });
    private updateKey = (key: string) => this.setState({ key });
    private updateAmount = (amount: number) => this.setState({ amount });

    private validate(): boolean {
        const { name, key, amount } = this.state;
        return name !== '' && key !== '' && amount > 0;
    }

    private onCreate = () => {
        const { selected } = this.props;
        const { name, key, amount } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onUpdate(selected.id, name, key, amount);
        this.onClose();
    };

    protected initState(): State {
        const { selected } = this.props;
        if (!selected) {
            return {
                name: '',
                key: '',
                amount: 0,
            };
        }

        return {
            name: selected.name,
            key: selected.key,
            amount: selected.amount,
        };
    }
}
