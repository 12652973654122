import React from 'react';
import {
    MobileDatePicker,
    DesktopDatePicker,
    PickerChangeHandlerContext,
    DateTimeValidationError,
} from '@mui/x-date-pickers';
import { Hidden } from '@mui/material';
import { Moment } from 'moment';

interface Props {
    readonly value: Moment;
    readonly onChange: (from: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void;
    readonly disablePast?: boolean;
}

export class DatePicker extends React.Component<Props> {
    render() {
        const { value, onChange, disablePast } = this.props;
        return (
            <>
                <Hidden smUp={true}>
                    <MobileDatePicker value={value} onChange={onChange} disablePast={disablePast} />
                </Hidden>
                <Hidden smDown={true}>
                    <DesktopDatePicker value={value} onChange={onChange} disablePast={disablePast} />
                </Hidden>
            </>
        );
    }
}