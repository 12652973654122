const KEY = 'storage';

export class LocalStorage {
    static get(): Entities.LocalStorage {
        const str = localStorage.getItem(KEY);
        const obj = str ? JSON.parse(str) : {};
        return {
            auth: {
                sessionId: '',
                ...obj.auth,
            },
            google: {
                id: '',
                picture: '',
                ...obj.google,
            },
            appContainer: {
                showDrawer: true,
                smDrawerOpen: true,
                themeMode: 'light',
                ...obj.appContainer,
            },
            title: {
                ...obj.title
            },
        };
    }

    static set(key: keyof Entities.LocalStorage, data: Partial<Entities.LocalStorage[keyof Entities.LocalStorage]>): void {
        const obj = this.get();
        localStorage.setItem(
            KEY,
            JSON.stringify({
                ...obj,
                [key]: {
                    ...obj[key],
                    ...data,
                },
            }),
        );
    }
}
