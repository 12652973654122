import * as React from 'react';
import actions from '../actions';
import { connect } from 'react-redux';
import { BaseContainer, BaseElement, CreateTitleDialog, TitleCard } from '../components';
import { AddOutlined } from '@mui/icons-material';
import { ContainerContext, mapProps } from './index';
import { Card, CardActionArea, CardActionAreaProps, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { UserRole } from '../enums';
import { StateMap, TitleState } from '../reducers';
import { styled } from '@mui/system';

interface Props extends ContainerContext.Props {
    readonly title: TitleState;
}

type DialogType = 'New';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedTitle: Entities.Title | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        openDialog: null,
        selectedTitle: null,
    };

    async componentDidMount() {
        actions.title.resetTitle();
        await actions.title.list();
    }

    protected renderContainer(): React.JSX.Element {
        return (
            <BaseContainer
                loading={this.props.loading}
                themeMode={this.props.app.themeMode}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderNewDialog()}
            </>
        );
    }

    private renderContent = () => {
        return (
            <div style={{ maxWidth: 960, margin: '20px auto' }}>
                <Grid container={true} alignItems="center" spacing={4}>
                    {this.renderTitles()}
                </Grid>
            </div>
        );
    };

    private renderTitles = () => {
        const { title, app, userRole } = this.props;
        let { titles } = title;
        const { themeMode } = app;
        if (!titles) {
            return;
        }
        const titleViews: React.JSX.Element[] = [];
        titles = [...titles].sort((t1, t2) => t2.name > t1.name ? -1 : t2.name < t1.name ? 1 : 0);
        if (userRole === UserRole.Admin) {
            titleViews.push(
                <Grid size={{ xs: 12, sm: 6 }}>
                    <Card
                        key={`add_new`}
                        style={{
                            boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                                : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                            borderRadius: 8,
                            backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                            width: '100%',
                            padding: 0,
                        }}
                    >
                        <StyledCardActionArea themeMode={themeMode} onClick={this.openNewDialog} >
                            <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <AddOutlined style={{width: 36, height: 36, color: themeMode === 'light' ? '#1a73e8' : '#7cacf8'}}/>
                                <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 500, color: themeMode === 'light' ? '#1a73e8' : '#7cacf8', marginTop: 10 }}>
                                    Add project
                                </Typography>
                            </CardContent>
                        </StyledCardActionArea>
                    </Card>
                </Grid>
            );
        }
        titles.forEach( (title: Entities.UserTitleData) => {
            titleViews.push(
                <Grid size={{ xs: 12, sm: 6 }}>
                    <TitleCard
                        themeMode={themeMode}
                        title={title}
                        onClick={this.openTitleSelect}
                    />
                </Grid>
            );
        });
        return titleViews;
    };

    private openTitleSelect = (title: Entities.UserTitleData) => {
        actions.title.setTitle(title);
        const { navigate } = this.props;
        if (navigate === null || navigate === undefined) {
            return;
        }
        navigate(`/${title.id}/dashboard`)
    };

    private renderNewDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateTitleDialog
                open={openDialog === 'New'}
                title={'Create Title'}
                onCreate={this.onCreateTitle}
                onClose={this.closeDialog}
            />
        );
    };

    private openNewDialog = () => this.setState({ openDialog: 'New' });

    private onCreateTitle = async (name: string, apiUrl: string, apiSecretKey: string) => {
        this.closeDialog();
        await actions.title.create(name, apiUrl, apiSecretKey);
    };

    private closeDialog = () => {
        this.setState({ openDialog: null });
    };
}

interface StyledCardActionAreaProps extends CardActionAreaProps, ThemeModeProps {
}
const StyledCardActionArea = styled((props: StyledCardActionAreaProps) => (
    <CardActionArea
        {...props}
        sx={{
            '&.MuiCardActionArea-root': {
                padding: '0px !important',
                height: 126,
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
                '&$selected': {
                    backgroundColor: props.themeMode === 'light' ? '#f0f0f0' : '#2e2e2e',
                },
            }
        }}
    >
        {props.children}
    </CardActionArea>
))(() => ({}));

const mapStateToProps = (state: StateMap): Props => {
    return {
        ...mapProps(state),
        title: state.title,
    };
};
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
