import * as React from 'react';
import { Button, Dropzone } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly onCreate: (file: any) => void;
}

interface State extends BaseDialogState {
    readonly file: File | null;
}

export class CreateNewFileVersionItemDialog extends BaseDialog<Props, State> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>File</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Dropzone
                        multiple={false}
                        text={'Drop file'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="contained" disabled={!this.validateFile()}/>
        );
    }

    protected initState(): State {
        return {
            file: null,
        };
    }

    private handleDrop = (file: File) => this.setState({file});

    private onCreate = () => {
        if (!this.validateFile()) {
            return;
        }
        const { file } = this.state;
        this.props.onCreate(file);
        this.onClose();
    };

    private validateFile(): boolean {
        const { file } = this.state;
        return file !== null;
    }
}
