import * as React from 'react';
import { connect } from 'react-redux';
import {
    WarningDialog,
    BaseContainer,
    CreateSegmentDialog,
    UpdateSegmentDialog,
    Table,
    BaseElement,
    HeaderButton,
} from '../components';
import { ContainerContext, mapProps } from './index';
import actions, { ActionRequest } from '../actions';
import {
    AddOutlined,
    CategoryOutlined as CategoryIcon,
    DeleteOutlineOutlined,
    ModeEditOutlineOutlined,
} from '@mui/icons-material';
import { formatDate, localeNumber } from '../utils';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { Chip } from '@mui/material';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Create' | 'Update' | 'ConfirmDelete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedSegment: Entities.SegmentData | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedSegment: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Segments"}
                TitleIcon={CategoryIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderUpdateDialog()}
                {this.renderConfirmDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Created', field: 'created'},
            { title: 'Player Count', field: 'playerCount'},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                actions={
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            rowData => ({
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openUpdateDialog(rowData.segmentData)
                            }),
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.onDeleteClick(rowData.segmentData)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`segment/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.SegmentData) => {
            return {
                id: e.segment.id,
                name:
                    <Chip
                        color={'secondary'}
                        label={e.segment.name}
                        style={{ margin: '2px 4px 2px 0px', backgroundColor: `${e.segment.color}`, color: 'white' }}
                    />,
                created: formatDate(e.segment.created),
                playerCount: localeNumber(e.playerCount),
                segmentData: e,
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateSegmentDialog
                open={openDialog === 'Create'}
                title="Create Segment"
                TitleIcon={CategoryIcon}
                onClose={this.closeDialog}
                onCreate={this.onCreateSegment}
            />
        );
    };
    private onCreateSegment = async (name: string, color: string, rules: Entities.SegmentRule[]) => {
        this.closeDialog();
        await actions.segment.create(name, color, rules);
    };

    private openUpdateDialog = (selectedSegment: Entities.SegmentData) => this.setState({ openDialog: 'Update', selectedSegment });
    private renderUpdateDialog = () => {
        const { openDialog, selectedSegment } = this.state;
        return (
            <UpdateSegmentDialog
                open={openDialog === 'Update'}
                selectedSegment={selectedSegment}
                title="Create Segment"
                TitleIcon={CategoryIcon}
                onClose={this.closeDialog}
                onUpdate={this.onUpdateSegment}
            />
        );
    };
    private onUpdateSegment = async (id: string, name: string, color: string, rules: Entities.SegmentRule[]) => {
        this.closeDialog();
        await actions.segment.update(id, name, color, rules);
    };

    private renderConfirmDeleteDialog = () => {
        const { openDialog, selectedSegment } = this.state;
        if (!selectedSegment) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title={'Delete Segment'}
                content={`Delete '${selectedSegment.segment.name}' segment? This cannot be undone.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteSegments}
                maxWidth={'xs'}
            />
        );
    };
    private deleteSegments = async () => {
        this.closeDialog();
        const { selectedSegment } = this.state;
        if (!selectedSegment) {
            return;
        }
        await actions.segment.remove(selectedSegment.segment.id);
    };

    private closeDialog = () => this.setState({ openDialog: null, selectedSegment: null });

    private onDeleteClick = (selectedSegment: Entities.SegmentData) => this.setState({ openDialog: 'ConfirmDelete', selectedSegment });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
