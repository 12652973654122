import React from 'react';
import { Chip, Grid2 as Grid, InputLabel } from '@mui/material';
import { Button, NumberSelect, TextSelect } from '../common';
import { getVersionsViews } from '../../utils';
import { UserTitleRole } from '../../enums';
import { DeveloperModeOutlined } from '@mui/icons-material';

interface Props {
    readonly clientVersionOverride: Entities.ClientVersionOverride;
    readonly cloudCodeRevisions: Entities.CloudCode[];
    readonly titleDataVersions: Entities.TitleData[];
    readonly contentVersions: Entities.FileVersion[];
    readonly userTitleRole: UserTitleRole;
    readonly onUpdate: (version: string, clientVersionOverride: Entities.ClientVersionOverride) => void;
    readonly onDelete: (version: string, subVersion: string) => void;
}

interface State {
    readonly cloudCodeRevision: number;
    readonly titleDataVersion: string;
    readonly contentVersion: string;
}

export class DeveloperClientVersionOverride extends React.Component<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.clientVersionOverride !== prevProps.clientVersionOverride) {
            this.setState(this.initState());
        }
    }

    render() {
        const { cloudCodeRevisions, titleDataVersions, contentVersions, userTitleRole } = this.props;
        const { cloudCodeRevision, titleDataVersion, contentVersion } = this.state;

        const versionsViews = getVersionsViews(cloudCodeRevisions, titleDataVersions, contentVersions, true);

        return (
            <Grid container={true} spacing={0} >
                <Grid size={{ xs:12 }} >
                    <Chip
                        color={'secondary'}
                        icon={<DeveloperModeOutlined />}
                        size='small'
                        label='Developer Override'
                        style={{
                            backgroundColor: '#21BA47',
                            color: 'white',
                            margin: '2px 0px',
                            borderRadius: 5,
                        }}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Cloud Code</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberSelect
                        value={cloudCodeRevision}
                        onChange={this.handleCloudCodeRevisionChange}
                    >
                        {versionsViews.cloudCodesViews}
                    </NumberSelect>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-duration">Title Data</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextSelect
                        value={titleDataVersion}
                        onChange={this.handleTitleDataVersionChange}
                    >
                        {versionsViews.titleDataVersionsViews}
                    </TextSelect>
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="select-content">Content</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextSelect
                        value={contentVersion}
                        onChange={this.handleContentVersionChange}
                    >
                        {versionsViews.contentVersionsViews}
                    </TextSelect>
                </Grid>
                <Grid size={12} style={{ padding: '8px 0px' }} />
                <Grid size={{ xs:12 }} />
                {userTitleRole > UserTitleRole.Viewer && (
                    <Grid size={12} style={{display: 'flex', gridAutoColumns: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                        <Button text="Delete" onClick={this.onDelete} variant="contained" />
                        <Button text="Update" onClick={this.onUpdate} variant="contained" disabled={this.validate()} />
                    </Grid>
                )}
            </Grid>
        );
    }

    protected handleCloudCodeRevisionChange = (cloudCodeRevision: number) => this.setState({ cloudCodeRevision });

    protected handleTitleDataVersionChange = (titleDataVersion: string) => this.setState({ titleDataVersion });

    protected handleContentVersionChange = (contentVersion: string) => this.setState({ contentVersion });

    private initState(): State {
        const { clientVersionOverride } = this.props;
        if (!clientVersionOverride) {
            return {
                cloudCodeRevision: -1,
                titleDataVersion: "",
                contentVersion: "",
            };
        }

        return {
            cloudCodeRevision: clientVersionOverride.cloudCodeRevision || -1,
            titleDataVersion: clientVersionOverride.titleDataVersion || '',
            contentVersion: clientVersionOverride.contentVersion || '',
        };
    }

    private onUpdate = () => {
        const { clientVersionOverride } = this.props;
        const { cloudCodeRevision, titleDataVersion, contentVersion } = this.state;
        if (this.validate()) {
            return;
        }
        clientVersionOverride.cloudCodeRevision = cloudCodeRevision;
        clientVersionOverride.titleDataVersion = titleDataVersion;
        clientVersionOverride.contentVersion = contentVersion;

        this.props.onUpdate(clientVersionOverride.version, clientVersionOverride);
    };

    private onDelete = () => {
        const { clientVersionOverride } = this.props;
        const { version, subVersion } = clientVersionOverride;
        this.props.onDelete(version, subVersion);
    };

    private validate = () => {
        const { clientVersionOverride } = this.props;
        const { cloudCodeRevision, titleDataVersion, contentVersion } = this.state;
        if( cloudCodeRevision === -1 && titleDataVersion === "" && contentVersion === "" ) {
            return false;
        }

        return clientVersionOverride.cloudCodeRevision === cloudCodeRevision
            && clientVersionOverride.titleDataVersion === titleDataVersion
            && clientVersionOverride.contentVersion === contentVersion;
    };
}
