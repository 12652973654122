import React from 'react';
import { IconButton, InputAdornment, InputAdornmentProps, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { Cancel, Search } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';

interface Props extends ThemeModeProps {
    readonly isRemoteData: boolean;
    readonly searchText: string;
    readonly readOnly?: boolean;
    readonly onSearch: (searchText: string) => void;
}

interface State {
    readonly searchText: string;
    readonly autoFocus: boolean;
}

export class SearchTextField extends React.Component<Props, State> {
    state = {
        searchText: '',
        autoFocus: false,
    };

    componentDidMount() {
        const { searchText } = this.props;
        this.setState({ searchText });
    }

    componentDidUpdate(prevProps: Props) {
        const { searchText } = this.props;
        if ( prevProps.searchText === searchText ) {
            return;
        }

        this.setState({ searchText });
    }

    render() {
        const { themeMode, isRemoteData, readOnly } = this.props;
        const { autoFocus, searchText } = this.state;

        const onSearchChange = (event: any) => {
            const search = event.target.value;
            onSearch(search, true);
        };

        const onSearchReset = () => {
            this.setState(
                { searchText: '', autoFocus: false },
                () => this.props.onSearch(''),
            );
        };

        const onSearch = (search: string, enableAutoFocus: boolean, forceRemote: boolean = false) => {
            this.setState(
                { searchText: search, autoFocus: enableAutoFocus },
                () => {
                    if (isRemoteData && !forceRemote) {
                        return;
                    }
                    this.props.onSearch(search);
                }
            );
        };

        const onKeyPress = (ev: any) => {
            if (ev.key === 'Enter' && isRemoteData && autoFocus) {
                onSearch(searchText, false, true);
            }
        };

        return (
            <StyleOutlinedInput
                themeMode={themeMode}
                autoFocus={autoFocus}
                onChange={onSearchChange}
                value={searchText}
                placeholder="Search"
                readOnly={readOnly}
                startAdornment={
                    <StyleInputAdornment themeMode={themeMode} position="end">
                        <Search fontSize="small" />
                    </StyleInputAdornment>
                }
                endAdornment={
                    <StyleInputAdornment themeMode={themeMode} position="end">
                        <IconButton
                            disabled={!this.state.searchText}
                            onClick={onSearchReset}
                            style={{ color: 'inherit'}}
                        >
                            <Cancel
                                fontSize="small"
                                aria-label="clear"
                            />
                        </IconButton>
                    </StyleInputAdornment>
                }
                onKeyPress={onKeyPress}
            />
        );
    }
}

interface StyleOutlinedInputProps extends OutlinedInputProps, ThemeModeProps {
}
const StyleOutlinedInput = styled((props: StyleOutlinedInputProps) => (
    <OutlinedInput
        {...props}
        sx={{
            '&.MuiOutlinedInput-root': {
                width: '100%',
                padding: 0,
                borderRadius: '8px',
                cursor: 'text',
                height: 32,
                color: props.themeMode === 'light' ? 'rgba(0,0,0,0.5)' : '#ffffff8c',
                backgroundColor: props.themeMode === 'light' ? '#eeeeee' : '#373737',
                boxShadow: 'none',
                border: 'none',
                '&.Mui-focused': {
                    backgroundColor: props.themeMode === 'light' ? '#fafafa' : '#121212',
                    color: props.themeMode === 'light' ? '#0009' : '#a0a0a0',
                    boxShadow: 'inset 0 0 0 2px #7cacf8 !important',
                }
            },
            '& .MuiOutlinedInput-input': {
                color: props.themeMode === 'light' ? '#00000080' : 'rgba(255, 255, 255, 0.9)',
                padding: '5px',
                fontSize: 13,
            },
            '&.MuiOutlinedInput-inputAdornedStart': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff8c',
            },
            '&.MuiOutlinedInput-inputAdornedEnd': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff8c',
            },
            '&.MuiInputAdornment-root': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff8c',
            },
        }}
        />
))(() => ({}));

interface StyleInputAdornmentProps extends InputAdornmentProps, ThemeModeProps {
}
const StyleInputAdornment = styled((props: StyleInputAdornmentProps) => (
    <InputAdornment
        {...props}
        sx={{
            '&.MuiInputAdornment-root': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#ffffff8c',
            },
        }}
    >
        {props.children}
    </InputAdornment>

))(() => ({}));
