import * as React from 'react';
import actions from '../actions';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid2 as Grid,
    InputLabel,
} from '@mui/material';
import {
    BaseElement,
    BaseSettingsContainer,
    Button,
    Dropzone,
    HeaderButton,
    NumberField,
    TextField,
    WarningDialog,
} from '../components';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import { getTitle } from '../actions/title';
import { UserRole, UserTitleRole } from '../enums';
import { Delete, SettingsOutlined as SettingsIcon } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly title?: Entities.Title | null;
    readonly config?: Entities.Config;
}

type DialogType = 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly name: string;
    readonly apiUrl: string;
    readonly apiSecretKey: string;
    readonly file: File | null;
}

class Container extends BaseElement<Props, State> {
    state = this.initState();

    async componentDidMount() {
        getTitle();
        await actions.title.getConfig();
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Delete Title', icon: Delete, color: 'error', onClick: this.openDeleteDialog},
        ];
        return (
            <BaseSettingsContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = "Project settings"
                TitleIcon = {SettingsIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseSettingsContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { config, title, userRole } = this.props;
        if (!config || !title) {
            return;
        }

        const { name, apiUrl, apiSecretKey } = this.state;
        const myslqReplications: React.JSX.Element[] = [];
        if (config.mysqlReplication && config.mysqlReplication.slaves) {
            config.mysqlReplication.slaves.forEach((slave) => {
                myslqReplications.push(
                    <>
                        <Grid size={12}>
                            <InputLabel>Slave Host</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                readOnly={true}
                                label=""
                                value={slave.host}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Slave Database</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                readOnly={true}
                                label=""
                                value={slave.database}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Slave Port</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                readOnly={true}
                                label=""
                                value={slave.port}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Slave Username</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                readOnly={true}
                                label=""
                                value={slave.username}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid size={12}>
                            <InputLabel>Slave Password</InputLabel>
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                fullWidth={true}
                                readOnly={true}
                                label=""
                                value={slave.password}
                                variant="outlined"
                            />
                        </Grid>
                    </>
                );
            });
        }

        return (
            <Grid container={true} spacing={2}>
                {userRole === UserRole.Admin && (
                    <Grid size={12}>
                        <Card key={'Settings'} >
                            <StyledCardHeader title={'Settings'}/>
                            <CardContent>
                                <Grid container={true} spacing={0}>
                                    <Grid size={12}>
                                        <InputLabel>Name</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            value={name}
                                            label=""
                                            required={true}
                                            variant="outlined"
                                            onChange={this.updateName}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Api Url</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            value={apiUrl}
                                            label=""
                                            required={true}
                                            variant="outlined"
                                            onChange={this.updateApiUrl}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Api Secret Key</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            value={apiSecretKey}
                                            label=""
                                            required={true}
                                            variant="outlined"
                                            onChange={this.updateApiSecretKey}
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Logo</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <Dropzone
                                            accept={{
                                                "image/jpeg": ['.jpeg'],
                                                "image/jpg": ['.jpg'],
                                                "image/png": ['.png'],
                                            }}
                                            multiple={false}
                                            preview={title ? title.imageUrl : undefined}
                                            text={'Drop file'}
                                            onDropFile={this.handleDrop}
                                            description={'Upload image (JPEG, JPG or PNG)'}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button text="Update" onClick={this.onUpdate} variant="outlined" disabled={!this.updateEnable()} />
                            </CardActions>
                        </Card>
                    </Grid>
                )}
                <Grid size={12}>
                    <Card key={'general'} >
                        <StyledCardHeader title={'General'} />
                        <CardContent>
                            <Grid container={true} spacing={0}>
                                <Grid size={12}>
                                    <InputLabel>App Name</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.appName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Release Version</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.releaseVersion}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Session Duration</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={`${config.sessionDurationSec} seconds`}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Secret Key</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.secretKey}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Terms Of Service Url</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.termsOfServiceUrl}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Privacy Policy Url</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.privacyPolicyUrl}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {(config.mysqlReplication && !config.mysqlReplication.enabled) && config.mysql && (
                    <Grid size={12}>
                        <Card key={'mysql'} >
                            <StyledCardHeader title={'Msql'} />
                            <CardContent>
                                <Grid container={true} spacing={0}>
                                    <Grid size={12}>
                                        <InputLabel>Host</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.host}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Database</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.database}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Port</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <NumberField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.port}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Username</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.username}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Password</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.password}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Connection Limit</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysql.extra.connectionLimit}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {config.mysqlReplication && config.mysqlReplication.enabled && (
                    <Grid size={12}>
                        <Card key={'mysql'} >
                            <StyledCardHeader title={'Mysql Replication'} />
                            <CardContent>
                                <Grid container={true} spacing={0}>
                                    <Grid size={12}>
                                        <InputLabel>Master Host</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysqlReplication.master.host}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Master Database</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysqlReplication.master.database}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Master Port</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysqlReplication.master.port}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Master Username</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysqlReplication.master.username}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid size={12}>
                                        <InputLabel>Master Password</InputLabel>
                                    </Grid>
                                    <Grid size={12}>
                                        <TextField
                                            fullWidth={true}
                                            readOnly={true}
                                            label=""
                                            value={config.mysqlReplication.master.password}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    {myslqReplications}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid size={12}>
                    <Card key={'redis'} >
                        <StyledCardHeader title={'Redis'} />
                        <CardContent>
                            <Grid container={true} spacing={0}>
                                <Grid size={12}>
                                    <InputLabel>Host</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.redis.host}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Port</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <NumberField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.redis.port}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={12}>
                    <Card key={'google_configuration'} >
                        <StyledCardHeader title={'Google'} />
                        <CardContent>
                            <Grid container={true} spacing={0}>
                                <Grid size={12}>
                                    <InputLabel>Package Id</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.google.packageId}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Licence Key</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.google.licenceKey}
                                        multiline={true}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Oauth Client Id</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.google.oauthClientId}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <InputLabel>Oauth Client Secret</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.google.oauthClientSecret}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={12}>
                    <Card key={'apple_configuration'} >
                        <StyledCardHeader title={'Apple'} />
                        <CardContent>
                            <Grid container={true} spacing={0}>
                                <Grid size={12}>
                                    <InputLabel>Bundle Id</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.apple.bundleId}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={12}>
                    <Card key={'facebook_configuration'} >
                        <StyledCardHeader title={'Facebook'} />
                        <CardContent>
                            <Grid container={true} spacing={0}>
                                <Grid size={12}>
                                    <InputLabel>App Token</InputLabel>
                                </Grid>
                                <Grid size={12}>
                                    <TextField
                                        fullWidth={true}
                                        readOnly={true}
                                        label=""
                                        value={config.facebook.appToken}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    private initState() {
        const { title } = this.props;
        if (!title) {
            return {
                openDialog: null,
                name: '',
                file: null,
                apiUrl: '',
                apiSecretKey: '',
            };
        }
        const { name, apiUrl, apiSecretKey } = title;
        return {
            openDialog: null,
            name,
            file: null,
            apiUrl,
            apiSecretKey,
        };
    }

    private updateName = (name: string) => this.setState({ name });

    private updateApiUrl = (apiUrl: string) => this.setState({ apiUrl });

    private updateApiSecretKey = (apiSecretKey: string) => this.setState({ apiSecretKey });

    private updateEnable = (): boolean =>  {
        const { title } = this.props;
        const { name, apiUrl, apiSecretKey, file } = this.state;
        if (!title) {
            return false;
        }
        return title.name !== name ||
            title.apiUrl !== apiUrl ||
            title.apiSecretKey !== apiSecretKey ||
            file !== null;

    }

    private handleDrop = (file: File) => this.setState({file});

    private onUpdate = async () => {
        const { title } = this.props;
        const { name, apiUrl, apiSecretKey, file } = this.state;
        if (!title) {
            return;
        }

        await actions.title.update(title.id, name, apiUrl, apiSecretKey, file);
    };

    private openDeleteDialog = () => this.setState({ openDialog: 'Delete' });
    private renderDeleteDialog = () => {
        const { openDialog } = this.state;
        const onDelete = async () => {
            const { title } = this.props;
            if (!title) {
                return;
            }

            await actions.title.deleteTitle(title.id);
            this.toLink('/titles');
        };

        const { themeMode } = this.props.app;
        return (
            <WarningDialog
                themeMode={themeMode}
                open={openDialog === 'Delete'}
                title="Delete Title"
                content="Delete title and all associated data? This cannot be undone."
                onClose={this.closeDialog}
                onSubmit={onDelete}
                maxWidth={'xs'}
            />
        );
    };

    private closeDialog = () => this.setState({ openDialog: null });
}

const StyledCardHeader = styled(CardHeader)(({
    '&.MuiCardHeader-root': {
        height: 0,
        padding: '12px 5px',
    },
    '& .MuiCardHeader-title': {
        fontWeight: 600,
        fontSize: 12,
    },
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    title: state.title.selectedTitle,
    config: state.title.config,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
