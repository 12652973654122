import * as React from 'react';
import { Button, TextField } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';

interface Props extends BaseDialogProps {
    readonly job: Entities.CloudBuildJob;
    readonly onEdit: (id: string, name: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
}

export class EditJobDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidMount() {
        const { job } = this.props;
        this.setState({
            name: job.name,
        });
    }

    componentDidUpdate(prevProps: Props) {
        const { job } = this.props;
        if (job === prevProps.job) {
            return;
        }
        this.setState({
           name: job.name,
        });
    }

    protected renderContent(): React.JSX.Element {
        const { name } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        value={name}
                        label=""
                        required={true}
                        onChange={this.updateName}
                        disabled={false}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onSubmit} variant="contained" disabled={!this.validate()} />
        );
    }

    private onSubmit = () => {
        const { job } = this.props;
        const { name } = this.state;
        if (!this.validate()) {
            return;
        }

        this.props.onEdit(job.id, name);
        this.onClose();
    };

    private updateName = (name: string) => this.setState({ name });

    private validate(): boolean {
        const { job } = this.props;
        const { name } = this.state;
        return name !== '' && job.name !== name;
    }

    protected initState(): State {
        return {
            name: '',
        };
    }
}
