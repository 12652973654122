import * as _ from 'lodash';
import * as React from 'react';
import { PlayerWalletDialog, BasePlayerContainer, Table, BaseElement } from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './index';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
    readonly currencies: Entities.Currency[];
    readonly wallet?: Record<string, number>;
}

interface State {
    readonly openDialog: boolean;
}

class Container extends BaseElement<Props, State> {
    state = {
        openDialog: false,
    };

    async componentDidMount() {
        const { playerId } = this.props;
        await Promise.all([
            actions.economy.listCurrencies(),
            actions.player.getPlayerDetail(playerId),
        ]);
    }

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;

        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Wallet"}
                playerId = {playerId}
                showSubTabs = {true}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { currencies, wallet } = this.props;
        if (!wallet) {
            return;
        }

        const columns = [
            { title: 'Code', field: 'code'},
            { title: 'Display Name', field: 'displayName'},
            { title: 'Amount', field: 'amount'},
        ];
        const data = _.map(
            currencies,
            stat => {
                return {
                    code: stat.code.toUpperCase(),
                    displayName: stat.name,
                    amount: wallet[stat.code],
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                onRowClick={this.onRowClick}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private renderDialog = () => {
        const { currencies, wallet } = this.props;
        if (!wallet) {
            return;
        }

        const { openDialog } = this.state;
        return (
            <PlayerWalletDialog
                open={openDialog}
                title={'Create Wallet'}
                currencies={currencies}
                wallet={wallet}
                onClose={this.closeDialog}
                onCreate={this.updateWallet}
            />
        );
    };

    private onRowClick = (event: any, rowData: any) => this.setState({ openDialog: true });

    private closeDialog = () => this.setState({ openDialog: false });

    private updateWallet = async (wallet: Record<string, number>) => {
        this.closeDialog();
        const { playerId } = this.props;
        await actions.player.updateWallet(playerId, wallet);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
    currencies: state.economy.currencies ? state.economy.currencies : [],
    wallet: state.player.wallet,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
