import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    BaseContainer,
    BaseElement,
    CreateEconomyItemComponent,
} from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { PaidOutlined as PaidIcon } from '@mui/icons-material';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly items: Entities.EconomyItem[];
}

class Container extends BaseElement<Props> {

    async componentDidMount() {
        await actions.economy.getItems();
    }

    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Items',
                to: `/economy/items`,
            },
            {
                text: 'New',
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"New Economy Item"}
                TitleIcon = {PaidIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { items } = this.props;

        return (
            <CreateEconomyItemComponent
                type={'Create'}
                onCreate={this.onCreate}
                onCancel={this.toEconomyItems}
                items={items}
            />
        );
    }

    private onCreate = async (itemId: string, itemClass: string, displayName: string, description: string, tags: string[], customData: string, isStackable: boolean, isTradable: boolean, isLimitedEdition: boolean) => {
        await actions.economy.createItem(itemId, itemClass, displayName, description, tags, customData, isStackable, isTradable, isLimitedEdition);
        this.toEconomyItems();
    };

    private toEconomyItems = () => this.toLink(`/economy/items`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    items: state.economy.items,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
