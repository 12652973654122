import * as React from 'react';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowDropDown, ArrowRight, Folder, FolderOpen } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';

interface Props extends ThemeModeProps {
    readonly parent: Entities.DirectoryTree;
    readonly selected: Entities.DirectoryTree;
    readonly onUpdateFolders: (parent: Entities.DirectoryTree) => void;
    readonly onSelectFolder: (parent: Entities.DirectoryTree) => void;
}

interface State {
    readonly parent: Entities.DirectoryTree | null;
}

export class DirectoryComponent extends React.Component<Props, State> {
    state: State = {
        parent: null,
    };

    componentDidMount() {
        const { parent } = this.props;
        if (parent) {
            this.setState({parent});
        }
    }

    render() {
        const { parent } = this.state;
        if (!parent) {
            return <></>;
        }

        return (
            <StyledList>
                {this.printDirectoryTree(parent)}
            </StyledList>
        );
    }

    printDirectoryTree(parent: Entities.DirectoryTree, depth: number = 0) {
        if (!parent) {
            return <></>;
        }
        const { themeMode, selected } = this.props;
        const subDirectories: React.JSX.Element[] = [];
        if(parent.subDirectories.length > 0) {
            parent.subDirectories.forEach( d => {
                subDirectories.push(
                    <Collapse in={parent.open} timeout="auto" unmountOnExit={true}>
                        <List component="div" disablePadding={true}>
                            {this.printDirectoryTree(d, depth + 1)}
                        </List>
                    </Collapse>
                );
            });
        }

        const handleOpenClick = () => this.handleOpenClick(parent);
        const handleSelectClick = () => this.handleSelectClick(parent);
        const color = parent === selected ? ( themeMode === 'light' ? '#e4e4e4' : '#3f3f3f' ): 'transparent';
        const containChildren = parent.subDirectories.length > 0;

        return (
            <>
                <ListItem style={{width: `100%`, padding: `5px 0px 5px ${depth * 10 + (containChildren ? 5 : 0)}px`, backgroundColor: color}}>
                    {containChildren && (
                        <ListItemIcon onClick={handleOpenClick} style={{ minWidth: 20 }}>
                            {parent.open ? <ArrowDropDown style={{ width: 15, height: 15 }}/> : <ArrowRight style={{ width: 15, height: 15 }}/>}
                        </ListItemIcon>
                    )}
                    <ListItemIcon onClick={handleSelectClick} style={{ minWidth: 25 , paddingLeft: parent.subDirectories.length > 0 ? 0 : 25 }}>
                        <FolderOpen style={{ width: 20, height: 20, color: 'inherit' }}/>
                    </ListItemIcon>
                    <StyledListItemText primary={parent.name} onClick={handleSelectClick}/>
                </ListItem>
                {subDirectories}
            </>
        );
    }

    private handleOpenClick = (selected: Entities.DirectoryTree) => {
        const { parent } = this.state;
        if (!parent) {
            return;
        }
        if (selected.subDirectories.length === 0) {
            return;
        }
        selected.open = !selected.open;
        this.setState({ parent });
        this.props.onUpdateFolders(parent);
    };

    private handleSelectClick = (selected: Entities.DirectoryTree) => {
        this.props.onSelectFolder(selected);
    }
}

const StyledList = styled(List)(({
    '&.MuiList-root': {
        width: '100%',
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        borderRadius: 8,
        boxSizing: 'border-box',
        overflow: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
            height: 8,
        },
        '&::-webkit-scrollbar-thumb': {
            border: '2px solid white',
            borderRadius: 0,
            backgroundColor: 'rgba(0, 0, 0, .3)',
        },
        fontWeight: 'bold',
        fontSize: '13px !important',
    },
    padding: 0,
}));

const StyledListItemText = styled((props: ListItemTextProps) => (
    <ListItemText
        {...props}
        primaryTypographyProps={{
            style: {
                fontSize: '12px',
                fontWeight: 400,
            }
        }}
        secondaryTypographyProps={{
            style: {
                fontSize: '12px',
                fontWeight: 400,
            }
        }}
        style={{
            marginTop: 0,
            marginBottom: 0
        }}
    />
))(() => ({
}));
