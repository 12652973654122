import * as React from 'react';
import { default as moment, Moment } from 'moment';
import {
    colorMenus,
    daysMenus,
    DaysMenuType,
    DurationMenuType,
    getColorTitleByIndex,
    getDurationMenus,
    Menu,
    renderColorSelect,
} from '../../utils';
import { AutomatePlayerAction, Button, Calendar, Dropzone, Metadata, NumberSelect, TextField, DatePicker, TimePicker } from '../common';
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import {
    Checkbox,
    Grid2 as Grid,
    Hidden,
    InputLabel,
    ListItemText,
    OutlinedInput,
    SelectChangeEvent,
    Switch,
    Tab,
    Tabs,
    Typography,
    Select as MuiSelect
} from '@mui/material';
import {
    DateTimeValidationError,
    PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { EventType, TimerRecurringType, TimerType } from '../../enums';
import { styled } from '@mui/styles';
import { TabContext, TabPanel } from '@mui/lab';

export interface CreateLiveEventProps {
    readonly type: 'Create' | 'Edit';
    readonly eventType: EventType;
    readonly liveEvents: Entities.LiveEvent[];
    readonly oldLiveEvent?: Entities.LiveEvent;
}

type Step = 'Detail' | 'Schedule' | 'Rewards' | 'FinishActions' | 'Confirm';
export interface CreateLiveEventState {
    readonly step: Step;
    readonly name: string;
    readonly start: Moment;
    readonly end: Moment;
    readonly duration: number;
    readonly color: number;
    readonly timesMenus: Record<DurationMenuType, Menu>;
    readonly gameData: string;
    readonly developerOnly: boolean;
    readonly file: File | null;
    readonly finishActions: Entities.AutomaticPlayerAction[];
    readonly useDuration: boolean;
    readonly timerType: TimerType;
    readonly timerRecurringType: TimerRecurringType;
    readonly timerRecurringStart: Moment;
    readonly timerRecurringDuration: number;
    readonly timerRecurringDays: number[];
}

export class BaseCreateNewEventComponent<P extends CreateLiveEventProps = CreateLiveEventProps, S extends CreateLiveEventState = CreateLiveEventState> extends React.Component<P, S> {

    render() {
        return (
            <></>
        );
    }

    componentDidMount() {
        this.updateFromOld();
    }

    componentDidUpdate(prevProps: CreateLiveEventProps) {
        if (this.props.oldLiveEvent === prevProps.oldLiveEvent) {
            return;
        }
        this.updateFromOld();
    }

    protected updateFromOld() {
    }

    protected renderDetailContent(): React.JSX.Element {
        return <></>;
    }

    protected renderDetailButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onScheduleStepSet} variant="contained"/>
            </>
        );
    }

    protected renderScheduleContent(): React.JSX.Element {
        const { liveEvents, oldLiveEvent } = this.props;
        const {
            name,
            start,
            end,
            duration,
            color,
            useDuration,
            timerType,
            timerRecurringType,
            timerRecurringStart,
            timerRecurringDuration,
            timerRecurringDays,
        } = this.state;

        const events: any[] = liveEvents
            .filter(e => {
                if(!oldLiveEvent) {
                    return true;
                }
                return  e.eventId !== oldLiveEvent.eventId;
            })
            .map(e => {
                    return {
                        id: e.eventId,
                        title: e.name,
                        start: new Date(e.startTimestamp),
                        end: new Date(e.endTimestamp),
                        backgroundColor: e.scheduleColor,
                        borderColor: e.scheduleColor,
                        textColor: 'white',
                        editable: false,
                    };
                }
            );

        switch (timerType) {
            case TimerType.Scheduled:
                events.push({
                    title: name,
                    start: new Date(start.valueOf()),
                    end: useDuration ? new Date(start.clone().add(duration, 'minutes').valueOf()) : new Date(end.valueOf()),
                    backgroundColor: getColorTitleByIndex(color),
                    borderColor: getColorTitleByIndex(color),
                    textColor: 'white',
                    editable: false,
                });
                break;
            case TimerType.Recurring:
                switch (timerRecurringType) {
                    case TimerRecurringType.Daily:
                        {
                            const endOfMonth = start.clone().endOf('month');
                            let startDate = timerRecurringStart.clone();
                            while (startDate.isBefore(endOfMonth)) {
                                events.push({
                                    title: name,
                                    start: new Date(startDate.valueOf()),
                                    end: new Date(startDate.add(timerRecurringDuration, 'millisecond').valueOf()),
                                    backgroundColor: getColorTitleByIndex(color),
                                    borderColor: getColorTitleByIndex(color),
                                    textColor: 'white',
                                    editable: false,
                                });
                                startDate.add(1, 'day');
                            }
                        }
                        break;
                    case TimerRecurringType.Weekly: {
                        const endOfMonth = start.clone().endOf('month');
                        let startDate = timerRecurringStart.clone();
                        while (startDate.isBefore(endOfMonth)) {
                            if (timerRecurringDays.some(d => d === startDate.day())) {
                                events.push({
                                    title: name,
                                    start: new Date(startDate.valueOf()),
                                    end: new Date(startDate.add(timerRecurringDuration, 'millisecond').valueOf()),
                                    backgroundColor: getColorTitleByIndex(color),
                                    borderColor: getColorTitleByIndex(color),
                                    textColor: 'white',
                                    editable: false,
                                });
                            }
                            startDate.add(1, 'day');
                        }
                    }
                        break;
                }
                break;
        }

        return (
            <Grid container={true} spacing={3} >
                <Grid size={{ xs: 12, sm: 12, md: 4}}>
                    <Grid container={true} spacing={0} style={{marginTop: 10}}>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Color</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            {renderColorSelect(this.handleColorChange, color)}
                        </Grid>
                        <Grid size={{ xs: 12 }} style={{ marginTop: 10 }}>
                            {this.renderTimerCreation()}
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden mdDown={true}>
                    <Grid size={{ xs: 12, sm: 12, md: 8}}>
                        <Calendar
                            headerToolbar = {{
                                left: 'title',
                                center: '',
                                right: 'today prev,next'
                            }}
                            weekends={true}
                            height={'parent'}
                            contentHeight={'auto'}
                            handleWindowResize={true}
                            events={events}
                        />
                    </Grid>
                </Hidden>
            </Grid>
        );
    }

    protected renderScheduleButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onDetailStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onRewardsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderRewardsContent(): React.JSX.Element {
        return <></>;
    }

    protected renderRewardsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onScheduleStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onFinishActionsStepSet} variant="contained"/>
            </>
        );
    }

    protected renderFinishActionsContent(): React.JSX.Element {
        const { finishActions } = this.state;
        return (
            <Grid container={true} spacing={2}>
                <Grid size={{ xs: 12 }}>
                    <Typography variant="caption">
                        List here the actions that Nucleo will be automatically trigger at the moment to end the event.
                    </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <AutomatePlayerAction
                        actions={finishActions}
                        onUpdateActions={this.onUpdateActions}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderFinishActionsButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onRewardsStepSet} variant="contained" color="secondary"/>
                <Button text="Next" icon={ChevronRightOutlined} iconPlacement={'end'} onClick={this.onConfirmStepSet} variant="contained"/>
            </>
        );
    }

    protected renderConfirmContent(): React.JSX.Element {
        return <></>;
    }

    protected renderConfirmButtons(): React.JSX.Element {
        return (
            <>
                <Button text="Back" icon={ChevronLeftOutlined} onClick={this.onFinishActionsStepSet} variant="contained" color="secondary" />
                <Button text="Confirm" onClick={this.onCreate} variant="contained"/>
            </>
        );
    }

    protected renderTimerCreation = () => {
        const { type } = this.props;
        const { start, end, duration, timesMenus, useDuration, timerType, timerRecurringType, timerRecurringDays, timerRecurringDuration } = this.state;
        const durationViews: React.JSX.Element[] = [];
        const timerRecurringDurationViews: React.JSX.Element[] = [];
        Object.keys(timesMenus).forEach(key => {
            const durationMenuType = key as DurationMenuType;
            const {title, value} = timesMenus[durationMenuType];
            durationViews.push(
                <option key={key} value={value}>
                    {title}
                </option>
            );
            if (value < timesMenus['1day'].value) {
                timerRecurringDurationViews.push(
                    <option key={key} value={value}>
                        {title}
                    </option>
                );
            }
        });

        const daysViews: React.JSX.Element[] = [];
        Object.keys(daysMenus).forEach(key => {
            const dayMenuType = key as DaysMenuType;
            const {title, value} = daysMenus[dayMenuType];
            daysViews.push(
                <option key={key} value={value}>
                    <Checkbox checked={timerRecurringDays.includes(value)} />
                    <ListItemText primary={title} />
                </option>
            );
        });

        return (
            <TabContext value={timerType.toString()}>
                <Tabs
                    value={timerType.toString()}
                    onChange={(event, value) => this.setState({ timerType: parseInt(value) })}
                >
                    <Tab label="Scheduled" value={"0"}/>
                    <Tab label="Recurring" value={"1"}/>
                </Tabs>
                <StyledTabPanel value={"0"}>
                    <Grid container={true} spacing={0}>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Start Date</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <DatePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                disablePast={type !== 'Edit'}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Start Time</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TimePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                ampm={false}
                            />
                        </Grid>
                        {useDuration && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Duration</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <NumberSelect
                                        value={duration}
                                        onChange={this.handleDurationChange}
                                    >
                                        {durationViews}
                                    </NumberSelect>
                                </Grid>
                            </>
                        )}
                        {!useDuration && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>End Date</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <DatePicker
                                        value={end}
                                        onChange={this.handleEndDateChange}
                                        disablePast={type !== 'Edit'}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>End Time</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TimePicker
                                        value={end}
                                        onChange={this.handleEndDateChange}
                                        ampm={false}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid size={{ xs: 12 }}>
                            <div style={{display: 'grid', gridAutoFlow: 'column', gridColumnGap: 10, justifyContent: 'flex-end'}}>
                                <Typography variant="caption" style={{margin: 'auto'}}>Use Set Time</Typography>
                                <Switch
                                    checked={useDuration}
                                    onChange={this.endTimeModeChange}
                                    color="primary"
                                    name="checkedLeaderBoardView"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography variant="caption" style={{margin: 'auto'}}>Use Duration</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </StyledTabPanel>
                <StyledTabPanel value={"1"}>
                    <Grid container={true} spacing={0}>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Start</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <DatePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                disablePast={type !== 'Edit'}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>End</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <DatePicker
                                value={end}
                                onChange={this.handleEndDateChange}
                                disablePast={type !== 'Edit'}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <InputLabel>Repeats</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <NumberSelect
                                value={timerRecurringType}
                                onChange={this.handleTimerRecurringTypeChange}
                            >
                                <option key={TimerRecurringType.Daily} value={TimerRecurringType.Daily as number}>
                                    Daily
                                </option>
                                <option key={TimerRecurringType.Weekly} value={TimerRecurringType.Weekly as number}>
                                    Weekly
                                </option>
                            </NumberSelect>
                        </Grid>
                        {timerRecurringType === TimerRecurringType.Daily && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Time</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TimePicker
                                        value={start}
                                        onChange={this.handleTimerRecurringStart}
                                        ampm={false}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Duration</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <NumberSelect
                                        value={timerRecurringDuration}
                                        onChange={this.handleTimerRecurringDurationChange}
                                    >
                                        {timerRecurringDurationViews}
                                    </NumberSelect>
                                </Grid>
                            </>
                        )}
                        {timerRecurringType === TimerRecurringType.Weekly && (
                            <>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Days</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <MuiSelect
                                        multiple={true}
                                        value={timerRecurringDays}
                                        renderValue={days => {
                                            const dayNames = days.map(day => {
                                                let dayName = '';
                                                Object.keys(daysMenus).forEach(key => {
                                                    const dayMenuType = key as DaysMenuType;
                                                    if (daysMenus[dayMenuType].value === day) {
                                                        dayName = daysMenus[dayMenuType].title;
                                                    }
                                                });
                                                return dayName;
                                            })
                                            return dayNames.join(' - ');
                                        }}
                                        onChange={this.handleTimerRecurringDaysChange}
                                        fullWidth={true}
                                        input={<OutlinedInput style={{ border: 'none'}}/>}
                                        variant={'outlined'}
                                    >
                                        {daysViews}
                                    </MuiSelect>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Time</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <TimePicker
                                        value={start}
                                        onChange={this.handleStartDateChange}
                                        ampm={false}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <InputLabel>Duration</InputLabel>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                    <NumberSelect
                                        value={timerRecurringDuration}
                                        onChange={this.handleTimerRecurringDurationChange}
                                    >
                                        {timerRecurringDurationViews}
                                    </NumberSelect>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledTabPanel>
            </TabContext>
        );
    };

    protected onCreate = () => {
    }

    protected initState(): CreateLiveEventState {
        const timesMenus = getDurationMenus();

        return {
            step: 'Detail',
            name: '',
            start: moment.parseZone(),
            end: moment.parseZone().add(1, 'day'),
            duration: timesMenus['1day'].value,
            color: colorMenus.BlueViolet.value,
            timesMenus,
            gameData: '',
            file: null,
            developerOnly: false,
            finishActions: [],
            useDuration: true,
            timerType: TimerType.Scheduled,
            timerRecurringType: TimerRecurringType.Daily,
            timerRecurringStart: moment.parseZone(),
            timerRecurringDuration: timesMenus['1hour'].value,
            timerRecurringDays: [],
        };
    }

    protected handleColorChange = (color: number) => this.setState({ color });

    private handleDurationChange = (duration: number) => this.setState({ duration });

    private handleTimerRecurringDurationChange = (timerRecurringDuration: number) => this.setState({ timerRecurringDuration });

    private handleTimerRecurringTypeChange = (timerRecurringType: number) => this.setState({ timerRecurringType: timerRecurringType });

    protected handleTimerRecurringDaysChange = (event: SelectChangeEvent<number[]>) => {
        const timerRecurringDays = event.target.value as number[];
        this.setState({ timerRecurringDays });
    }

    protected handleStartDateChange = (start: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (start === null) {
            return;
        }
        this.setState({ start });
    }

    protected handleEndDateChange = (end: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (end === null) {
            return;
        }
        this.setState({ end });
    }

    protected handleTimerRecurringStart = (timerRecurringStart: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => {
        if (timerRecurringStart === null) {
            return;
        }
        this.setState({ timerRecurringStart });
    }

    protected endTimeModeChange = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ useDuration: event.target.checked });

    protected handleGameDataChange = (gameData: string) => this.setState({ gameData });

    protected handleDrop = (file: File) => this.setState({file});

    protected handleDeveloperOnlyChange = (developerOnly: boolean) => this.setState({developerOnly});

    protected renderNameField = () => {
        const { name } = this.state;
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        fullWidth={true}
                        required={true}
                        variant="outlined"
                    />
                </Grid>
            </>
        );
    };

    protected renderImageField = () => {
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel>Image</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Dropzone
                        accept={{
                            "image/jpeg": ['.jpeg'],
                            "image/jpg": ['.jpg'],
                            "image/png": ['.png'],
                        }}
                        text={'No file chosen'}
                        description={'Upload image (200x200px - JPEG, JPG or PNG)'}
                        onDropFile={this.handleDrop}
                    />
                </Grid>
            </>
        );
    };

    protected renderGameDataField = (readOnly: boolean = false) => {
        const { gameData } = this.state;
        return (
            <>
                <Grid size={{ xs: 12 }}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Metadata
                        text={gameData}
                        readOnly={readOnly}
                        onChange={this.handleGameDataChange}
                    />
                </Grid>
            </>
        );
    };

    protected updateName = (name: string) => this.setState({ name });

    protected onUpdateActions = (finishActions: Entities.AutomaticPlayerAction[]) => this.setState({finishActions});

    protected onDetailStepSet = () => this.setState({ step: 'Detail'});

    protected onRewardsStepSet = () => this.setState({ step: 'Rewards'});

    protected onScheduleStepSet = () => this.setState({ step: 'Schedule'});

    protected onFinishActionsStepSet = () => this.setState({ step: 'FinishActions'});

    protected onConfirmStepSet = () => this.setState({ step: 'Confirm'});
}

const StyledTabPanel = styled(TabPanel)(({
    '&.MuiTabPanel-root': {
        padding: '10px 0px',
    }
}));
