import * as React from 'react';
import { formatDate, formatNumber, getPlayerLink } from '../utils';
import { ContainerContext, mapProps } from './index';
import { connect } from 'react-redux';
import { ActionRequest } from '../actions';
import { BaseContainer, BaseElement, Table } from '../components';
import { CardMedia, Grid2 as Grid, Typography } from '@mui/material';
import { PollOutlined as PollIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
    readonly name: string;
}

class Container extends BaseElement<Props> {
    protected renderContainer(): React.JSX.Element {
        const { name } = this.props;
        const headlines = [
            {
                text: 'LeaderBoard',
                to: `/leaderBoard/all`,
            },
            {
                text: name,
            },
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title={"LeaderBoard Detail"}
                TitleIcon = {PollIcon}
                headlines={headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        const { name } = this.props;
        if (name.includes('live_event_') || name.includes('season_')) {
            return (
                <Grid container={true} justifyContent="center" spacing={3}>
                    <Grid size={12}>
                        {this.renderRankingTable()}
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid container={true} justifyContent="center" spacing={3}>
                <Grid size={{ xs:12, sm:7 }}>
                    {this.renderRankingTable()}
                </Grid>
                <Grid size={{ xs:12, sm:5 }}>
                    {this.renderHistoryTable()}
                </Grid>
            </Grid>
        );
    };

    private renderHistoryTable = () => {
        const columns = [
            { title: 'Version', field: 'version'},
            { title: 'Start', field: 'start'},
            { title: 'End', field: 'end'},
        ];

        return (
            <Table
                title={
                    <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                        History
                    </Typography>
                }
                data={this.getHistories}
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    search: false,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private getHistories = async (query: any): Promise<QueryResult<any>> => {
        const { name } = this.props;
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`statistic/${name}/history`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities.map((e: Entities.StatisticHistory) => {
            return {
                version: e.version,
                start: formatDate(e.start),
                end: e.end && formatDate(e.end),
            };
        });

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private renderRankingTable = () => {
        const columns = [
            { title: 'Position', field: 'position'},
            { title: 'Player', field: 'player'},
            { title: 'Value', field: 'value'},
        ];
        return (
            <Table
                title={
                    <Typography variant="subtitle1" style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                        Rankings
                    </Typography>
                }
                columns={columns}
                options={{
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: false,
                    sorting: false,
                    draggable: false,
                }}
                data={this.getRanking}
            />
        );
    };

    private getRanking = async (query: any) => {
        const { name } = this.props;
        const { page, pageSize } = query;

        let result: any;
        if (name.includes('live_event_')) {
            const splitNames = name.replace('live_event_', '').split('_');
            const eventId = splitNames[0];
            result = await ActionRequest.get(`liveEvent/${eventId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });
        } else if (name.includes('season_')) {
            const splitNames = name.replace('season_', '').split('_');
            const seasonId = splitNames[0];
            result = await ActionRequest.get(`season/${seasonId}/getLeaderBoardRanking`, { statisticName: name, page: page + 1, perPage: pageSize });
        } else {
            result = await ActionRequest.get(`statistic/${name}/ranking`, { page: page + 1, perPage: pageSize });
        }

        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }

        const data = result.leaderBoard.leaderBoard.map((leaderBoard: any) => {
            return {
                position: `#${leaderBoard.position+1}`,
                player: (
                    <Grid container={true} justifyContent="center" spacing={0} style={{ minWidth: 320}}>
                        {(!leaderBoard.avatarImageUrl || leaderBoard.avatarImageUrl === '') && (
                            <>
                                <Grid size={12}>
                                    {getPlayerLink(leaderBoard.playerId)}
                                </Grid>
                                <Grid size={12}>
                                    {leaderBoard.displayName}
                                </Grid>
                            </>
                        )}
                        {(leaderBoard.avatarImageUrl && leaderBoard.avatarImageUrl !== '') && (
                            <>
                                <Grid size={2}>
                                    <CardMedia image={leaderBoard.avatarImageUrl} style={{width: 40, height: 40, borderRadius: 0, border: `1px solid #c3cfdd`}}/>
                                </Grid>
                                <Grid size={10}>
                                    <Grid container={true} justifyContent="center" spacing={0}>
                                        <Grid size={12}>
                                            {getPlayerLink(leaderBoard.playerId)}
                                        </Grid>
                                        <Grid size={12}>
                                            {leaderBoard.displayName}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ),
                value: formatNumber(leaderBoard.statValue),
            };
        });
        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    name: '',
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const name = params.name || '';
    return (<Container {...props} navigate={navigate} name={name}/>);
};
export default connect(mapStateToProps)(AppContainer);
