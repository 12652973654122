import * as _ from 'lodash';
import * as React from 'react';
import { Button, Table } from '../common';
import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';

interface PlayerWalletDialogProps extends BaseDialogProps {
    readonly currencies: Entities.Currency[];
    readonly wallet: Record<string, number>;
    readonly onCreate: (wallet: Record<string, number>) => void;
}

export interface PlayerWalletDialogState extends BaseDialogState {
    readonly wallet: Record<string, number>;
}

export class PlayerWalletDialog extends BaseDialog<PlayerWalletDialogProps, PlayerWalletDialogState> {
    state = this.initState();

    protected renderContent(): React.JSX.Element {
        const { currencies } = this.props;
        const { wallet } = this.state;
        const columns = [
            { title: 'Code', field: 'code'},
            { title: 'Display Name', field: 'displayName'},
            { title: 'Amount', field: 'amount'},
        ];
        const data = _.map(
            currencies,
            currency => {
                return {
                    code: currency.code.toUpperCase(),
                    displayName: currency.name,
                    amount: wallet[currency.code],
                };
            }
        );

        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: data.length > 10,
                    pageSize: data.length > 10 ? 10 : data.length,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Create" onClick={this.onCreate} variant="contained" disabled={!this.validate()} />
        );
    }

    protected initState(): PlayerWalletDialogState {
        return {
            wallet: { ...this.props.wallet }
        };
    }

    private validate = (): boolean => {
        const { wallet } = this.state;
        return !_.isEqual(wallet, this.props.wallet) && _.every(wallet, val => val >= 0 && _.isSafeInteger(val));
    };

    private onCreate = () => {
        if (!this.validate()) {
            return;
        }
        this.props.onCreate(this.state.wallet);
    };
}
