import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import {
    AddUserTitleDialog,
    WarningDialog,
    BaseSettingsContainer,
    EditUserTitleDialog,
    Table,
    BaseElement,
    HeaderButton,
} from '../components';
import { connect } from 'react-redux';
import actions from '../actions';
import * as _ from 'lodash';
import { Avatar, Hidden } from '@mui/material';
import { UserTitleRole } from '../enums';
import {
    AddOutlined,
    DeleteOutlineOutlined,
    ModeEditOutlineOutlined,
    SettingsOutlined as SettingsIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly users?: Entities.UserTitleRoleData[];
}

type DialogType = 'Add' | 'Edit' | 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedUser: Entities.UserTitleRoleData | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedUser: null,
    };

    async componentDidMount() {
        const { titleId, userTitleRole } = this.props;
        if (userTitleRole < UserTitleRole.Owner) {
            return;
        }
        await actions.title.listUsers(titleId);
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Add User', icon: AddOutlined, onClick: this.openAddUserDialog},
        ];
        return (
            <BaseSettingsContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = "Project settings"
                TitleIcon = {SettingsIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseSettingsContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderAddUserDialog()}
                {this.renderEditUserDialog()}
                {this.renderDeleteUserDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { users, userTitleRole } = this.props;
        if (userTitleRole < UserTitleRole.Owner) {
            return <></>;
        }

        if (!users) {
            return;
        }

        const columns = [
            { title: 'User', field: 'data'},
            { title: 'Role', field: 'role'},
        ];
        const data = _.map(
            users,
            user => {
                return {
                    user,
                    data: this.getUser(user),
                    role: UserTitleRole[user.userTitleRole].toString(),
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1
                }}
                actions = {
                    [
                        rowData => ({
                            icon: ModeEditOutlineOutlined,
                            tooltip: 'Edit',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openEditUserDialog(rowData.user),
                        }),
                        rowData => ({
                            icon: DeleteOutlineOutlined,
                            tooltip: 'Delete',
                            iconProps: {
                                color: 'primary',
                            },
                            onClick: () => this.openDeleteUserDialog(rowData.user),
                        }),
                    ]
                }
            />
        );
    }

    private getUser = (user: Entities.UserTitleRoleData) => {
        const { username, name, picture } = user;

        return (
            <div style={{ display: 'flex' }}>
                <Hidden smDown={true}>
                    <Avatar
                        alt="Remy Sharp"
                        src={picture}
                        sx={{
                            height: 40,
                            width: 40,
                        }}
                    />
                </Hidden>
                <div style={{ display: 'inline-block', margin: '0px 8px' }}>
                    {name !== '' && (
                        <>
                            <div style={{ fontSize: 14 }} >
                                {name}
                            </div>
                            <div style={{ fontSize: 12 }} >
                                {username}
                            </div>
                        </>
                    )}
                    {name === '' && (
                        <div style={{ fontSize: 14 }} >
                            {username}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    private openAddUserDialog = () => this.setState({ openDialog: 'Add' });
    private renderAddUserDialog = () => {
        const { openDialog } = this.state;
        return (
            <AddUserTitleDialog
                open={openDialog === 'Add'}
                title={'Add User'}
                onClose={this.closeDialog}
                onCreate={this.onCreate}
            />
        );
    };

    private openDeleteUserDialog = (selectedUser: Entities.UserTitleRoleData) => this.setState({ openDialog: 'Delete', selectedUser });
    private renderDeleteUserDialog = () => {
        const { openDialog, selectedUser } = this.state;
        if (!selectedUser) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title="Remove User"
                content={`This will permanently delete the user ${selectedUser.username} from the title.`}
                onClose={this.closeDialog}
                onSubmit={this.onDeleteUser}
                maxWidth={'xs'}
            />
        );
    };
    private onDeleteUser = async () => {
        const { titleId } = this.props;
        const { selectedUser } = this.state;
        this.closeDialog();
        if (!selectedUser) {
            return;
        }
        await actions.title.deleteTitleUser(titleId, selectedUser.username);
    };

    private openEditUserDialog = (selectedUser: Entities.UserTitleRoleData) => this.setState({ openDialog: 'Edit', selectedUser });
    private renderEditUserDialog = () => {
        const { openDialog, selectedUser } = this.state;
        if (!selectedUser) {
            return;
        }
        return (
            <EditUserTitleDialog
                open={openDialog === 'Edit'}
                selectedUser={selectedUser}
                onClose={this.closeDialog}
                onUpdate={this.onEditUser}
            />
        );
    };
    private onEditUser = async (role: UserTitleRole) => {
        const { titleId } = this.props;
        const { selectedUser } = this.state;
        if (!selectedUser) {
            return;
        }
        const { username } = selectedUser;
        await actions.title.editTitleUser(titleId, username, role);
    };

    private closeDialog = () => this.setState({ openDialog: null, selectedUser: null });

    private onCreate = async (username: string, role: UserTitleRole) => {
        this.closeDialog();
        const { titleId } = this.props;
        await actions.title.addTitleUser(titleId, username, role);
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    users: state.title.users,
});

const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate} />);
};
export default connect(mapStateToProps)(AppContainer);
