import * as React from 'react';
import { CircularProgress } from '@mui/material';

interface Props extends ThemeModeProps {
    disableText?: boolean;
}

export class Loading extends React.Component<Props> {
    render() {
        const { themeMode } = this.props;
        return (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center'}}>
                <CircularProgress style={{width: 40, height: 40, color: themeMode === 'light' ? '#b0bec5' : '#9ba8ae'}}/>
                {!this.props.disableText && (
                    <div style={{color: themeMode === 'light' ? '#b0bec5' : '#9ba8ae', marginTop: 5, fontSize: 'x-small'}}>
                        Loading...
                    </div>
                )}
            </div>
        );
    }
}
