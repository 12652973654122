import { ContainerContext, mapProps } from './index';
import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { Grid2 as Grid, } from '@mui/material';
import {
    AddOutlined,
    CheckCircleOutlineOutlined,
    CreateNewFolderOutlined,
    DeleteOutline,
    DeleteOutlined,
    PublicOutlined as PublicIcon,
} from '@mui/icons-material';
import { formatBytes, formatDate, getContentVersionsViews } from '../utils';
import {
    CreateFolderDialog,
    CreateNewFileVersionDialog,
    CreateNewFileVersionItemDialog,
    BaseContainer,
    AlertDialog,
    WarningDialog,
    Button,
    DirectoryComponent,
    Table,
    BaseElement,
    HeaderButton, TextSelect,
} from '../components';
import { UserTitleRole } from '../enums';
import { styled } from '@mui/styles';
import { useNavigate, useParams } from 'react-router';
import * as _ from 'lodash';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly version: string;
    readonly versions: Entities.FileVersion[];
    readonly selectedVersion: Entities.FileVersion | null;
    readonly items: Entities.FileVersionItem[];
    readonly canRemove: boolean;
}

type DialogType = 'NewVersion' | 'ConfirmSetAsDefault' | 'ConfirmDelete' | 'NewItem' | 'ConfirmItemDelete' | 'NewFolder' | 'ConfirmDeleteFolder';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedItems: string[] | null;
    readonly parentFolder: Entities.DirectoryTree;
    readonly selectedFolder: Entities.DirectoryTree | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedItems: null,
        selectedFolder: null,
        parentFolder: {
            name: 'Root',
            open: true,
            subDirectories: [],
            files: [],
        },
    };

    async componentDidMount() {
        const { version } = this.props;
        await actions.cdn.get(version);
    }

    componentDidUpdate(prevProps: Props) {
        const { items } = this.props;
        if (items === prevProps.items) {
            return;
        }
        const parent: Entities.DirectoryTree = {
            name: 'Root',
            open: true,
            subDirectories: [],
            files: [],
        };
        items.forEach(item => {
            this.buildDirectoryTree(item.name, parent, item);
        });
        this.setState({parentFolder: parent, selectedFolder: parent});
    }

    protected renderContainer(): React.JSX.Element {
        const { selectedVersion, canRemove } = this.props;
        let buttons: HeaderButton[] | undefined;
        if (this.props.userTitleRole > UserTitleRole.Viewer) {
            buttons = [];
            if (selectedVersion && !selectedVersion.isDefault) {
                buttons.push({ text: 'Set As Default', icon: CheckCircleOutlineOutlined, onClick: this.openSetAsDefaultConfirmDialog});
            }
            buttons.push({ text: 'New Version', icon: AddOutlined, onClick: this.openNewVersionDialog});
            if (canRemove) {
                buttons.push({ text: 'Delete', icon: DeleteOutline, onClick: this.openConfirmDeleteDialog, color: 'error'});
            }
        }
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"File Management"}
                TitleIcon = {PublicIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderNewVersionDialog()}
                {this.renderSetAsDefaultConfirmDialog()}
                {this.renderConfirmDeleteDialog()}
                {this.renderCreateItemDialog()}
                {this.renderConfirmDeleteItemDialog()}
                {this.renderAddFolderDialog()}
                {this.renderConfirmDeleteFolderDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { versions, selectedVersion, userTitleRole } = this.props;
        const { parentFolder, selectedFolder } = this.state;
        const { themeMode } = this.props.app;
        if (!selectedVersion || !selectedFolder) {
            return;
        }

        const columns = [
            { title: 'Name', field: 'name'},
            { title: 'Size', field: 'size', render: (rowData: any) => formatBytes(rowData.size)},
            { title: 'Created', field: 'created', render: (rowData: any) => formatDate(rowData.created)},
        ];

        const data = _.orderBy(selectedFolder.files, ['size'], ['desc']);
        const title = userTitleRole > UserTitleRole.Viewer ? (
            <div>
                <div style={{
                    display: 'grid',
                    justifyContent: 'start',
                    padding: '5px 0px',
                    gridAutoFlow: 'column',
                    width: '100%',
                    gridColumnGap: 10,
                }}>
                    <StyledButton variant="text" text="Upload File" icon={AddOutlined} onClick={this.openCreateItemDialog} />
                    <StyledButton variant="text" text="Add Folder" icon={CreateNewFolderOutlined} onClick={this.openAddFolderDialog} />
                    <StyledButton variant="text" text="Delete Folder" icon={DeleteOutlined} onClick={this.openConfirmDeleteFolderDialog} />
                </div>
            </div>
        ) : <></>;

        const contentVersionsViews = getContentVersionsViews(versions, false);

        const onSearchChange = (searchText: string) => {
            const filters = selectedFolder.files.filter(d => d.name.includes(searchText));
            let size = 0;
            filters.forEach(file => size += file.size);
        };

        return (
            <Grid container={true} justifyContent="center" spacing={2}>
                <Grid size={12}>
                    <TextSelect
                        value={selectedVersion.version}
                        onChange={this.onChangeVersion}
                        sx={{
                            height: 32,
                            '&.MuiNativeSelect-select': {
                                fontSize: '14px',
                            }
                        }}
                    >
                        {contentVersionsViews}
                    </TextSelect>
                </Grid>
                <Grid size={12}>
                    <Grid container={true} justifyContent="center" spacing={2}>
                        <Grid size={{ xs:12, sm:4 }}>
                            <DirectoryComponent
                                themeMode={themeMode}
                                parent={parentFolder}
                                selected={selectedFolder}
                                onUpdateFolders={this.onUpdateFolders}
                                onSelectFolder={this.onSelectFolder}
                            />
                        </Grid>
                        <Grid size={{ xs:12, sm:8 }}>
                            <Table
                                title={title}
                                data={data}
                                columns={columns}
                                onSearchChange={onSearchChange}
                                options={{
                                    selection: userTitleRole > UserTitleRole.Viewer,
                                    paging: true,
                                    pageSize: 10,
                                    pageSizeOptions: [5, 10, 30, 50],
                                    emptyRowsWhenPaging: true,
                                    search: true,
                                    sorting: true,
                                    draggable: false,
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: `cdn`,
                                    actionsColumnIndex: -1,
                                }}
                                style={{
                                    height: '100%',
                                }}
                                actions={[
                                    {
                                        tooltip: 'Remove All Selected Data',
                                        position: 'toolbarOnSelect',
                                        icon: DeleteOutlined,
                                        hidden: userTitleRole === UserTitleRole.Viewer,
                                        onClick: this.onDeleteClick,
                                    }
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private onSelectFolder = (folder: Entities.DirectoryTree) => this.setState({selectedFolder: folder});

    private onUpdateFolders = (parentFolder: Entities.DirectoryTree) => this.setState({ parentFolder });

    private closeDialog = () =>
        this.setState({
            openDialog: null,
            selectedItems: null,
        });

    private onChangeVersion = async (version: string) => {
        const { selectedVersion } = this.props;
        if (!selectedVersion || selectedVersion.version === version) {
            return;
        }
        await actions.cdn.get(version);
    };

    private openNewVersionDialog = () => this.setState({ openDialog: 'NewVersion' });
    private renderNewVersionDialog = () => {
        const { versions } = this.props;
        const { openDialog } = this.state;
        return (
            <CreateNewFileVersionDialog
                open={openDialog === 'NewVersion'}
                title={'Create New File Version'}
                TitleIcon = {PublicIcon}
                versions={versions}
                onClose={this.closeDialog}
                onCreate={this.createVersion}
            />
        );
    };
    private createVersion = async (version: string, isDefault: boolean) => {
        this.closeDialog();
        await actions.cdn.createVersion(version, isDefault);
    }

    private openSetAsDefaultConfirmDialog = () => this.setState({ openDialog: 'ConfirmSetAsDefault' });
    private renderSetAsDefaultConfirmDialog = () => {
        const { openDialog } = this.state;
        const { selectedVersion } = this.props;
        if (!selectedVersion) {
            return;
        }
        return (
            <AlertDialog
                open={openDialog === 'ConfirmSetAsDefault'}
                title="Set as Default"
                TitleIcon = {PublicIcon}
                content={`Are you sure you want set ${selectedVersion.version} as default`}
                submitButtonText={'Confirm'}
                onClose={this.closeDialog}
                onSubmit={this.setVersionAsDefault}
            />
        );
    };
    private setVersionAsDefault = async () => {
        this.closeDialog();
        const { selectedVersion } = this.props;
        if (!selectedVersion) {
            return;
        }
        await actions.cdn.setVersionAsDefault(selectedVersion.version);
        await actions.cdn.get();
    };

    private openConfirmDeleteDialog = () => this.setState({ openDialog: 'ConfirmDelete' });
    private renderConfirmDeleteDialog = () => {
        const { openDialog } = this.state;
        const { selectedVersion } = this.props;
        if (!selectedVersion) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDelete'}
                title="Delete Version"
                TitleIcon = {PublicIcon}
                content={`Are you sure you want to delete the files version ${selectedVersion.version}? This will delete all the files included on this version.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteVersion}
                maxWidth={'xs'}
            />
        );
    };
    private deleteVersion = async () => {
        this.closeDialog();
        const { selectedVersion } = this.props;
        if (!selectedVersion) {
            return;
        }
        await actions.cdn.deleteVersion(selectedVersion.version);
        await actions.cdn.get();
    };

    private openCreateItemDialog = () => this.setState({ openDialog: 'NewItem' });
    private renderCreateItemDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateNewFileVersionItemDialog
                open={openDialog === 'NewItem'}
                title={'Create New File Version Item'}
                TitleIcon = {PublicIcon}
                onClose={this.closeDialog}
                onCreate={this.createItem}
            />
        );
    };
    private createItem = async (file: any) => {
        this.closeDialog();
        const { selectedVersion } = this.props;
        const { parentFolder, selectedFolder } = this.state;
        if (!selectedVersion) {
            return;
        }
        let folder = selectedFolder ? this.getFolderPath(parentFolder, selectedFolder) : '';
        folder = folder.replace('/Root', '');
        await actions.cdn.createItem(selectedVersion.version, folder, file.name, file);
    };

    private buildDirectoryTree(path: string, parent: Entities.DirectoryTree | undefined, item: Entities.FileVersionItem) {
        if (!parent) {
            return;
        }

        if (path.charAt(0) === '/') {
            path = path.substring(1, path.length);
        }

        if (path.includes("/")) {
            const dir = path.substring(0, path.indexOf("/"));
            const newPath = path.substring(dir.length + 1);
            let addNodeTo: Entities.DirectoryTree | undefined;
            if (!parent.subDirectories.some(d => d.name === dir)) {
                const newParent: Entities.DirectoryTree = {
                    name: dir,
                    open: false,
                    subDirectories: [],
                    files: [],
                };
                parent.subDirectories.push(newParent);
                addNodeTo = newParent;
            } else {
                addNodeTo = parent.subDirectories.find(d => d.name === dir);
            }
            this.buildDirectoryTree(newPath, addNodeTo, item);
        } else {
            parent.files.push({
                name: path,
                size: item.size,
                created: item.size,
            });
        }
    }

    private getFolderPath = (parent: Entities.DirectoryTree, selected: Entities.DirectoryTree, path: string = '') => {
        if (parent === selected) {
            return `${path}/${parent.name}/`;
        }

        let subPath = "";
        for (const directory of parent.subDirectories) {
            subPath = this.getFolderPath(directory, selected, `${path}/${parent.name}`);
            if (subPath !== '') {
                break;
            }
        }

        return subPath;
    };

    private openAddFolderDialog = () => this.setState({ openDialog: 'NewFolder' });
    private renderAddFolderDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateFolderDialog
                open={openDialog === 'NewFolder'}
                title={'Create Folder'}
                TitleIcon = {PublicIcon}
                onClose={this.closeDialog}
                onCreate={this.addFolder}
            />
        );
    };
    private addFolder = async (folder: string) => {
        this.closeDialog();
        const { parentFolder,selectedFolder } = this.state;
        if(!selectedFolder) {
            return;
        }
        selectedFolder.subDirectories.push({
            name: folder,
            open: true,
            subDirectories: [],
            files: [],
        });
        this.setState({ parentFolder });
    };

    private openConfirmDeleteFolderDialog = () => this.setState({ openDialog: 'ConfirmDeleteFolder' });
    private renderConfirmDeleteFolderDialog = () => {
        const { openDialog, parentFolder, selectedFolder } = this.state;
        if (!selectedFolder) {
            return;
        }
        const folder = selectedFolder ? this.getFolderPath(parentFolder, selectedFolder) : '';
        return (
            <WarningDialog
                open={openDialog === 'ConfirmDeleteFolder'}
                title="Delete Folder"
                TitleIcon = {PublicIcon}
                content={`Are you sure you want to delete the folder '${folder}'? This will delete all the files included on this folder.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteFolder}
                maxWidth={'xs'}
            />
        );
    };
    private deleteFolder = async () => {
        this.closeDialog();
        const { selectedVersion, items } = this.props;
        const { parentFolder, selectedFolder } = this.state;
        if (!selectedVersion || !selectedFolder) {
            return;
        }
        let folder = selectedFolder ? this.getFolderPath(parentFolder, selectedFolder) : '';
        folder = folder.replace('/Root', '');
        const selectedItems: string[] = items.filter(i => i.name.startsWith(folder)).map(i => i.name);
        await actions.cdn.deleteMultipleItems(selectedVersion.version, selectedItems);
    };

    private onDeleteClick = async (event: any, data: any[]) => {
        const selectedItems = data.map(d => d.key);
        this.setState({openDialog: 'ConfirmItemDelete', selectedItems});
    };
    private renderConfirmDeleteItemDialog = () => {
        const { openDialog } = this.state;
        const { selectedVersion } = this.props;
        if (!selectedVersion) {
            return;
        }
        return (
            <WarningDialog
                open={openDialog === 'ConfirmItemDelete'}
                title="Delete Items"
                TitleIcon = {PublicIcon}
                content={`This will permanently delete the selected items.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteItems}
                maxWidth={'xs'}
            />
        );
    };
    private deleteItems = async () => {
        const { selectedVersion } = this.props;
        const { selectedItems } = this.state;
        this.closeDialog();
        if (!selectedVersion || selectedItems === null) {
            return;
        }

        await actions.cdn.deleteMultipleItems(selectedVersion.version, selectedItems);
    };
}

const StyledButton = styled(Button)(({
    fontSize: 'smaller',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
}));

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    version: '',
    versions: state.cdn.versions,
    selectedVersion: state.cdn.selectedVersion,
    items: state.cdn.items,
    canRemove: state.cdn.canRemove,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const version = params.version || '';
    return (<Container {...props} navigate={navigate} version={version}/>);
};
export default connect(mapStateToProps)(AppContainer);
