import React from 'react';
import { Divider, Grid2 as Grid, InputLabel } from '@mui/material';
import { Button, NumberField, TextField } from '../common';
import { AddOutlined, DeleteOutlined, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../../utils';

interface Props {
    readonly ranks?: Entities.EntityLeaderBoardRank[];
    readonly onUpdateRanks: (ranks: Entities.EntityLeaderBoardRank[]) => void;
}

interface State {
    readonly ranks: Entities.EntityLeaderBoardRank[];
}

export class LeaderBoardRanks extends React.Component<Props, State> {
    state: State = {
        ranks: [],
    };

    componentDidMount() {
        const { ranks } = this.props;
        if (ranks) {
            this.setState({ranks});
        }
    }

    render() {
        const { ranks } = this.state;
        const rankElements: React.JSX.Element[] = [];
        ranks.forEach((rank, rankIndex) => rankElements.push(
            <>
                {rankIndex > 0 && (
                    <Grid size={12}>
                        <Divider />
                    </Grid>
                )}
                <Grid size={12}>
                    {this.renderRank(rank, rankIndex)}
                </Grid>
            </>
        ));

        const addRank = () => {
            if (ranks.length === 0) {
                ranks.push({
                    id: '0',
                    entityId: '',
                    description: '',
                    initialPosition: 1,
                    finalPosition: 10,
                    prizes: [],
                });
            } else {
                const initialPosition = ranks[ranks.length - 1].finalPosition + 1;
                ranks.push({
                    id: 'ranks.length',
                    entityId: '',
                    description: '',
                    initialPosition,
                    finalPosition: initialPosition + 10,
                    prizes: [],
                });
            }
            this.updateRanks(ranks);
        };

        return (
            <Grid container={true} spacing={1} style={{width: '100%', margin: 0, padding: 10}}>
                <Grid size={12}>
                    <strong>RANK CONTENT</strong>
                </Grid>
                <Grid size={12}>
                    <StyledButton text="Add Rank" icon={AddOutlined} onClick={addRank} />
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <Divider />
                </Grid>
                <Grid size={{ xs:12 }} >
                    <Grid container={true} spacing={2}>
                        {ranks.length === 0 && (
                            <Grid size={12}>
                                No ranks
                            </Grid>
                        )}
                        {rankElements}
                    </Grid>
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <Divider />
                </Grid>
                {ranks.length > 1 && (
                    <Grid size={12}>
                        <StyledButton text="Add Rank" icon={AddOutlined} onClick={addRank} />
                    </Grid>
                )}
            </Grid>
        );
    }

    private renderRank = (rank: Entities.EntityLeaderBoardRank, rankIndex: number) => {
        const { ranks } = this.state;
        const onInitialPositionChange = (initialPosition: number) => {
            ranks[rankIndex].initialPosition = initialPosition;
            this.updateRanks(ranks);
        };

        const onFinalPositionChange = (finalPosition: number) => {
            ranks[rankIndex].finalPosition = finalPosition;
            this.updateRanks(ranks);
        };

        const onDescriptionChange = (description: string) => {
            ranks[rankIndex].description = description;
            this.updateRanks(ranks);
        };

        const onAddReward = () => {
            ranks[rankIndex].prizes.push({
                id: ranks[rankIndex].prizes.length,
                rankId: rank.id,
                key: '',
                amount: 0,
            });
            this.updateRanks(ranks);
        };

        const onRemoveRank = () => {
            this.updateRanks(ranks.filter((value, i) => i !== rankIndex));
        };

        const prizeElements: React.JSX.Element[] = [];
        rank.prizes.forEach( (prize, prizeIndex) => prizeElements.push(this.renderPrize(prize, rankIndex, prizeIndex)));

        return (
            <Grid container={true} spacing={0} >
                <Grid size={12}>
                    <InputLabel>From</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={rank.initialPosition}
                        onChange={onInitialPositionChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>To (inclusive)</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        fullWidth={true}
                        label=""
                        value={rank.finalPosition}
                        onChange={onFinalPositionChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Description</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        fullWidth={true}
                        label=""
                        value={rank.description}
                        onChange={onDescriptionChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12} style={{ margin: '10px 0px' }}>
                    <Divider />
                </Grid>
                <Grid size={12}>
                    <StyledAccordion>
                        <StyledAccordionSummary expandIcon={<ExpandMore />}>
                            PRIZES ({rank.prizes.length})
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <Grid container={true} spacing={1} style={{width: '100%', margin: 0, padding: 10}}>
                                <>
                                    {rank.prizes.length === 0 && (
                                        <Grid size={12} style={{padding: 10, textAlign: 'center'}}>
                                            No prizes
                                        </Grid>
                                    )}
                                    {prizeElements}
                                </>
                            </Grid>
                            <Grid size={12}>
                                <StyledButton text="Add Prize" icon={AddOutlined} onClick={onAddReward} />
                            </Grid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </Grid>
                <Grid size={12}>
                    <StyledButton text="Remove Rank" icon={DeleteOutlined} onClick={onRemoveRank} />
                </Grid>
            </Grid>
        );
    };

    private renderPrize = (prize: Entities.EntityLeaderBoardRankPrize, rankIndex: number, prizeIndex: number) => {
        const { ranks } = this.state;

        const onKeyChange = (key: string) => {
            ranks[rankIndex].prizes[prizeIndex].key = key;
            this.updateRanks(ranks);
        };

        const onAmountChange = (amount: number) => {
            ranks[rankIndex].prizes[prizeIndex].amount = amount;
            this.updateRanks(ranks);
        };

        const onRemovePrize = () => {
            ranks[rankIndex].prizes = ranks[rankIndex].prizes.filter((value, i) => i !== prizeIndex);
            this.updateRanks(ranks);
        };

        return (
            <Grid size={12}>
                <Grid container={true} spacing={0} style={{width: '100%'}}>
                    <Grid size={12}>
                        <InputLabel>Key</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth={true}
                            label=""
                            value={prize.key}
                            onChange={onKeyChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid size={12}>
                        <InputLabel>Amount</InputLabel>
                    </Grid>
                    <Grid size={12}>
                        <NumberField
                            fullWidth={true}
                            label=""
                            value={prize.amount}
                            onChange={onAmountChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid size={12} style={{textAlign: 'end'}}>
                        <StyledButton text="Remove Prize" icon={DeleteOutlined} onClick={onRemovePrize} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private updateRanks = (ranks: Entities.EntityLeaderBoardRank[]) => {
        this.setState({ ranks });
        this.props.onUpdateRanks(ranks);
    };
}

const StyledButton = styled(Button)(({
    color: '#0073bb',
    fontSize: 'smaller',
    fontWeight: 'normal',
    textTransform: 'none',
    borderColor: 'rgba(0, 0, 0, 0)',
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0)',
    }
    ,
    '&:hover': {
        backgroundColor: 'transparent',
    }
}));
