import * as React from 'react';
import { ContainerContext, mapProps } from './index';
import { BaseContainer, BaseElement, CreateSeasonLeaderBoardComponent } from '../components';
import actions from '../actions';
import { connect } from 'react-redux';
import { GamesOutlined as GamesIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { LeaderBoardType } from '../enums';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
}

class Container extends BaseElement<Props> {
    protected renderContainer(): React.JSX.Element {
        const headlines = [
            {
                text: 'Season',
                to: `/season/all`,
            },
            {
                text: 'New',
            },
        ];

        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"New Season"}
                TitleIcon = {GamesIcon}
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    private renderContent = () => {
        return (
            <CreateSeasonLeaderBoardComponent
                type='Create'
                onCreate={this.onCreateSeason}
            />
        );
    }

    private onCreateSeason = async (name: string, start: number, end: number, metadata: string, developerOnly: boolean, tiers: Entities.SeasonTier[], ranks: Entities.EntityLeaderBoardRank[], maxUsersPerInstance: number, maxAmountPerReport: number, leaderBoardType: LeaderBoardType) => {
        await actions.season.createLeaderBoard(name, start, end, metadata, developerOnly, tiers, ranks, maxUsersPerInstance, maxAmountPerReport, leaderBoardType);
        this.toSeason();
    };

    private toSeason = () => this.toLink( `/season/all`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
