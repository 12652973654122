import * as _ from 'lodash';
import * as React from 'react';
import { classifyEconomyItems } from '../utils';
import { WarningDialog, BaseEconomyContainer, Table, BaseElement, HeaderButton } from '../components';
import { ContainerContext, mapProps } from './index';
import actions from '../actions';
import { connect } from 'react-redux';
import { AddOutlined, DeleteOutlineOutlined, LibraryAddOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly items: Entities.EconomyItem[];
}

type DialogType = 'Delete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedItem: Entities.EconomyItem | null;
    readonly items: Entities.EconomyItem[];
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedItem: null,
        items: [],
    };

    async componentDidMount() {
        await actions.economy.getItems();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.items !== prevProps.items) {
            const items = classifyEconomyItems(this.props.items).items;
            this.setState({items});
        }
    }

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New Item', icon: AddOutlined, onClick: this.openCreateItem},
        ];
        return (
            <BaseEconomyContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Store"}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseEconomyContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const columns = [
            { title: 'Item ID', field: 'itemId'},
            { title: 'Display Name', field: 'displayName'},
            { title: 'Item Class', field: 'itemClass'},
        ];

        const data = _.map(
            this.state.items,
            item => {
                return {
                    itemId: item.id || '',
                    displayName: item.displayName || '',
                    itemClass: item.itemClass || '',
                    item,
                };
            }
        );

        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1
                }}
                actions = {[
                    rowData => ({
                        icon: ModeEditOutlineOutlined,
                        tooltip: 'Edit',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.edit(rowData.item)
                    }),
                    rowData => ({
                        icon: LibraryAddOutlined,
                        tooltip: 'Duplicate',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.duplicate(rowData.item)
                    }),
                    rowData => ({
                        icon: DeleteOutlineOutlined,
                        tooltip: 'Delete',
                        iconProps: {
                            color: 'primary',
                        },
                        onClick: () => this.delete(rowData.item)
                    }),
                ]}
            />
        );
    }

    private openCreateItem = () => this.toLink(`/economy/newItem`);

    private edit = (item: Entities.EconomyItem) => this.toLink(`/economy/item/${item.id}/edit`);

    private duplicate = (item: Entities.EconomyItem) => this.toLink(`/economy/item/${item.id}/duplicate`);

    private delete = (item: Entities.EconomyItem) => this.setState({openDialog: 'Delete', selectedItem: item});
    private renderDeleteDialog = () => {
        const { openDialog, selectedItem } = this.state;
        if (!selectedItem) {
            return <></>;
        }
        return (
            <WarningDialog
                open={openDialog === 'Delete'}
                title={`Delete Item ${selectedItem.id}`}
                content="This will permanently delete the economy item."
                onClose={this.onCloseDialog}
                onSubmit={this.onDelete}
                maxWidth={'xs'}
            />
        );
    };
    private onDelete = async () => {
        const { selectedItem } = this.state;
        this.onCloseDialog();
        if (!selectedItem) {
            return;
        }
        await actions.economy.deleteItem(selectedItem.id);
    };

    private onCloseDialog = () => this.setState({ openDialog: null, selectedItem: null});
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    items: state.economy.items,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
