import { BaseDialog, BaseDialogProps, BaseDialogState } from '../common/dialog/BaseDialog';
import { Grid2 as Grid, InputLabel } from '@mui/material';
import { Button, Metadata, NumberField, NumberSelect, TextField } from '../common';
import * as React from 'react';

interface Props extends BaseDialogProps {
    readonly group: Entities.Group;
    readonly onEdit: (id: string, name: string, privacy: number, description: string, maxSize: number, metadata: string) => void;
}

interface State extends BaseDialogState {
    readonly name: string;
    readonly privacy: number;
    readonly description: string;
    readonly maxSize: number;
    readonly metadata: string;
}

export class EditGroupDialog extends BaseDialog<Props, State> {
    state = this.initState();

    componentDidUpdate(prevProps: Props) {
        if (this.props.group.id !== prevProps.group.id) {
            this.setState(this.initState());
        }
    }

    protected renderContent(): React.JSX.Element {
        const {
            name,
            privacy,
            description,
            maxSize,
            metadata,
        } = this.state;

        return (
            <Grid container={true} spacing={0}>
                <Grid size={12}>
                    <InputLabel>Name</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={name}
                        onChange={this.updateName}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel id="select-privacy">Privacy</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberSelect
                        labelId={`select-privacy`}
                        value={privacy}
                        onChange={this.handleChangePrivacy}
                    >
                        <option key={0} value={0}>
                            {'Open'}
                        </option>
                        <option key={1} value={1}>
                            {'Apply'}
                        </option>
                        <option key={2} value={2}>
                            {'Invite Only'}
                        </option>
                    </NumberSelect>
                </Grid>
                <Grid size={12}>
                    <InputLabel>Description</InputLabel>
                </Grid>
                <Grid size={12}>
                    <TextField
                        label=""
                        value={description}
                        onChange={this.updateDescription}
                        variant="outlined"
                        fullWidth={true}
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel>Max Size</InputLabel>
                </Grid>
                <Grid size={12}>
                    <NumberField
                        label=""
                        value={maxSize}
                        onChange={this.updateMaxSize}
                        fullWidth={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid size={12}>
                    <InputLabel htmlFor="game-data">Metadata</InputLabel>
                </Grid>
                <Grid size={12}>
                    <Metadata
                        text={metadata}
                        onChange={this.handleChangeMetadata}
                    />
                </Grid>
            </Grid>
        );
    }

    protected renderActions(): React.JSX.Element {
        return (
            <Button text="Update" onClick={this.onCreate} variant="contained" disabled={!this.validate()} />
        );
    }

    protected initState(): State {
        const { group } = this.props;
        if (!group) {
            return {
                name: '',
                privacy: 0,
                description: '',
                maxSize: 50,
                metadata: '',
            };
        }

        return {
            name: group.name,
            privacy: group.privacy,
            description: group.description,
            maxSize: group.maxPlayersCount,
            metadata: group.metadata,
        };
    }

    private updateName = (name: string) => this.setState({ name });

    private handleChangePrivacy = (privacy: number) => this.setState({ privacy });

    private updateDescription = (description: string) => this.setState({ description });

    private updateMaxSize = (maxSize: number) => this.setState({ maxSize });

    protected handleChangeMetadata = (metadata: string) => this.setState({ metadata });

    private onCreate = () => {
        const { group } = this.props;
        const { name, privacy, description, maxSize, metadata } = this.state;
        if (!this.validate()) {
            return;
        }
        this.props.onEdit(group.id, name, privacy, description, maxSize, metadata);
        this.onClose();
    };

    private validate(): boolean {
        const { group } = this.props;
        const { name, privacy, description, maxSize, metadata } = this.state;
        return group.name !== name || group.privacy !== privacy || group.description !== description
            || group.maxPlayersCount != maxSize || group.metadata !== metadata;
    }
}
