import * as React from 'react';
import { connect } from 'react-redux';
import {
    WarningDialog,
    BaseContainer,
    Table,
    BaseElement,
    HeaderButton, CreateLocalizationDialog,
} from '../components';
import { ContainerContext, mapProps } from './index';
import actions, { ActionRequest } from '../actions';
import {
    AddOutlined,
    LanguageOutlined as LanguageIcon,
    DeleteOutlineOutlined,
} from '@mui/icons-material';
import { UserTitleRole } from '../enums';
import { useNavigate } from 'react-router';
import { StateMap } from '../reducers';
import { QueryResult } from 'material-table';

interface Props extends ContainerContext.Props {
}

type DialogType = 'Create' | 'Update' | 'ConfirmDelete';
interface State {
    readonly openDialog: DialogType | null;
    readonly selectedLocalization: Entities.Localization | null;
}

class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: null,
        selectedLocalization: null,
    };

    protected renderContainer(): React.JSX.Element {
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'New', icon: AddOutlined, onClick: this.openCreateDialog}
        ];
        return (
            <BaseContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Localizations"}
                TitleIcon={LanguageIcon}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BaseContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderCreateDialog()}
                {this.renderConfirmDeleteDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { userTitleRole } = this.props;
        const columns = [
            { title: 'Key', field: 'key'},
            { title: 'English', field: 'english'},
            { title: 'Mexican Spanish', field: 'spanishMexican'},
            { title: 'Spain Spanish', field: 'spanishSpain'},
            { title: 'Deutsch', field: 'deutsch'},
            { title: 'French', field: 'french'},
            { title: 'Brazil Portuguese', field: 'portugueseBrazil'},
        ];

        return (
            <Table
                columns={columns}
                data={this.getData}
                options={{
                    showTitle: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                    actionsColumnIndex: -1,
                }}
                actions={
                    userTitleRole > UserTitleRole.Viewer ?
                        [
                            /*
                            rowData => ({
                                icon: ModeEditOutlineOutlined,
                                tooltip: 'Edit',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.openUpdateDialog(rowData.segmentData)
                            }),
                             */
                            rowData => ({
                                icon: DeleteOutlineOutlined,
                                tooltip: 'Delete',
                                iconProps: {
                                    color: 'primary',
                                },
                                onClick: () => this.onDeleteClick(rowData.segmentData)
                            }),
                        ]
                        : undefined
                }
            />
        );
    };

    private getData = async (query: any): Promise<QueryResult<any>> => {
        const { search, page, pageSize, orderBy, orderDirection } = query;
        const params = {
            search,
            page: page + 1,
            perPage: pageSize,
            orderBy: orderBy ? orderBy.field : null,
            orderDirection: orderDirection !== "" ? orderDirection : null,
        };
        const result = await ActionRequest.get(`localization/list`, params);
        if (!result) {
            return {
                data: [],
                page: 0,
                totalCount: 0,
            };
        }
        const data = result.entities;

        return {
            data,
            page: result.page - 1,
            totalCount: result.totalCount,
        };
    }

    private openCreateDialog = () => this.setState({ openDialog: 'Create' });
    private renderCreateDialog = () => {
        const { openDialog } = this.state;
        return (
            <CreateLocalizationDialog
                open={openDialog === 'Create'}
                title="Create Localization"
                TitleIcon={LanguageIcon}
                onClose={this.closeDialog}
                onCreate={this.onCreateSegment}
            />
        );
    };
    private onCreateSegment = async (key: string, english: string, spanishMexican: string, spanishSpain: string, deutsch: string, french: string, italian: string, portugueseBrazil: string) => {
        this.closeDialog();
        await actions.localization.create(key, english, spanishMexican, spanishSpain, deutsch, french, italian, portugueseBrazil);
    };

    /*
    private openUpdateDialog = (selectedLocalization: Entities.Localization) => this.setState({ openDialog: 'Update', selectedLocalization });
    private renderUpdateDialog = () => {
        const { openDialog, selectedLocalization } = this.state;
        return (
            <UpdateSegmentDialog
                open={openDialog === 'Update'}
                selectedSegment={selectedLocalization}
                title="Create Localization"
                TitleIcon={LanguageIcon}
                onClose={this.closeDialog}
                onUpdate={this.onUpdateLocalization}
            />
        );
    };
    private onUpdateLocalization = async (id: string, name: string, color: string, rules: Entities.SegmentRule[]) => {
        this.closeDialog();
        await actions.segment.update(id, name, color, rules);
    };
     */

    private renderConfirmDeleteDialog = () => {
        const { openDialog, selectedLocalization } = this.state;
        if (!selectedLocalization) {
            return;
        }
        return (
            <WarningDialog
                themeMode={this.props.app.themeMode}
                open={openDialog === 'ConfirmDelete'}
                title={'Delete Localization'}
                content={`Delete '${selectedLocalization.key}' localization? This cannot be undone.`}
                onClose={this.closeDialog}
                onSubmit={this.deleteLocalization}
                maxWidth={'xs'}
            />
        );
    };
    private deleteLocalization = async () => {
        this.closeDialog();
        const { selectedLocalization } = this.state;
        if (!selectedLocalization) {
            return;
        }
        await actions.segment.remove(selectedLocalization.key);
    };

    private closeDialog = () => this.setState({ openDialog: null, selectedLocalization: null });

    private onDeleteClick = (selectedLocalization: Entities.Localization) => this.setState({ openDialog: 'ConfirmDelete', selectedLocalization });
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    return (<Container {...props} navigate={navigate}/>);
};
export default connect(mapStateToProps)(AppContainer);
