import * as _ from 'lodash';
import * as React from 'react';
import { PlayerGrantItemDialog, BasePlayerContainer, Table, BaseElement, HeaderButton } from '../components';
import { formatDate } from '../utils';
import actions from '../actions';
import { connect } from 'react-redux';
import { ContainerContext, mapProps } from './index';
import { UserTitleRole } from '../enums';
import { useNavigate, useParams } from 'react-router';
import { AddOutlined } from '@mui/icons-material';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly playerId: string;
    readonly stackables?: Entities.PlayerStackableItem[];
}

interface State {
    readonly openDialog: boolean;
}

export class Container extends BaseElement<Props, State> {
    state: State = {
        openDialog: false,
    };

    async componentDidMount() {
        const { playerId } = this.props;
        await Promise.all([
            actions.player.getInventory(playerId),
        ]);
    }

    protected renderContainer(): React.JSX.Element {
        const { playerId } = this.props;
        const buttons: HeaderButton[] | undefined = this.props.userTitleRole === UserTitleRole.Viewer ? undefined : [
            { text: 'Grant Item', icon: AddOutlined, onClick: this.openGrantItemDialog},
        ];
        return (
            <BasePlayerContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = {"Inventory"}
                playerId = {playerId}
                showSubTabs = {true}
                buttons = {buttons}
            >
                {this.renderContent()}
            </BasePlayerContainer>
        );
    }

    protected renderDialogs(): React.JSX.Element {
        return (
            <>
                {this.renderGrantItemDialog()}
            </>
        );
    }

    private renderContent = () => {
        const { stackables } = this.props;
        const columns = [
            { title: 'ItemId', field: 'itemId'},
            { title: 'ItemClass', field: 'itemClass'},
            { title: 'InstanceId', field: 'instanceId'},
            { title: 'Remaining Usages', field: 'remainingUsages'},
            { title: 'Purchase Date', field: 'purchaseDate'},
        ];
        const data = _.map(
            stackables,
            stackable => {
                return {
                    itemId: stackable.itemId,
                    itemClass: stackable.itemClass,
                    instanceId: stackable.itemInstanceId,
                    remainingUsages: stackable.remainingUses,
                    purchaseDate: formatDate(stackable.purchaseDate),
                };
            }
        );
        return (
            <Table
                data={data}
                columns={columns}
                options={{
                    showTitle: false,
                    selection: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 30, 50],
                    emptyRowsWhenPaging: false,
                    search: true,
                    sorting: false,
                    draggable: false,
                }}
            />
        );
    };

    private closeDialog = () => this.setState({ openDialog: false });

    private openGrantItemDialog = () => this.setState({ openDialog: true });

    private renderGrantItemDialog = () => {
        const { playerId } = this.props;
        const { stackables } = this.props;
        const { openDialog } = this.state;
        if (!stackables) {
            return;
        }

        return (
            <PlayerGrantItemDialog
                open={openDialog}
                title={'Grant Item'}
                playerId={playerId}
                stackables={stackables}
                onClose={this.closeDialog}
            />
        );
    };
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    playerId: '',
    stackables: state.player.stackables,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const playerId = params.playerId || '';
    return (<Container {...props} navigate={navigate} playerId={playerId}/>);
};
export default connect(mapStateToProps)(AppContainer);
