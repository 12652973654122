import * as React from 'react';
import DateRangePickerWrapper, { DateRangePickerWrapperProps } from './DateRangePickerWrapper';
import { Components as StyleComponents } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { styleComponents } from '../../../../utils';
import { getThemeMode } from '../../../../reducers';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const DateRangePickerExporter: React.FunctionComponent<DateRangePickerWrapperProps> = (
  props: DateRangePickerWrapperProps,
) => {
    const themeMode = getThemeMode();
    const tableComponents: StyleComponents<Omit<Theme, 'components'>> = {
        ...styleComponents(themeMode),
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px',
                    backgroundColor: themeMode === 'light' ? '#fff' : '#212121',
                    color: themeMode === 'light' ? '#404040' : '#adadad',
                    border: 'none',
                },
                elevation0: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
                elevation2: {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
                elevation5: {
                    borderRadius: '8px !important',
                    boxShadow: themeMode === 'light' ? 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important'
                        : 'rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px  !important',
                    backgroundColor: themeMode === 'light' ? '#fff !important' : '#212121 !important',
                    border: 'none',
                }
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                    borderColor: themeMode === 'light' ? '#e5e7e9' : '#5d636d',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color:  themeMode === 'light' ? '#717171' : '#aeaeae',
                    '&:disabled': {
                        color:  themeMode === 'light' ? '#e5e7e9' : '#6d6d6d',
                    },
                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                },
                overline: {
                    color: 'inherit',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    padding: 0,
                }
            }
        }
    };
    return (
        <ThemeProvider theme={createTheme({ components: tableComponents })}>
            <DateRangePickerWrapper
                {...props}
            />
        </ThemeProvider>
    );
}

export default DateRangePickerExporter;
