import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AggregationMethod, ResetFrequency } from '../enums';
import { getThemeMode, getTitleId } from '../reducers';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardMedia,
    Chip,
    Grid2 as Grid,
    Hidden,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemIconProps, ListItemProps,
    ListItemText, ListProps,
    Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Android,
    Apple,
    Square,
    DeveloperModeOutlined,
    Facebook, PhoneIphone,
    ReportOutlined,
    ReportProblemOutlined,
    InfoOutlined,
    Circle,
} from '@mui/icons-material';
import { Button, NumberSelect } from '../components';
import { ContainerContext } from '../containers';
import { ListItemButtonProps } from '@mui/material/ListItemButton/ListItemButton';
export * from './request';
export * from './theme';
export * from './localStorage';

export const Link = (props: any) => {
    const { to, ...rest } = props;
    let overrideTo = to;
    const titleId = getTitleId();
    const themeMode = getThemeMode();
    const style = {
        color: themeMode === 'light' ? '#0b57d0' : '#7cacf8',
        textDecoration: 'none',
        fontSize: 13,
        fontWeight: 500,
    };
    if (titleId !== '') {
        overrideTo = `/${titleId}${overrideTo}`;
    }
    return <ReactRouterLink {...rest} to={overrideTo} style={{ ...style, ...rest.style }}/>;
};

export const toLink = (props: ContainerContext.Props, to: string) => {
    const { navigate, titleId } = props;
    if (navigate === null || navigate === undefined) {
        return;
    }

    if (titleId !== '') {
        to = `/${titleId}${to}`;
    }
    navigate(to);
};

export const routeMap: Record<string, any> = {};

export const isJson = (str: string) => {
    try {
        JSON.stringify(JSON.parse(str.trim()), null, 2);
    } catch (e) {
        return false;
    }
    return true;
};

export const formatJSON = (str: string) => {
    try {
        return JSON.stringify(JSON.parse(str.trim()), null, 2);
    } catch (e) {
        return str;
    }
};

export const parseExtraData = (str: string) => {
    if(!str) {
      return '';
    }
    if(isJson(str)) {
        const json = JSON.parse(str);
        const values: string[] = [];
        for (const [key, value] of Object.entries(json)) {
            values.push(`${key}: ${value}`);
        }
        return values.join(' | ');
    }

    return str;
};

export const formatDate = (timestamp: number, format: string = 'lll') => timestamp === 0 ? '' : formatMomentDate(moment(timestamp), format);

export const formatMomentDate = (time: moment.Moment, format: string = 'lll') => time.format(format);

export const formatNumber = (value: number) => value > 100000 ? Number(value).toExponential(2) : Math.round(value);

export const formatDuration = (duration: number) => {
    const momentDuration = moment.duration(duration);
    const hours = momentDuration.hours();
    const minutes = momentDuration.minutes();
    const seconds = momentDuration.seconds();
    return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;
};

export const formatBytes = (value: number) => {
    if(value < 1024) {
        return `${value} bytes`;
    } else if(value < 1024 * 1024) {
        return `${(value/1024).toFixed(2)} KB`;
    } else if(value < 1024 * 1024 * 1024) {
        return `${(value/(1024 * 1024)).toFixed(2)} MB`;
    } else {
        return `${(value/(1024 * 1024 * 1024)).toFixed(2)} GB`;
    }
};

// economy

export const isBundle = (item: Entities.EconomyItem): boolean =>
    !!item.bundledItems.length ||
    !!item.bundledResultTables.length ||
    (!!item.bundledVirtualCurrencies && item.bundledVirtualCurrencies !== '{}');

export const isContainer = (item: Entities.EconomyItem): boolean => !!item.container;

export const classifyEconomyItems = (items: Entities.EconomyItem[]) =>
    _.chain(items)
        .groupBy(item => (isContainer(item) ? 'containers' : isBundle(item) ? 'bundles' : 'items'))
        .pick('containers', 'bundles', 'items')
        .value();

// search

export const createRegExpList = (text: string): RegExp[] => text.split(/\s/g).map(str => new RegExp(str || '.*', 'i'));

export const formatDateToTextField = (timestamp: number): string => {
    if(timestamp === 0) {
        return '';
    }

    return new Date(timestamp)
        .toISOString()
        .split(':')
        .slice(0, 2)
        .join(':');
};

export interface Menu {
    title: string;
    value: number;
}
export type DurationMenuType = '1minute' | '2minute' | '5minute' | '15minute' | '30minute' | '45minute' | '1hour'
    | '1.5hour' | '2hour' | '2.5hour' | '3hour' | '4hour' | '8hour' | '12hour' | '1day' | '1.25day' | '1.5day' | '2day'
    | '3day' | '3.5day' | '4day' | '1week' | '2week' | '1month';
export const getDurationMenus = (): Record<DurationMenuType, Menu> => {
    return {
        '1minute':  { title: '1 minute', value: 1 },
        '2minute':  { title: '2 minutes', value: 2 },
        '5minute':  { title: '5 minutes', value: 5 },
        '15minute': { title: '15 minutes', value: 15 },
        '30minute': { title: '30 minutes', value: 30 },
        '45minute': { title: '45 minutes', value: 45 },
        '1hour':    { title: '1 hour', value: 60 },
        '1.5hour':  { title: '1.5 hour', value: 90 },
        '2hour':    { title: '2 hours', value: 120 },
        '2.5hour':  { title: '2.5 hours', value: 150 },
        '3hour':    { title: '3 hours', value: 180 },
        '4hour':    { title: '4 hours', value: 240 },
        '8hour':    { title: '8 hours', value: 480 },
        '12hour':   { title: '12 hours', value: 720 },
        '1day':     { title: '1 day', value: 1440 },
        '1.25day':  { title: '1.25 days (30 hours)', value: 1800 },
        '1.5day':   { title: '1.5 days (36 hours)', value: 2160 },
        '2day':     { title: '2 days (48 hours)', value: 2880 },
        '3day':     { title: '3 days (72 hours)', value: 4320 },
        '3.5day':   { title: '3.5 days (84 hours)', value: 5040 },
        '4day':     { title: '4 days (96 hours)', value: 5760 },
        '1week':    { title: '1 week', value: 10080 },
        '2week':    { title: '2 weeks', value: 20160 },
        '1month':    { title: '1 month', value: 40320 },
    };
};

export type ResetFrequencyMenuType = 'Never' | 'Hour' | 'Day' | 'Week' | 'Month';
export const getResetFrequencyMenus = () : Record<ResetFrequencyMenuType, Menu> => {
    return {
        'Never':    { title: 'Manually', value: ResetFrequency.Never },
        'Hour':     { title: 'Hourly', value: ResetFrequency.Hour },
        'Day':      { title: 'Daily', value: ResetFrequency.Day },
        'Week':     { title: 'Weekly', value: ResetFrequency.Week },
        'Month':    { title: 'Monthly', value: ResetFrequency.Month },
    };
};

export type AggregationMethodMenuType = 'Last' | 'Min' | 'Max' | 'Sum';
export const getAggregationMethodMenus = () : Record<AggregationMethodMenuType, Menu> => {
    return {
        'Last':     { title: 'Last (always update with the new value)', value: AggregationMethod.Last },
        'Min':      { title: 'Minimum (always use the lowest value)', value: AggregationMethod.Min },
        'Max':      { title: 'Maximum (always use the highest value)', value: AggregationMethod.Max },
        'Sum':      { title: 'Sum (add this value to the existing value)', value: AggregationMethod.Sum },
    };
};

export type ColorMenuType = 'BlueViolet' | 'BurlyWood' | 'CadetBlue' | 'Chocolate' | 'CornFlowerBlue' | 'DarkCyan' |
    'DarkGoldenRod' | 'DarkGreen' | 'Gold' | 'Orange' | 'Red' | 'Grey' | 'LimeGreen';
export const colorMenus: Record<ColorMenuType, Menu> = {
    'BlueViolet':           { title: 'blueviolet', value: 0 },
    'BurlyWood':            { title: 'burlywood', value: 1 },
    'CadetBlue':            { title: 'cadetblue', value: 2 },
    'Chocolate':            { title: 'chocolate', value: 3 },
    'CornFlowerBlue':       { title: 'cornflowerBlue', value: 4 },
    'DarkCyan':             { title: 'darkcyan', value: 5 },
    'DarkGoldenRod':        { title: 'darkgoldenrod', value: 6 },
    'DarkGreen':            { title: 'darkgreen', value: 7 },
    'Gold':                 { title: 'gold', value: 8 },
    'Orange':               { title: 'orange', value: 9 },
    'Red':                  { title: 'red', value: 10 },
    'Grey':                 { title: 'grey', value: 11 },
    'LimeGreen':            { title: 'limegreen', value: 12 },
};

export type DaysMenuType = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';
export const daysMenus: Record<DaysMenuType, Menu> = {
    'Monday':               { title: 'Monday', value: 1 },
    'Tuesday':              { title: 'Tuesday', value: 2 },
    'Wednesday':            { title: 'Wednesday', value: 3 },
    'Thursday':             { title: 'Thursday', value: 4 },
    'Friday':               { title: 'Friday', value: 5 },
    'Saturday':             { title: 'Saturday', value: 6 },
    'Sunday':               { title: 'Sunday', value: 0 },
}

export const getColorIndexByTitle = (selectedTitle: string): number => {
    let index = 0;
    Object.keys(colorMenus).forEach(key => {
        const colorMenuType = key as ColorMenuType;
        const {title, value} = colorMenus[colorMenuType];
        if(title === selectedTitle) {
            index = value;
        }
    });
    return index;
};

export const getColorTitleByIndex = (index: number): string => {
    let color = '';

    Object.keys(colorMenus).forEach(key => {
        const colorMenuType = key as ColorMenuType;
        const {title, value} = colorMenus[colorMenuType];
        if (value === index) {
            color = title;
        }
    });
    return color;
};

export const renderColorSelect = (onChange: (value: number) => void, color: number) => {
    const colorViews: React.JSX.Element[] = [];
    Object.keys(colorMenus).forEach(key => {
        const colorMenuType = key as ColorMenuType;
        const {title, value} = colorMenus[colorMenuType];
        colorViews.push(
            <option key={key} value={value}>
                <Square style={{ color: `${title}`}} />
                {title}
            </option>
        );
    });

    return (
        <NumberSelect
            value={color}
            onChange={onChange}
        >
            {colorViews}
        </NumberSelect>
    );
};

export const isActive = (startTimestamp: number, endTimestamp: number): boolean => {
    return moment().isBetween(moment(startTimestamp), moment(endTimestamp));
};

export const isComing = (startTimestamp: number, endTimestamp: number): boolean => {
    if (isActive(startTimestamp, endTimestamp)) {
        return false;
    }
    if (isEnded(endTimestamp)) {
        return false;
    }
    return moment().isBefore(moment(startTimestamp));
};

export const isEnded = (endTimestamp: number): boolean => {
    return moment().isAfter(moment(endTimestamp));
};

export const getDurationTitleFromTimeStamp = (startTimestamp: number, endTimestamp: number) : string => {
    const start = moment(startTimestamp);
    const end = moment(endTimestamp);
    return getDurationTitleFromMoment(start, end);
};

export const getDurationTitleFromMoment = (start: moment.Moment, end: moment.Moment): string => {
    const duration = end.diff(start, 'minutes');
    let durationText = '';

    const timesMenus = getDurationMenus();
    Object.keys(timesMenus).forEach(key => {
        const durationMenuType = key as DurationMenuType;
        const {title, value} = timesMenus[durationMenuType];
        if(value === duration) {
            durationText = title;
        }
    });
    return durationText;
};

export const getDurationValueFromTimeStamp = (startTimestamp: number, endTimestamp: number) : number => {
    const start = moment(startTimestamp);
    const end = moment(endTimestamp);
    return getDurationValueFromMoment(start, end);
};

export const getDurationValueFromMoment = (start: moment.Moment, end: moment.Moment) : number => {
    const duration = end.diff(start, 'minutes');
    let durationValue = 0;
    const timesMenus = getDurationMenus();
    Object.keys(timesMenus).forEach((key, index) => {
        const durationMenuType = key as DurationMenuType;
        const {value} = timesMenus[durationMenuType];
        if(value === duration) {
            durationValue = value;
        }
    });
    return durationValue;
};

export const truncateString = (text: string, size: number = 40) : string => {
    if(text.length <= size) {
        return text;
    }
    return `${text.substring(0, size)}...`;
};

export const localeNumber = (value: number) : string => {
    return value.toLocaleString(undefined, {
        maximumFractionDigits: 2
    });
};

export const localeCurrencyNumber = (value: number, currency: string = 'USD') : string => {
    if (!value) {
        value = 0;
    }
    return value.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency,
    });
};

export const getPlayerLink = (playerId: string) => {
    const to = `/players/${playerId}/overview`;
    return (
        <Link to={to}>
            {playerId}
        </Link>
    );
};

export const getLiveEventLink = (liveEventId: string) => {
    const to = `/liveEvent/${liveEventId}/details`;
    return (
        <Link to={to}>
            {liveEventId}
        </Link>
    );
};

export const getSeasonLink = (seasonId: string) => {
    const to = `/season/${seasonId}/details`;
    return (
        <Link to={to}>
            {seasonId}
        </Link>
    );
};

export const getCloudCodeRevisionLink = (cloudCodeRevision: number) => {
    const to = `/cloudCode/${cloudCodeRevision}`;
    return (
        <Link to={to}>
            {cloudCodeRevision}
        </Link>
    );
};

export const getTitleDataVersionLink = (titleDataVersion: string) => {
    const to = `/titleData/${titleDataVersion}`;
    return (
        <Link to={to}>
            {titleDataVersion}
        </Link>
    );
};

export const getContentVersionLink = (contentVersion: string) => {
    const to = `/files/${contentVersion}`;
    return (
        <Link to={to}>
            {contentVersion}
        </Link>
    );
};

export const getLeaderBoardLink = (statisticName: string) => {
    const to = `/leaderBoard/${statisticName}/details`;
    return (
        <Link to={to}>
            {statisticName}
        </Link>
    );
};

export const getCloudCodeVersionsViews = (
    cloudCodeRevisions: Entities.CloudCode[],
    showEmpty: boolean,
) => {
    const views: React.JSX.Element[] = [];
    if (showEmpty) {
        views.push(
            <option key={''} value={''}>
                {''}
            </option>
        );
    }
    cloudCodeRevisions.sort((p1, p2) => p2.created - p1.created)
        .forEach(item => {
            views.push(
                    <option key={item.revision} value={item.revision}>
                        {`${item.revision} - ${formatDate(item.created)}${item.live ? ' - LIVE' : ''}`}
                    </option>
                );
            }
        );
    return views;
};

export const getTitleDataVersionsViews = (
    titleDataVersions: Entities.TitleData[],
    showEmpty: boolean,
) => {
    const views: React.JSX.Element[] = [];
    if (showEmpty) {
        views.push(
            <option key={''} value={''}>
                {''}
            </option>
        );
    }
    titleDataVersions.sort((p1, p2) => p2.updated - p1.updated)
        .forEach(item => {
            let extraData = parseExtraData(item.extraData);
            extraData = extraData !== '' ? ` - ${extraData}` : '';
            views.push(
                <option key={item.version} value={item.version}>
                    {`${item.version} - ${formatDate(item.updated)}${item.isDefault ? ' - DEFAULT' : ''}${extraData}`}
                </option>
            );
        }
    );
    return views;
};

export const getContentVersionsViews = (
    contentVersions: Entities.FileVersion[],
    showEmpty: boolean,
) => {
    const views: React.JSX.Element[] = [];
    if (showEmpty) {
        views.push(
            <option key={''} value={''}>
                {''}
            </option>
        );
    }
    contentVersions.sort((p1, p2) => p2.updated - p1.updated)
        .forEach(item => {
            let extraData = parseExtraData(item.extraData);
            extraData = extraData !== '' ? ` - ${extraData}` : '';
            views.push(
                <option key={item.version} value={item.version}>
                    {`${item.version} - ${formatDate(item.updated)}${item.isDefault ? ' - DEFAULT' : ''}${extraData}`}
                </option>
            );
        }
    );
    return views;
};

export const getVersionsViews = (
    cloudCodeRevisions: Entities.CloudCode[],
    titleDataVersions: Entities.TitleData[],
    contentVersions: Entities.FileVersion[],
    showEmpty: boolean,
) => {
    const cloudCodesViews = getCloudCodeVersionsViews(cloudCodeRevisions, showEmpty);
    const titleDataVersionsViews = getTitleDataVersionsViews(titleDataVersions, showEmpty);
    const contentVersionsViews = getContentVersionsViews(contentVersions, showEmpty);

    return {
        cloudCodesViews,
        titleDataVersionsViews,
        contentVersionsViews
    };
};

interface FilterListProps extends ListProps, ThemeModeProps {
}
export const FilterList = styled((props: FilterListProps) => (
    <List
        {...props}
        sx={{
            '&.MuiList-root': {
                minWidth: 200,
                padding: 0,
            },
        }}
    >
        {props.children}

    </List>
))(() => ({}));

interface FilterListItemButtonProps extends ListItemButtonProps, ThemeModeProps {
}
export const FilterListItemButton = styled((props: FilterListItemButtonProps) => (
    <ListItemButton
        {...props}
        sx={{
            '&.MuiListItemButton-root': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                padding: '0px 4px 0px 16px',
                height: 32,
                "&$selected": {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    "&:disabled": {
                        backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    },
                    "&:hover": {
                        backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    },
                }
            },
            '&.MuiListItemButton-selected': {
                backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                '&:disabled': {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                },
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                },
            },
            padding: 0,
        }}
    >
        {props.children}
    </ListItemButton>
))(() => ({}));

interface FilterListItemProps extends ListItemProps, ThemeModeProps {
}
export const FilterListItem = styled((props: FilterListItemProps) => (
    <ListItem
        {...props}
        sx={{
            '&.MuiListItem-root': {
                color: props.themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.9)',
                padding: '0px 4px 0px 16px',
                height: 32,
                "&$selected": {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    "&:disabled": {
                        backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    },
                    "&:hover": {
                        backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    },
                }
            },
            '&.MuiListItem-selected': {
                backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                '&:disabled': {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                },
                '&:hover': {
                    backgroundColor: props.themeMode === 'light' ? '#e8f0fe' : 'rgba(174, 203, 250, 0.04)',
                    color: props.themeMode === 'light' ? '#0073bb' : 'rgba(255, 255, 255, 0.9)',
                },
            },
            padding: 0,
        }}
    >
        {props.children}

    </ListItem>
))(() => ({}));

interface FilterListItemIconProps extends ListItemIconProps, ThemeModeProps {
}
export const FilterListItemIcon = styled((props: FilterListItemIconProps) => (
    <ListItemIcon
        {...props}
        sx={{
            '&.MuiListItemIcon-root': {
                minWidth: 24,
                padding: 0,
                color: 'unset',
            },
        }}
    >
        {props.children}
    </ListItemIcon>
))(() => ({}));

interface FilterListItemText {
    text: string;
    color?: string;
}
export const FilterListItemText = styled((props: FilterListItemText) => {
    const primary = (<div style={{ fontSize: 14, color: props.color }}>{props.text}</div>);
    return (
        <ListItemText primary={primary} />
    );
})(() => ({
}));

export const StyledAccordion = styled(Accordion)(({
    '&.MuiAccordion-root': {
        width: '100%',
        padding: 0,
        border: 'none',
        '&$expanded': {
            padding: '0px 5px',
        },
    },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({
    '&.MuiAccordionSummary-root': {
        minHeight: 30,
        padding: '0px 5px',
    },
    '& .MuiAccordionSummary-content': {
        margin: '0px !important',
        fontWeight: 700,
        fontSize: 14,
        padding: 0,
        '&$expanded': {
            margin: 0,
        },
    }
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({
    borderRadius: '0px 0px 8px 8px',
    padding: 5,
    fontSize: 13,
}));

export const getPlayerDetails = (player: Entities.Player) => {
    const themeMode = getThemeMode();
    const icons: React.JSX.Element[] = [];
    const { online, facebookLoginLink, googleLoginLink, appleIdLoginLink, googlePlayGamesLoginLink, gameCenterLoginLink, banned, cheater, developer } = player;
    if (facebookLoginLink) {
        icons.push(<Facebook style={{ height: 25, width: 25, color: 'rgb(66, 103, 178)' }} />);
    }
    if (googleLoginLink) {
        icons.push(<CardMedia
            style={{ height: 25, width: 25 }}
            image={`${process.env.PUBLIC_URL}/images/google_icon.png`}
            title="Logo"
        />);
    }
    if (appleIdLoginLink) {
        icons.push(<Apple style={{ height: 25, width: 25, color: 'black' }} />);
    }
    if (googlePlayGamesLoginLink) {
        icons.push(<CardMedia
            style={{ height: 25, width: 25 }}
            image={`${process.env.PUBLIC_URL}/images/google_play_games_icon.png`}
            title="Logo"
        />);
    }
    if (gameCenterLoginLink) {
        icons.push(<CardMedia
            style={{ height: 25, width: 25 }}
            image={`${process.env.PUBLIC_URL}/images/game_center_icon.png`}
            title="Logo"
        />);
    }

    return (
        <div>
            {banned && (
                <Chip
                    color={'secondary'}
                    icon={<ReportOutlined />}
                    size='small'
                    label='Banned'
                    style={{ backgroundColor: 'red', color: 'white', margin: 2 }}
                />
            )}
            {cheater && (
                <Chip
                    color={'secondary'}
                    icon={<ReportProblemOutlined />}
                    size='small'
                    label='Cheater'
                    style={{ backgroundColor: 'orange', color: 'white', margin: 2 }}
                />
            )}
            {developer && (
                <Chip
                    color={'secondary'}
                    icon={<DeveloperModeOutlined />}
                    size='small'
                    label='Developer'
                    style={{ backgroundColor: '#21BA47', color: 'white', margin: 2 }}
                />
            )}
            <div style={{ display: 'flex' }}>
                {player.avatarImageUrl !== '' && (
                    <Hidden smDown={true}>
                        <CardMedia
                            image={player.avatarImageUrl}
                            style={{
                                margin: '4px 0px',
                                width: 40,
                                height: 40,
                                borderRadius: 8,
                                border: themeMode === 'light' ? '1px solid rgb(216, 222, 228)' : '1px solid #323337',
                                backgroundColor: '#fff',
                            }}
                        />
                    </Hidden>
                )}
                <div style={{ display: 'inline-block', margin: '0px 8px' }}>
                    <div style={{ display: 'flex', alignContent: 'flex-start' }}>
                        <Circle style={{ color: online ? 'green' : 'red', padding: '0px 2px', width: 12 }} />
                        {getPlayerLink(player.playerId)}
                    </div>
                    <div>
                        {player.displayName}
                    </div>
                    <Hidden smDown={true}>
                        <div style={{ display: 'flex'}}>
                            {icons}
                        </div>
                    </Hidden>
                </div>
            </div>
        </div>
    );
};

export const getPlayerPlatform = (player: Entities.Player, size: number = 25) => {
    let icon = <></>;
    switch (player.platform) {
        case 'Android':
            icon = <Tooltip title="Android">
                <Android style={{ height: size, width: size, color: '#a4c639' }}/>
            </Tooltip>;
            break;
        case 'IPhonePlayer':
            icon = <Tooltip title="iOS">
                <PhoneIphone style={{ height: size, width: size, color: '#1a73e8' }} />
            </Tooltip>;
            break;
        case 'OSXEditor':
        case 'WindowsEditor':
        case 'LinuxEditor':
            icon = <Tooltip title="Unity">
                    <CardMedia
                    style={{ height: size, width: size }}
                    image={`${process.env.PUBLIC_URL}/images/unity_icon.png`}
                />
            </Tooltip>;
            break;
    }
    return icon;
};

export const parseJson = (value: string, onSelected?: (value: string) => void) => {
    if(isJson(value)) {
        const onSelect = () => onSelected ? onSelected(value) : null;
        return (
            <Grid container={true} justifyContent="center" spacing={1}>
                <Grid size={12}>
                    {truncateString(value)}
                </Grid>
                <Grid  size={12}>
                    <Button
                        onClick={onSelected ? onSelect : undefined}
                        text="JSON"
                        icon={InfoOutlined}
                        variant="text"
                        style={{
                            color: '#0073bb',
                            fontWeight: 400,
                            fontSize: '0.866em',
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
    return value;
};
