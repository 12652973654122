import * as React from 'react';
import { connect } from 'react-redux';
import actions from '../actions';
import { ContainerContext, mapProps } from './';
import { BaseEconomyContainer, BaseElement, CreateEconomyItemComponent } from '../components';
import { useNavigate, useParams } from 'react-router';
import { StateMap } from '../reducers';

interface Props extends ContainerContext.Props {
    readonly itemId: string;
    readonly item?: Entities.EconomyItem;
    readonly items: Entities.EconomyItem[];
}

interface State {
    readonly item: Entities.EconomyItem | null;
}

class Container extends BaseElement<Props, State> {
    state = {
        item: this.props.item ? { ...this.props.item } : null,
    };

    async componentDidMount() {
        const { itemId } = this.props;
        await actions.economy.getItem(itemId);
    }

    componentDidUpdate(prevProps: Props) {
        const { item } = this.props;
        if (item && item !== prevProps.item) {
            this.setState({ item: { ...item } });
        }
    }

    protected renderContainer(): React.JSX.Element {
        const { itemId } = this.props;
        const headlines = [
            {
                text: `items`,
                to: `/economy/items`,
            },
            {
                text: `${itemId}`,
            },
        ];
        return (
            <BaseEconomyContainer
                {...this.props}
                themeMode={this.props.app.themeMode}
                title = "Edit Economy Item"
                headlines = {headlines}
            >
                {this.renderContent()}
            </BaseEconomyContainer>
        );
    }

    private renderContent = () => {
        const { items } = this.props;
        const { item } = this.state;
        if (!item) {
            return;
        }

        return (
            <CreateEconomyItemComponent
                type={'Edit'}
                onCreate={this.onEdit}
                onCancel={this.toEconomyItems}
                items={items}
                item={item}
            />
        );
    }

    private onEdit = async (itemId: string, itemClass: string, displayName: string, description: string, tags: string[], customData: string, isStackable: boolean, isTradable: boolean, isLimitedEdition: boolean) => {
        await actions.economy.updateItem(itemId, itemClass, displayName, description, tags, customData, isStackable, isTradable, isLimitedEdition);
        this.toEconomyItems();
    };

    private toEconomyItems = () => this.toLink(`/economy/items`);
}

const mapStateToProps = (state: StateMap): Props => ({
    ...mapProps(state),
    itemId: '',
    item: state.economy.item,
    items: state.economy.items,
});
const AppContainer = (props: Props) =>
{
    const navigate = useNavigate();
    const params = useParams();
    const itemId = params.itemId || '';
    return (<Container {...props} navigate={navigate} itemId={itemId}/>);
};
export default connect(mapStateToProps)(AppContainer);
